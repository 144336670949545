import mapboxgl from 'mapbox-gl';
import { Dispatch, SetStateAction, useState } from 'react';
import Map, { Layer, Source } from 'react-map-gl';

import { AnalyticsEventHandler, Coordinates } from '@tracking/data';

import { FallbackMapError } from '../../types';
import { drawMapCircle } from '../draw-map-circle';
import { MapPopup } from '../map-popup';

const POPUP_MARGIN = 8;

type MapboxPublicMapProps = {
  analyticsEventHandler: AnalyticsEventHandler;
  coordinates: Coordinates | undefined;
  hasAuthenticationEmail: boolean;
  setAuthModalVisibility: Dispatch<SetStateAction<boolean>>;
  mapboxApiKey: string;
  onError: (e: FallbackMapError) => void;
};

export const MapboxPublicMap = ({
  analyticsEventHandler,
  coordinates,
  hasAuthenticationEmail,
  setAuthModalVisibility,
  mapboxApiKey,
  onError,
}: MapboxPublicMapProps) => {
  const [popupCoordinates, setPopupCoordinates] = useState<{ x: number; y: number } | null>(null);

  const handleOnHover = (event: mapboxgl.MapLayerMouseEvent) => {
    if (!hasAuthenticationEmail) {
      return null;
    }
    const {
      features,
      point: { y, x },
    } = event;
    const hoveredFeature = features && features[0];
    setPopupCoordinates(hoveredFeature ? { x: x + POPUP_MARGIN, y: y + POPUP_MARGIN } : null);
  };

  const handleOnClick = () => {
    if (!hasAuthenticationEmail) {
      return null;
    }
    analyticsEventHandler('open_verification_modal_via_map_click');
    setAuthModalVisibility(true);
  };
  return (
    <Map
      initialViewState={{
        longitude: coordinates?.lng,
        latitude: coordinates?.lat,
        zoom: 11,
      }}
      style={{ cursor: 'pointer' }}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      mapboxAccessToken={mapboxApiKey}
      interactive={false}
      interactiveLayerIds={['radius-circle']}
      onMouseMove={handleOnHover}
      onMouseLeave={() => setPopupCoordinates(null)}
      onClick={handleOnClick}
      onError={e => onError(e.error)}
    >
      <Source id="radius-circle" type="geojson" data={drawMapCircle({ ...coordinates })}>
        <Layer
          id="radius-circle"
          type="fill"
          paint={{
            'fill-color': '#767676',
            'fill-opacity': 0.5,
          }}
        />
      </Source>
      {popupCoordinates && <MapPopup coordinates={popupCoordinates} />}
    </Map>
  );
};
