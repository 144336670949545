import { z } from 'zod';

import { sectionsConfigSchema } from './sectionsConfigSchema';

export const pageBrandingSchema = z.object({
  css_inline: z.string(),
  page_logo_link_url: z
    .string()
    .describe(`If set, the image logo becomes a clickable link leading to this URL.`)
    .optional(),
  page_logo_url: z.string().optional(),
  sections_config: z.lazy(() => sectionsConfigSchema).optional(),
  sender_name: z
    .string()
    .describe(`Used to overwrite the default value which is name of the site.`)
    .optional(),
  splashscreen_logo_url: z.string().optional(),
  translation_key: z
    .string()
    .describe(
      `TranslationKey is a custom translation key. If not defined - default translation key will be used.`
    )
    .optional(),
});
