import { useFormatMessage } from '@tracking/i18n';

import { Button, Image, Stack, Text } from '../../atoms';
import { Modal } from '../../molecules';
import * as styles from './identify.css';

type IdentifyOnOtherDeviceProps = {
  onClose: () => void;
  openBankIdApp: () => void;
  qrCode?: string;
};
export const IdentifyOnOtherDevice = ({
  onClose,
  openBankIdApp,
  qrCode,
}: IdentifyOnOtherDeviceProps) => {
  const formatMessage = useFormatMessage();

  return (
    <Modal
      headerText={formatMessage('IDENTIFY.OTHER_DEVICE.MODAL_TITLE')}
      onClose={onClose}
      headerSpacing="dense"
      bodySpacing="none"
      fullscreen
      shrinkBody
      footerContent={
        <Stack>
          <Button buttonType="secondary" border="solidBlack" onClick={openBankIdApp}>
            <Text>{formatMessage('IDENTIFY.OTHER_DEVICE.OPEN_APP')}</Text>
          </Button>
        </Stack>
      }
    >
      <Stack padding={16} space={16} data-branding="identify-on-other-device">
        <div className={styles.bankIdBrandSection}>
          <div className={styles.qrCodeImage}>
            {Boolean(qrCode) && (
              <Image
                width={160}
                height={160}
                src={qrCode}
                alt={formatMessage('COMMON.QR_CODE_ALT')}
              />
            )}
          </div>
        </div>
        <Stack space={2} data-branding="instructions">
          <Text textStyle="regular1420" textColor="darkGrey" data-branding="block-label">
            {formatMessage('IDENTIFY.OTHER_DEVICE.INSTRUCTIONS.HEADER')}
          </Text>
          <Text textStyle="regular1420" data-branding="block-text">
            {formatMessage('IDENTIFY.OTHER_DEVICE.INSTRUCTIONS.CONTENT')}
          </Text>
        </Stack>
      </Stack>
    </Modal>
  );
};
