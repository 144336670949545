import { z } from 'zod';

import { displayItemSchema } from './displayItemSchema';
import { privateOrderCartSchema } from './privateOrderCartSchema';
import { privateParcelSchema } from './privateParcelSchema';

export const privateOrderSchema = z.object({
  analytics_id: z.string(),
  cart: z.lazy(() => privateOrderCartSchema).optional(),
  external_id: z.string().optional(),
  items: z
    .array(z.lazy(() => displayItemSchema))
    .describe(`Deprecated: use Cart instead.`)
    .optional(),
  parcels: z.array(z.lazy(() => privateParcelSchema)).optional(),
  preselected_parcel_index: z.string().optional(),
  sender_name: z
    .string()
    .describe(
      `sender_name is the name of the sender of the order. This maybe a site name or a merchant name.`
    )
    .optional(),
  site_name: z.string().optional(),
  tos_id: z.string().optional(),
});
