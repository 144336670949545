import type { FC } from 'react';

import {
  Branding,
  getCarrierName,
  getDeliveryType,
  getDestinationName,
  getGlobalTranslationKey,
  getParcelCurrentStatus,
  getParcelStatuses,
  getSectionExpandProps,
  getSenderName,
  getTrackingLink,
  useAnalytics,
  useAuthState,
  useTrackingData,
} from '@tracking/data';
import { useLocale } from '@tracking/i18n';
import { ParcelProgressTimeline, TrackItHereButton } from '@tracking/ui';

type TrackingProgressProps = {
  parcelIndex: number;
  branding: Branding;
};

export const TrackingProgress: FC<TrackingProgressProps> = ({ parcelIndex, branding }) => {
  const authContext = useAuthState();
  const locale = useLocale();
  const analytics = useAnalytics();

  const { trackingLink, ...parcelProgressTimelineProps } = useTrackingData(data => ({
    carrierName: getCarrierName(data, parcelIndex),
    currentStatus: getParcelCurrentStatus(data, parcelIndex),
    deliveryType: getDeliveryType(data, parcelIndex),
    destination: getDestinationName(data, parcelIndex, branding, !authContext?.isAuthenticated),
    senderName: getSenderName(data, branding),
    statuses: getParcelStatuses(data, parcelIndex),
    trackingLink: getTrackingLink(data, parcelIndex),
  }));

  const translationKey = getGlobalTranslationKey(branding);

  if (trackingLink) {
    return (
      <TrackItHereButton
        trackingLink={trackingLink}
        onClick={() => analytics.capture('open_tracking_link')}
        containerProps={{
          'data-test': 'tracking-progress',
        }}
        data-test="carrier-redirect-button"
      />
    );
  }

  return (
    <ParcelProgressTimeline
      expandProps={getSectionExpandProps(branding, 'tracking_progress')}
      {...parcelProgressTimelineProps}
      analyticsEventHandler={analytics.capture}
      locale={locale}
      parcelIndex={parcelIndex}
      translationKey={translationKey}
    />
  );
};
