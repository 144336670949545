import * as maptilersdk from '@maptiler/sdk';
import '@maptiler/sdk/dist/maptiler-sdk.css';

export const initializeMap = (options: maptilersdk.MapOptions) =>
  new maptilersdk.Map({
    style: 'bb79f8b5-8ac7-4b3f-a7cc-98b77d6e9495',
    navigationControl: false,
    geolocateControl: false,
    ...options,
  });
