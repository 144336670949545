import * as maptilersdk from '@maptiler/sdk';
import { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';

import { AnalyticsEventHandler, Coordinates } from '@tracking/data';

import { Pin } from '../../../../atoms';
import { getMapPadding } from '../../../../helpers';
import { initializeMap } from '../../initialize-map';
import * as styles from '../../map.css';
import { PrimaryMapError } from '../../types';
import { LogoMapEntry } from '../private-map';
import { MapLocationPointerControl } from './map-location-pointer-control';

type MaptilerPrivateMap = {
  analyticsEventHandler: AnalyticsEventHandler;
  coordinates: Required<Coordinates>;
  maptilerApiKey: string;
  logoMapEntry: LogoMapEntry;
  onError: (e: PrimaryMapError) => void;
};

export const MaptilerPrivateMap = ({
  analyticsEventHandler,
  coordinates,
  maptilerApiKey,
  logoMapEntry,
  onError,
}: MaptilerPrivateMap) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<maptilersdk.Map | null>(null);
  const marker = useRef<maptilersdk.Marker | null>(null);
  maptilersdk.config.apiKey = maptilerApiKey;
  maptilersdk.config.session = false;

  const handleOnMapCentre = () => {
    if (coordinates.lat && coordinates.lng) {
      map?.current?.flyTo({ center: [coordinates.lng, coordinates.lat], zoom: 15 });
      analyticsEventHandler('map_centering');
    }
  };

  useEffect(() => {
    map.current = initializeMap({
      container: mapContainer.current as HTMLElement,
      center: coordinates,
      zoom: 15,
    });
    map.current.on('error', e => onError(e.error));
    map.current?.addControl(new MapLocationPointerControl(handleOnMapCentre), 'bottom-right');
  }, [coordinates]);

  useEffect(() => {
    if (map.current && logoMapEntry) {
      marker.current?.remove();
      const pinElement = document.createElement('div');
      createRoot(pinElement).render(
        <Pin
          offsetVertically
          src={logoMapEntry.pathPin}
          alt={logoMapEntry.name || 'Delivery location'}
          dataTest={`pin-${
            logoMapEntry.patterns.find(pattern => pattern.startsWith('carrier-')) ||
            logoMapEntry.patterns[0] ||
            'generic'
          }`}
          onLoad={event => {
            map.current?.setPadding({
              ...map.current?.getPadding(),
              top: getMapPadding(
                event.currentTarget.height,
                map.current.getContainer().offsetHeight
              ),
            });
          }}
        />
      );

      marker.current = new maptilersdk.Marker({
        element: pinElement,
      })
        .setLngLat(coordinates)
        .addTo(map.current);
    }
  }, [map.current, logoMapEntry]);

  return <div ref={mapContainer} style={{ height: '100%' }} className={styles.mapContainer} />;
};
