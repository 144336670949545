import { useCallback, useEffect, useRef } from 'react';

export const useAbortController = () => {
  const controllerRef = useRef<AbortController>();

  const ensureController = () =>
    (controllerRef.current = controllerRef.current ?? new AbortController());

  const reset = useCallback(() => {
    ensureController().abort();
    controllerRef.current = new AbortController();
  }, []);

  const abort = useCallback(() => {
    ensureController().abort();
  }, []);

  const getSignal = useCallback(() => ensureController().signal, []);

  useEffect(() => {
    return () => {
      ensureController().abort();
    };
  }, []);

  return {
    reset,
    getSignal,
    abort,
  };
};
