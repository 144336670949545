import { FC } from 'react';

import {
  Branding,
  getCarrier,
  getCarrierName,
  getDeliveryType,
  getGlobalTranslationKey,
  getIsHomeDelivery,
  getLocation,
  getLocationAddress,
  getPickupInstructions,
  getSectionExpandProps,
  getTrackingNumber,
  useAnalytics,
  useTrackingData,
} from '@tracking/data';
import { useLocale } from '@tracking/i18n';
import { DeliveryMinimal, PickupPoint } from '@tracking/ui';

import { HomeDelivery } from './home-delivery';

type DeliveryDetailsProps = {
  parcelIndex: number;
  branding: Branding;
};

export const DeliveryDetails: FC<DeliveryDetailsProps> = ({ parcelIndex, branding }) => {
  const analytics = useAnalytics();
  const locale = useLocale();
  const { location, isHomeDelivery, ...commonDeliverySectionData } = useTrackingData(data => ({
    location: getLocation(data, parcelIndex),
    isHomeDelivery: getIsHomeDelivery(data, parcelIndex),
    address: getLocationAddress(data, parcelIndex),
    carrier: getCarrier(data, parcelIndex),
    carrierName: getCarrierName(data, parcelIndex),
    trackingNumber: getTrackingNumber(data, parcelIndex),
    deliveryType: getDeliveryType(data, parcelIndex),
    pickupInstructions: getPickupInstructions(data, parcelIndex),
  }));

  const commonDeliverySectionProps = {
    ...commonDeliverySectionData,
    analyticsEventHandler: analytics.capture,
  };

  const expandProps = getSectionExpandProps(branding, 'delivery_details');
  if (!location) {
    return <DeliveryMinimal {...commonDeliverySectionProps} />;
  }

  if (isHomeDelivery) {
    return <HomeDelivery expandProps={expandProps} branding={branding} parcelIndex={parcelIndex} />;
  }

  return (
    <PickupPoint
      expandProps={expandProps}
      {...commonDeliverySectionProps}
      location={location}
      locale={locale}
      translationKey={getGlobalTranslationKey(branding)}
    />
  );
};
