import { assignInlineVars } from '@vanilla-extract/dynamic';
import clsx from 'clsx';
import { LazyMotion, domAnimation, m } from 'framer-motion';
import type { FC } from 'react';

import { FrontendAlert } from '@tracking/data';

import { hasAlert } from '../helpers/has-alert';
import { theme } from '../theme.css';
import { styles, vars } from './progress-bar.css';

type Props = {
  className?: string;
  progress: number;
  alerts?: FrontendAlert[];
  currentStatus?: string;
};

export const ProgressBar: FC<Props> = ({ className, progress, alerts = [], currentStatus }) => {
  // make sure `progress` is an integer in [0,100] range
  const progressSafe = Math.round(Math.max(0, Math.min(100, progress)));

  const progressBarStyle = getProgressBarStyles(currentStatus);
  const progressBarValueStyle = getProgressBarValueStyles(currentStatus);

  const isDelayed = hasAlert('ALERT_DELIVERY_DELAYED', alerts);
  const isDeliveryAttemptFailed = hasAlert('ALERT_DELIVERY_ATTEMPT_FAILED', alerts);

  const isAlert = isDelayed || isDeliveryAttemptFailed;

  return (
    <div
      className={clsx([styles.baseBar, className, progressBarStyle])}
      data-test="progress-bar"
      data-test-delayed={isDelayed || null}
      data-test-delivery-attempt-failed={isDeliveryAttemptFailed || null}
    >
      <LazyMotion features={domAnimation}>
        <m.div
          initial={{ scaleX: 0, transformOrigin: 'left' }}
          animate={{ scaleX: 1 }}
          transition={{ delay: 2, duration: 0.3, type: 'spring', stiffness: 300, damping: 26 }}
          className={clsx([styles.progressBar, progressBarValueStyle])}
          style={assignInlineVars({
            [vars.progressBarWidth]: `${progressSafe}%`,
            [vars.progressBarColor]: isAlert ? theme.colors.orange : theme.colors.black,
          })}
        />
      </LazyMotion>
    </div>
  );
};

export const getProgressBarStyles = (step?: string) => {
  switch (step) {
    case 'STEP_DELIVERED_TO_CONSUMER':
    case 'STEP_DELIVERED_TO_MERCHANT':
      return 'progress-bar-delivered';
    default:
      return 'progress-bar';
  }
};

export const getProgressBarValueStyles = (step?: string) => {
  switch (step) {
    case 'STEP_DELIVERED_TO_CONSUMER':
    case 'STEP_DELIVERED_TO_MERCHANT':
      return 'progress-bar-value-delivered';
    default:
      return 'progress-bar-value';
  }
};
