import { createContext, useContext } from 'react';

type Logger = {
  error: (error: unknown) => void;
};

const context = createContext<Logger | null>(null);

export const useLogger = () => {
  const value = useContext(context);

  if (!value) {
    throw new Error('useLogger must be used within a LoggerProvider');
  }
  return value;
};
export const LoggerProvider = context.Provider;
