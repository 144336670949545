import { useFormatMessage } from '@tracking/i18n';

import { Link } from '../atoms';
import { Text } from '../atoms/text';
import * as styles from './track-it-here-button.css';

type TrackItHereButtonProps = Parameters<typeof Link>[0] & {
  trackingLink: string;
  containerProps: JSX.IntrinsicElements['article'] & {
    [n: `data-${string}`]: string;
  };
  textDataTest?: string;
};

export const TrackItHereButton = ({
  trackingLink,
  containerProps,
  textDataTest = 'carrier-redirect-link',
  ...rest
}: TrackItHereButtonProps) => {
  const formatMessage = useFormatMessage();

  return (
    <article className={styles.trackingLinkContainer} {...containerProps}>
      <Link
        {...rest}
        rel="noreferrer"
        target="_blank"
        className={styles.trackingLink}
        href={trackingLink}
      >
        <Text dataTest={textDataTest} textColor="white">
          {formatMessage('TRACKING_PROGRESS.TRACK_IT_HERE')}
        </Text>
      </Link>
    </article>
  );
};
