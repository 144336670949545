import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const useNavigatorLanguage = () => {
  const { defaultLocale, locales } = useRouter();
  const [language, setLanguage] = useState(defaultLocale!);

  useEffect(() => {
    const supportedLocale = locales?.find(locale =>
      locale.toLocaleLowerCase().includes(navigator.language.toLocaleLowerCase())
    );
    setLanguage(supportedLocale ?? defaultLocale!);
  }, [locales, defaultLocale]);

  return language;
};
