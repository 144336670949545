import { z } from 'zod';

export const generateTokenRequestSchema = z
  .object({
    order_id: z
      .string()
      .describe(
        `Identifier of the order to which the parcels belongs. This is either 'tos_id' or 'external_id'.`
      )
      .optional(),
    site_id: z.string().describe(`Identifier of the site to which the tracking resource belongs.`),
    tracking_number: z.string().describe(`Tracking number of the parcel.`).optional(),
  })
  .describe(`NOTE: You must specify one of the 'tracking_number' or 'order_id'.`);
