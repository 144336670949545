import { z } from 'zod';

import { itemSchema } from './itemSchema';
import { orderCartPricesSchema } from './orderCartPricesSchema';

export const orderCartSchema = z.object({
  currency: z.string().optional(),
  items: z.array(z.lazy(() => itemSchema)).optional(),
  prices: z.lazy(() => orderCartPricesSchema).optional(),
});
