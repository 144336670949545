import { z } from 'zod';

import { dateTimeRangeSchema } from './dateTimeRangeSchema';
import { stepSchema } from './stepSchema';

export const progressItemSchema = z.object({
  estimated_time: z.lazy(() => dateTimeRangeSchema).optional(),
  step: z.lazy(() => stepSchema),
  time: z.string(),
});
