import { Text } from '../../atoms';
import * as styles from './eta-timestamps.css';

type EtaTimestampItemProps = {
  message: string;
  date: string;
  className?: string;
  dataTest?: string;
};

export const EtaTimestampItem = ({ message, date, dataTest }: EtaTimestampItemProps) => {
  return (
    <div className={styles.item} data-test={dataTest} data-branding="eta-timestamp-item">
      {date && (
        <>
          <Text
            textStyle="regular1012"
            textColor="darkGrey"
            className={styles.header}
            data-branding="block-label"
          >
            {message}
          </Text>
          <Text dataTest="eta-values" textStyle="light1216" data-branding="block-text">
            {date}
          </Text>
        </>
      )}
    </div>
  );
};
