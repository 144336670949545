import { z } from 'zod';

import { itemVariantSchema } from './itemVariantSchema';

export const itemSchema = z.object({
  cancelled_quantity: z
    .number()
    .describe(`CancelledQuantity is the number of items that have been cancelled.`)
    .optional(),
  discount: z
    .number()
    .describe(
      `Discount is the discount for a given item in cents. Example: '20000' represents '200.00'.`
    )
    .optional(),
  image: z.string().describe(`Image is the URL of the image of the given cart item.`).optional(),
  name: z.string().describe(`Name is the product name or title.`).optional(),
  price: z
    .number()
    .describe(
      `Price is the price of a single item with applied discounts in cents. Example: '20000' represents '200.00'.`
    )
    .optional(),
  quantity: z
    .number()
    .describe(`Quantity is the total number of a given product item in the cart.`)
    .optional(),
  sku: z.string().describe(`SKU is a unique product identifier.`).optional(),
  total_price: z.number().optional(),
  variant: z.lazy(() => itemVariantSchema).optional(),
});
