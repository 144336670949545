import { RecipeVariants } from '@vanilla-extract/recipes';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { ReactNode, forwardRef } from 'react';

import { Sprinkles, sprinkles } from '../sprinkles.css';
import { base, stackRecipe } from './stack.css';

type StackVariants = RecipeVariants<typeof stackRecipe>;

type Props = StackVariants & {
  className?: string;
  'data-branding'?: string;
  children: ReactNode;
  style?: React.CSSProperties;
} & Sprinkles;

export const Stack = forwardRef<HTMLDivElement, Props>(
  (
    { 'data-branding': dataBranding, children, className, space, width, style, ...sprinklesProps },
    ref
  ) => (
    <div
      className={clsx(base, stackRecipe({ space, width }), sprinkles(sprinklesProps), className)}
      ref={ref}
      style={style}
      data-branding={dataBranding}
    >
      {children}
    </div>
  )
);
Stack.displayName = 'Stack';

export const MotionStack = motion(Stack);
