import { createRoot } from 'react-dom/client';

import { MapLocationPointer } from '../map-location-pointer';
import * as styles from '../private-map.css';

export class MapLocationPointerControl implements maplibregl.IControl {
  _map?: maplibregl.Map;
  _container?: HTMLElement;
  handleOnMapCentre: () => void;

  constructor(handleOnMapCentre: () => void) {
    this.handleOnMapCentre = handleOnMapCentre;
  }

  onAdd(map: maplibregl.Map) {
    this._map = map;
    this._container = document.createElement('div');
    createRoot(this._container).render(
      <button className={styles.locationPointer} onClick={this.handleOnMapCentre}>
        <MapLocationPointer data-branding="map-location-pointer" />
      </button>
    );
    return this._container;
  }

  onRemove() {
    this._container?.parentNode?.removeChild?.(this._container);
    this._map = undefined;
  }
}
