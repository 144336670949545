import { useState } from 'react';

import { AbortError, CreatePassObjectPayload, UserFacingError, useLogger } from '@tracking/data';
import { useFormatMessage } from '@tracking/i18n';

import { useEncryptedCookieState } from '../../hooks';
import { ErrorMessage } from './error-message';
import { Identified } from './identified';
import { IdentifyButton } from './identify-button';
import { IdentifyOnOtherDevice } from './identify-on-other-device';
import * as styles from './identify.css';
import { MethodSelection } from './method-selection';
import { IdentifiedData, IdentifyMethod, ParcelDataBase } from './types';

type IdentifyState =
  | 'not-initialized'
  | 'method-selection'
  | 'external-device-auth'
  | 'current-device-auth'
  | 'identified';

type ParcelData = ParcelDataBase & {
  qrCodeContent: string;
};

type IdentifyProps = {
  externalDeviceAuth: (
    setQrCode: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => Promise<string>;
  currentDeviceAuth: () => Promise<string>;
  cancelAuthProcess: () => Promise<void>;
  getParcelData: (token: string) => Promise<ParcelData>;
  onModalOpen: () => void;
  onGoogleWalletButtonClick: (barcodeConent: CreatePassObjectPayload['barcode']) => void;
  trackingNumber: string | undefined;
};
export const Identify = ({
  cancelAuthProcess,
  externalDeviceAuth,
  currentDeviceAuth,
  getParcelData,
  onModalOpen,
  onGoogleWalletButtonClick,
  trackingNumber,
}: IdentifyProps) => {
  const [identifyState, setIdentifyState] = useState<IdentifyState>('not-initialized');
  const [identifiedData, setIdentifiedData] = useEncryptedCookieState<IdentifiedData>(
    'parcel_collection_data',
    { cookieAttributes: { 'max-age': '14400' } } //store cookie for 4h
  );
  const [externalDeviceAuthQrCode, setExternalDeviceAuthQrCode] = useState<string>();
  const [identifyInProgress, setIdentifyInProgress] = useState(false);
  const [cancellingAuth, setCancellingAuth] = useState(false);

  const [errorText, setErrorText] = useState<string | null>();
  const formatMessage = useFormatMessage();

  const logger = useLogger();

  const identifiedParcelData =
    identifiedData?.trackingNumber === trackingNumber ? identifiedData : undefined;

  const cancelCurrentAuth = async () => {
    try {
      setCancellingAuth(true);
      await cancelAuthProcess();
    } finally {
      setCancellingAuth(false);
    }
  };

  const startAuthProcess = () => {
    onModalOpen();
    if (!identifiedParcelData) {
      setIdentifyState('method-selection');
    } else {
      setIdentifyState('identified');
    }
  };

  const closeModal = () => {
    cancelCurrentAuth();
    setIdentifyState('not-initialized');
  };

  const handleSelectedAuth = async (selectedMethod: IdentifyMethod) => {
    setErrorText(null);
    try {
      await cancelAuthProcess();
      setIdentifyState(selectedMethod);
      const authHandler =
        selectedMethod === 'external-device-auth'
          ? () => externalDeviceAuth(setExternalDeviceAuthQrCode)
          : currentDeviceAuth;
      setIdentifyInProgress(true);
      const token = await authHandler();
      if (token) {
        const identifiedData = await getParcelData(token);
        setIdentifiedData(identifiedData);
        setIdentifyState('identified');
      }
    } catch (error) {
      if (error instanceof AbortError) {
        // the abort error comes from manual operation and should be treated as a non-error here
        // it must not affect UI view as user may have navigated already
        return;
      }
      logger.error(error);

      const errorText =
        error instanceof UserFacingError ? error.message : formatMessage('WIDGET.ERROR.TEXT');

      setErrorText(errorText);

      setIdentifyState(currentState =>
        currentState !== 'not-initialized' ? 'method-selection' : currentState
      );
    } finally {
      setIdentifyInProgress(false);
    }
  };

  const useCurrentDeviceAuthInstead = () => {
    handleSelectedAuth('current-device-auth');
  };

  const buttonsLoadingState = identifyInProgress || cancellingAuth;

  return (
    <>
      <IdentifyButton
        loading={buttonsLoadingState}
        onClick={startAuthProcess}
        alreadyAuthenticated={Boolean(identifiedParcelData)}
      />
      {errorText && <ErrorMessage text={errorText} />}
      {identifyState !== 'not-initialized' && (
        <div data-branding="identify-modal" className={styles.modalContainer}>
          {identifyState === 'identified' && identifiedParcelData ? (
            <>
              <Identified
                onClose={closeModal}
                data={identifiedParcelData}
                onGoogleWalletButtonClick={() =>
                  onGoogleWalletButtonClick(identifiedParcelData.qrCodeContent)
                }
              />
            </>
          ) : identifyState === 'external-device-auth' ? (
            <IdentifyOnOtherDevice
              onClose={closeModal}
              openBankIdApp={useCurrentDeviceAuthInstead}
              qrCode={externalDeviceAuthQrCode}
            />
          ) : identifyState === 'method-selection' || identifyState === 'current-device-auth' ? (
            <MethodSelection
              errorText={errorText}
              onClose={closeModal}
              onMethodSelected={handleSelectedAuth}
              appStarting={buttonsLoadingState}
            />
          ) : null}
        </div>
      )}
    </>
  );
};
