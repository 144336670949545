import { RecipeVariants } from '@vanilla-extract/recipes';

import { useFormatMessage } from '@tracking/i18n';

import { Image, Text } from '../../../atoms';
import CloseIcon from './close.svg';
import * as styles from './modal-header.css';

type ModalHeaderProps = {
  onClose: () => void;
  text: string;
} & RecipeVariants<typeof styles.modalHeader>;
export const ModalHeader = ({ onClose, text, divider, spacing }: ModalHeaderProps) => {
  const formatMessage = useFormatMessage();

  return (
    <div className={styles.modalHeader({ divider, spacing })}>
      <Text textStyle="regular1620" data-branding="modal-header">
        {text}
      </Text>
      <button
        className={styles.closeButton}
        onClick={onClose}
        // TODO: consider extracting data test attribute from here
        data-test="close-modal"
      >
        <Image
          src={CloseIcon}
          width={12}
          height={12}
          // TODO: rename the translation key
          // it's just a general-purpose text "close modal"
          alt={formatMessage('AUTH.CLOSE_MODAL_ALT')}
        />
      </button>
    </div>
  );
};
