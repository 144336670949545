import { createIntl, createIntlCache } from 'react-intl';

import { TranslationKey } from '@tracking/i18n';

export const getProposalIntl = (locale: string, localeMessages: Record<string, string>) => {
  const cache = createIntlCache();

  const { formatMessage } = createIntl(
    {
      locale,
      messages: localeMessages,
    },
    cache
  );

  // simplify formatMessage API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (id: TranslationKey, values?: { [key: string]: any }) => formatMessage({ id }, values);
};
