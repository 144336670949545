import { z } from 'zod';

import { coordinatesSchema } from './coordinatesSchema';

export const addressSchema = z
  .object({
    address_lines: z.array(z.string()).optional(),
    apartment_number: z.string().describe(`Number of the apartment.`).optional(),
    attn: z.string().optional(),
    care_of: z.string().optional(),
    city: z.string().optional(),
    coordinates: z.lazy(() => coordinatesSchema).optional(),
    country: z
      .string()
      .describe(
        `Country should be specified as two uppercase letters (ISO Alpha-2). Example 'SE' for Sweden, 'ES' for Spain.`
      )
      .optional(),
    door_code: z.string().optional(),
    floor_number: z.string().optional(),
    name: z.string().optional(),
    postal_code: z.string().optional(),
    region: z.string().optional(),
    street: z.string().describe(`Name of the street.`).optional(),
    street_number: z.string().describe(`Number of the street.`).optional(),
    subregion: z.string().optional(),
  })
  .describe(`Common address entity that used almost everywhere in Ingrid's API.`);
