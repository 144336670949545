import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { FC } from 'react';

import { DeliveryType, ProgressItem } from '@tracking/data';
import { dateFnsLocales, useFormatMessage } from '@tracking/i18n';

import { getTimelineItemText } from '../../helpers';
import { TimelineItem } from '../../molecules';
import { sprinkles } from '../../sprinkles.css';

type Props = {
  statuses: ProgressItem[];
  currentStatus: ProgressItem | undefined;
  deliveryType: DeliveryType | undefined;
  senderName: string;
  carrierName: string | undefined;
  destination: string;
  locale: string | undefined;
  translationKey: string | undefined;
};

export const Timeline: FC<Props> = ({
  statuses,
  currentStatus,
  deliveryType,
  senderName,
  carrierName,
  destination,
  locale,
  translationKey,
}) => {
  const formatMessage = useFormatMessage();
  const timestampFormat = formatMessage('SPECIAL.DATETIME_FORMAT.PARCEL_STATUS');

  const statusProgressItems = statuses.map((status, index, statuses) => (
    <TimelineItem
      key={status.step}
      text={getTimelineItemText(
        formatMessage,
        deliveryType,
        status,
        senderName,
        carrierName,
        destination,
        translationKey
      )}
      timestamp={
        status.time &&
        format(parseISO(status.time), timestampFormat, {
          locale: dateFnsLocales.get(locale!),
        })
      }
      completed={!!status.time}
      current={status.step === currentStatus?.step}
      isLast={index === statuses.length - 1}
    />
  ));

  return (
    <div className={sprinkles({ paddingTop: 12, paddingRight: 24 })}>{statusProgressItems}</div>
  );
};
