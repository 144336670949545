import { Step } from '@tracking/data';

import { TimestampsType } from '../../helpers/get-timestamps';
import { EtaTimestampItem } from './eta-timestamp-item';
import { isTimeVisible } from './is-time-visible';

type EtaLastTimestampProps = {
  timestamps: TimestampsType;
  currentStep: Step | undefined;
  isETAOutdated: boolean;
};

export const EtaLastTimestamp = ({
  timestamps,
  currentStep,
  isETAOutdated,
}: EtaLastTimestampProps) => {
  if (currentStep === 'STEP_TERMINATED') {
    return (
      <EtaTimestampItem
        dataTest="eta-header-timestamp-terminated"
        message={timestamps.terminated.message}
        date={timestamps.terminated.formattedTime}
      />
    );
  }
  if (currentStep === 'STEP_SENT_BACK') {
    return (
      <EtaTimestampItem
        dataTest="eta-header-timestamp-sent-back"
        message={timestamps.sentBack.message}
        date={timestamps.sentBack.formattedTime}
      />
    );
  }

  if (isTimeVisible(timestamps.delivered, isETAOutdated)) {
    return (
      <EtaTimestampItem
        dataTest="eta-header-timestamp-delivered"
        message={timestamps.delivered.message}
        date={timestamps.delivered.formattedTime}
      />
    );
  }

  return null;
};
