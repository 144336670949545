import { ShippingLogo } from '@ingrid/react-shipping-logo';

import {
  Branding,
  getCarrier,
  getCarrierName,
  getDestinationName,
  getLocationAddress,
  getParcelDirectionType,
  getTrackingNumber,
  useAnalytics,
  useAuthState,
  useTrackingData,
} from '@tracking/data';
import { useFormatMessage } from '@tracking/i18n';
import { ContentBlock, CopyButton, Expand, ExpandProps, Stack, Text } from '@tracking/ui';

import * as styles from './styles.css';

type HomeDeliveryProps = {
  expandProps: Pick<ExpandProps, 'defaultExpanded' | 'expandToggleDisabled'>;
  parcelIndex: number;
  branding: Branding;
};

export const HomeDelivery = ({ expandProps, parcelIndex, branding }: HomeDeliveryProps) => {
  const formatMessage = useFormatMessage();
  const authContext = useAuthState();
  const data = useTrackingData(data => ({
    address: getLocationAddress(data, parcelIndex),
    carrier: getCarrier(data, parcelIndex),
    carrierName: getCarrierName(data, parcelIndex),
    trackingNumber: getTrackingNumber(data, parcelIndex),
    directionType: getParcelDirectionType(data, parcelIndex),
    destination: getDestinationName(data, parcelIndex, branding, !authContext?.isAuthenticated),
  }));

  const analytics = useAnalytics();

  return (
    <article
      className={styles.wrapper}
      data-test="delivery-details"
      data-branding="section-delivery-details-home"
    >
      <Expand
        {...expandProps}
        unexpandedButtonOffset={data.destination ? 8 : 0}
        expandedButtonOffset={data.destination ? 8 : 0}
        dataTest="delivery-details-expand"
        unexpandedChildren={
          <Stack paddingRight={24}>
            <Text textStyle="light1420" textColor="darkGrey" data-branding="block-label">
              {formatMessage('DELIVERY_DETAILS.DELIVERY')}
            </Text>
            <Text textStyle="light1420" textColor="black" data-branding="block-text">
              {data.destination}
            </Text>
          </Stack>
        }
        expandedChildren={
          <>
            {data.destination && (
              <Stack paddingRight={24} paddingBottom={12}>
                <Text textStyle="light1420" textColor="darkGrey" data-branding="block-label">
                  {formatMessage('DELIVERY_DETAILS.DELIVERY')}
                </Text>
                <Text
                  dataTest="delivery-address"
                  textStyle="light1420"
                  textColor="black"
                  data-branding="block-text"
                >
                  {data.destination}
                </Text>
              </Stack>
            )}
            <ContentBlock>
              <Stack>
                <Text data-branding="block-label">
                  {formatMessage('DELIVERY_DETAILS.DELIVERY_BY')}
                </Text>
                <Text dataTest="delivery-by" textColor="darkGrey" data-branding="block-text">
                  {data.carrierName}
                </Text>
              </Stack>
              <ShippingLogo
                data-branding="shipping-logo-home-delivery"
                method={data.carrier || ''}
                variant="Round30"
                width={32}
                height={32}
                alt={formatMessage('COMMON.CARRIER_LOGO_ALT')}
                data-test={`parcel-carrier-logo-${data.carrierName}`}
              />
            </ContentBlock>
            {data.trackingNumber && (
              <ContentBlock marginTop={16} data-branding="block-tracking-number">
                <Stack>
                  <Text data-branding="block-label">
                    {formatMessage('DELIVERY_DETAILS.CARRIER_TRACKING_NUMBER', {
                      carrier: data.carrierName,
                    })}
                  </Text>
                  <Text dataTest="tracking-number" textColor="darkGrey" data-branding="block-text">
                    {data.trackingNumber}
                  </Text>
                </Stack>
                <CopyButton
                  textToCopy={data.trackingNumber}
                  onCopy={() => analytics.capture('copy_tracking_number')}
                />
              </ContentBlock>
            )}
          </>
        }
        onExpandPosthogCapture={() => analytics.capture('expand_delivery_details')}
        onHidePosthogCapture={() => analytics.capture('hide_delivery_details')}
      />
    </article>
  );
};
