import type { FC } from 'react';

import {
  Branding,
  getDestinationName,
  getGlobalTranslationKey,
  getParcelsOutbound,
  getParcelsReturn,
  useAnalytics,
  useAuthState,
  useTrackingData,
} from '@tracking/data';
import { useLocale } from '@tracking/i18n';
import { ParcelOverview, ParcelSelectorUI } from '@tracking/ui';

import * as styles from './parcel-selector.css';

interface ParcelSelectorProps {
  branding: Branding;
  // TODO: for sure we don't need both indexes etc.
  // The index state thingy is a mess
  // we don't need more than parcel index.
  onParcelClick: (parcelIndex: number, clickedParcelIndex: number, isReturn: boolean) => void;
}

export const ParcelSelector: FC<ParcelSelectorProps> = ({ branding, onParcelClick }) => {
  const locale = useLocale();
  const translationKey = getGlobalTranslationKey(branding);
  // const parcelIndexState = useParcelIndexState();
  const trackingData = useTrackingData(data => data);
  const authContext = useAuthState();

  const deliveries = getParcelsOutbound(trackingData);
  const returns = getParcelsReturn(trackingData);

  const analytics = useAnalytics();

  return (
    <div className={styles.borderBottom} data-branding="parcel-selector">
      {deliveries.length > 0 && (
        <ParcelSelectorUI
          header="PARCEL_OVERVIEW.DELIVERIES"
          dataTest="multiparcel-deliveries-header"
        >
          {deliveries.map((parcel, idx) => (
            <ParcelOverview
              key={`parcel-${parcel.index}-${parcel.tracking_number}`}
              trackingData={trackingData}
              idx={idx}
              selectParcel={() => {
                analytics.capture(`open_delivery_${parcel.index}_details`);
                onParcelClick(parcel.index, idx, false);
              }}
              total={deliveries.length}
              locale={locale}
              branding={branding}
              destination={getDestinationName(
                trackingData,
                parcel.index,
                branding,
                !authContext?.isAuthenticated
              )}
              parcelIndex={parcel.index}
              dataTest={`delivery-parcel-${idx}`}
              translationKey={translationKey}
            />
          ))}
        </ParcelSelectorUI>
      )}

      {returns.length > 0 && (
        <ParcelSelectorUI header="PARCEL_OVERVIEW.RETURNS" dataTest="multiparcel-returns-header">
          {returns.map((parcel, idx) => (
            <ParcelOverview
              key={`parcel-${parcel.index}-${parcel.tracking_number}`}
              trackingData={trackingData}
              idx={idx}
              selectParcel={() => {
                analytics.capture(`open_return_${parcel.index}_details`);
                onParcelClick(parcel.index, idx, true);
              }}
              total={returns.length}
              locale={locale}
              branding={branding}
              destination={getDestinationName(
                trackingData,
                parcel.index,
                branding,
                !authContext?.isAuthenticated
              )}
              parcelIndex={parcel.index}
              dataTest={`return-parcel-${idx}`}
              translationKey={translationKey}
            />
          ))}
        </ParcelSelectorUI>
      )}
    </div>
  );
};
