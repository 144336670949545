import clsx from 'clsx';
import type { FC } from 'react';

import { DotLine, Text } from '../atoms';
import * as styles from './timeline-item.css';

type Props = {
  text: string;
  timestamp?: string;
  completed: boolean;
  current: boolean;
  isLast: boolean;
};

export const TimelineItem: FC<Props> = ({ text, timestamp, completed, current, isLast }) => (
  <div
    className={clsx([styles.rootElement, completed && styles.completed])}
    data-test={`timeline-item`}
  >
    <DotLine completed={completed} current={current} drawLine={!isLast} />
    <div data-test={`timeline-texts`} className={styles.textBody}>
      <Text
        dataTest={'timeline-status'}
        textStyle={'light1420'}
        textColor={completed ? 'black' : 'darkGrey'}
        data-branding={`timeline-item-label${completed ? '-completed' : ''}`}
      >
        {text}
      </Text>
      <Text
        data-branding="timeline-item-timestamp"
        textStyle={'light1420'}
        textColor={'darkGrey'}
        dataTest={`timeline-date`}
      >
        {timestamp}
      </Text>
    </div>
  </div>
);
