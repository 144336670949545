import clsx from 'clsx';
import type { FC } from 'react';

import { Text } from '../../atoms';
import * as styles from './page-text-input.css';

type Props = {
  raiseLabel: boolean;
  label: string;
  id: string;
  error: boolean;
  letterCount?: {
    current: number;
    max: number;
  };
};

export const InputLabelShell: FC<Props> = ({ raiseLabel, label, id, letterCount, error }) => (
  <label className={clsx([styles.label, raiseLabel && styles.labelFocusedOrFilled])} htmlFor={id}>
    <Text
      textStyle={raiseLabel ? 'light1216' : 'light1420'}
      textColor={error ? 'lightRed' : 'darkGrey'}
    >
      {label}
    </Text>
    {letterCount && (
      <Text textStyle="light1216" textColor="darkGrey">
        {letterCount.current}/{letterCount.max}
      </Text>
    )}
  </label>
);
