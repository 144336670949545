import { z } from 'zod';

import { errorSchema } from './errorSchema';
import { getWidgetBrandingResponseSchema } from './getWidgetBrandingResponseSchema';

export const moiraiGetWidgetBrandingQueryParamsSchema = z
  .object({ site_id: z.string().optional() })
  .optional();

/**
 * @description An unexpected error response.
 */
export const moiraiGetWidgetBrandingErrorSchema = z.lazy(() => errorSchema);

/**
 * @description A successful response.
 */
export const moiraiGetWidgetBrandingQueryResponseSchema = z.lazy(
  () => getWidgetBrandingResponseSchema
);
