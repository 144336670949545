import { z } from 'zod';

export const displayItemSchema = z.object({
  discount: z.number().optional(),
  image: z.string().optional(),
  name: z.string().optional(),
  price: z.number().optional(),
  quantity: z.number().describe(`quantity - current quantity of items.`).optional(),
  sku: z.string().optional(),
  total_price: z.number().optional(),
});
