import { z } from 'zod';

import { getPrivateTrackingDataResponseOptionsSchema } from './getPrivateTrackingDataResponseOptionsSchema';
import { privateOrderSchema } from './privateOrderSchema';

export const getPrivateTrackingDataResponseSchema = z.object({
  options: z.lazy(() => getPrivateTrackingDataResponseOptionsSchema).optional(),
  order: z.lazy(() => privateOrderSchema),
  parcel_options: z
    .object({})
    .catchall(z.lazy(() => getPrivateTrackingDataResponseOptionsSchema))
    .describe(`parcel_options is a map of parcel tracking numbers to options.`)
    .optional(),
  site_id: z.string(),
  tos_id: z.string(),
});
