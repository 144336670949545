import { z } from 'zod';

import { sectionsConfigCartItemsSchema } from './sectionsConfigCartItemsSchema';
import { sectionsConfigDeliveryDetailsSchema } from './sectionsConfigDeliveryDetailsSchema';
import { sectionsConfigEtaHeaderSchema } from './sectionsConfigEtaHeaderSchema';
import { sectionsConfigFooterSchema } from './sectionsConfigFooterSchema';
import { sectionsConfigInfoBoxSchema } from './sectionsConfigInfoBoxSchema';
import { sectionsConfigMapSchema } from './sectionsConfigMapSchema';
import { sectionsConfigNavigationHeaderMultiparcelSchema } from './sectionsConfigNavigationHeaderMultiparcelSchema';
import { sectionsConfigNavigationHeaderSchema } from './sectionsConfigNavigationHeaderSchema';
import { sectionsConfigTrackingProgressSchema } from './sectionsConfigTrackingProgressSchema';

export const sectionsConfigSchema = z
  .object({
    cart_items: z.lazy(() => sectionsConfigCartItemsSchema).optional(),
    delivery_details: z.lazy(() => sectionsConfigDeliveryDetailsSchema).optional(),
    eta_header: z.lazy(() => sectionsConfigEtaHeaderSchema).optional(),
    footer: z.lazy(() => sectionsConfigFooterSchema).optional(),
    info_box: z.lazy(() => sectionsConfigInfoBoxSchema).optional(),
    map: z.lazy(() => sectionsConfigMapSchema).optional(),
    navigation_header: z.lazy(() => sectionsConfigNavigationHeaderSchema).optional(),
    navigation_header_multiparcel: z
      .lazy(() => sectionsConfigNavigationHeaderMultiparcelSchema)
      .optional(),
    tracking_progress: z.lazy(() => sectionsConfigTrackingProgressSchema).optional(),
  })
  .describe(`SectionsConfig describes the sections that should be shown on the widget.`);
