import { cloneDeep, isObject, set } from 'lodash';
import { ZodParsedType } from 'zod';

import { sectionsConfigSchema } from '../../../generated';
import { Branding } from './configuration';

const primitiveConstructors = new Map<ZodParsedType, () => unknown>([
  ['boolean', Boolean],
  ['string', String],
  ['number', Number],
]);

export const sanitizeBranding = (branding: Branding) => {
  if (!isObject(branding)) {
    return branding;
  }

  const result = cloneDeep(branding);
  const validatedSections = sectionsConfigSchema.safeParse(branding.sections_config);
  if (validatedSections.success) {
    return { ...result, sections_config: validatedSections.data };
  }

  const { issues } = validatedSections.error;
  issues.forEach(issue => {
    if (issue.code !== 'invalid_type') {
      return;
    }
    const path = `sections_config.${issue.path.join('.')}`;

    const expectedType = issue.expected;
    const constructor = primitiveConstructors.get(expectedType);
    if (constructor) {
      const sanitizedValue = constructor();
      set(result, path, sanitizedValue);
    }
  });
  return result;
};
