import { z } from 'zod';

import { itemSchema } from './itemSchema';
import { privateOrderCartPricesSchema } from './privateOrderCartPricesSchema';

export const privateOrderCartSchema = z.object({
  currency: z.string().optional(),
  items: z.array(z.lazy(() => itemSchema)).optional(),
  prices: z.lazy(() => privateOrderCartPricesSchema).optional(),
});
