import { z } from 'zod';

import { boolOptionSchema } from './boolOptionSchema';
import { stringOptionSchema } from './stringOptionSchema';

export const sectionsConfigInfoBoxSchema = z.object({
  disabled: z.lazy(() => boolOptionSchema).optional(),
  label: z.lazy(() => stringOptionSchema).optional(),
  message: z.lazy(() => stringOptionSchema).optional(),
});
