import { z } from 'zod';

import { orderCartPricesShippingComponentSchema } from './orderCartPricesShippingComponentSchema';
import { orderCartPricesTotalsSchema } from './orderCartPricesTotalsSchema';

export const orderCartPricesSchema = z.object({
  shipping_components: z
    .array(z.lazy(() => orderCartPricesShippingComponentSchema))
    .describe(`ShippingComponents represents the shipping components of the cart.`)
    .optional(),
  total: z.lazy(() => orderCartPricesTotalsSchema).optional(),
});
