import clsx from 'clsx';
import { InputHTMLAttributes, forwardRef, useState } from 'react';
import { FieldError } from 'react-hook-form';

import { Text } from '../../atoms';
import { InputLabelShell } from './input-label-shell';
import * as styles from './page-text-input.css';
import { getBorderColor, getString } from './utils';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  id: string;
  label?: string;
  error?: FieldError;
  maxLength?: number;
  dataTest?: string;
};

export const TextInputWithMask = forwardRef<HTMLInputElement, Props>(
  ({ label, id, error, className, dataTest, onBlur, onFocus, ...inputAttributes }, ref) => {
    const [focused, toggleFocused] = useState(false);
    const focusedOrFilled = focused || !!inputAttributes.value;

    const letterCount = inputAttributes.maxLength &&
      focusedOrFilled && {
        current: getString(inputAttributes.value).length,
        max: inputAttributes.maxLength,
      };

    return (
      <div className={clsx([styles.wrapper, className])} data-test={dataTest}>
        <div className={styles.inputWrapper}>
          <input
            id={id}
            ref={ref}
            className={clsx([
              styles.input,
              getBorderColor(focused, !!error),
              label && styles.inputWithLabel,
            ])}
            onFocus={e => {
              onFocus?.(e);
              toggleFocused(true);
            }}
            onBlur={e => {
              onBlur?.(e);
              toggleFocused(false);
            }}
            {...inputAttributes}
          />
          {label && (
            <InputLabelShell
              error={!!error}
              letterCount={letterCount || undefined}
              id={id}
              label={label}
              raiseLabel={focusedOrFilled}
            />
          )}
        </div>

        {error && (
          <div className={styles.textError}>
            <Text textStyle="light1216" textColor="lightRed" dataTest="order-no-error-msg">
              {error.message}
            </Text>
          </div>
        )}
      </div>
    );
  }
);

TextInputWithMask.displayName = 'TextInputWithMask';
