import type { Location } from '@tracking/data';
import { FormatMessageFn } from '@tracking/i18n';

export const formatLocationType = (
  locationType: Location['location_type'],
  carrierName: string,
  formatMessage: FormatMessageFn,
  translationKeySuffix?: string
) => {
  switch (locationType) {
    case 'LOCKER':
      return formatMessage(
        'DELIVERY_DETAILS.LOCATION_TYPE.LOCKER',
        { carrier: carrierName },
        translationKeySuffix
      );
    case 'POSTOFFICE':
      return formatMessage('DELIVERY_DETAILS.LOCATION_TYPE.POST_OFFICE', {
        carrier: carrierName,
      });
    case 'STORE':
      return formatMessage('DELIVERY_DETAILS.LOCATION_TYPE.STORE', { carrier: carrierName });
    case 'MANNED':
      return formatMessage(
        'DELIVERY_DETAILS.LOCATION_TYPE.MANNED',
        { carrier: carrierName },
        translationKeySuffix
      );
    case 'UNKNOWN_PICKUP_LOCATION_TYPE':
    case undefined:
      return formatMessage(
        'DELIVERY_DETAILS.LOCATION_TYPE.UNKNOWN',
        { carrier: carrierName },
        translationKeySuffix
      );
    default:
      return null;
  }
};
