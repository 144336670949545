import enUSMessages from './messages/en-US.json';

export const fallbackLocaleMessages = new Map<string, Record<string, string>>([
  ['en-US', enUSMessages],
]);

// the order is important here. please make sure you put new locale in a proper place.
// there is wikipedia style locale order, ex: ru-RU -> Русский -> Russkij, so list is sorted by this last "native language in latin" value
export const localeToLocalName = new Map<string, string>([
  ['cs-CZ', 'Čeština'],
  ['zh-CN', '简体字'],
  ['da-DK', 'Dansk'],
  ['de-DE', 'Deutsch (Deutschland)'],
  ['de-AT', 'Deutsch (Österreich)'],
  ['de-CH', 'Deutsch (Schweiz)'],
  ['et-EE', 'Eesti'],
  ['el-GR', 'Ελληνικά'],
  ['es-ES', 'Español'],
  ['en-AU', 'English (Australia)'],
  ['en-CA', 'English (Canada)'],
  ['en-GB', 'English (United Kingdom)'],
  ['en-IE', 'English (Ireland)'],
  ['en-US', 'English (United States)'],
  ['fr-FR', 'Français'],
  ['fr-BE', 'Français (Belgique)'],
  ['hr-HR', 'Hrvatski'],
  ['is-IS', 'Íslenska'],
  ['it-IT', 'Italiano'],
  ['ja-JP', '日本語'],
  ['ko-KR', '한국어/韓國語'],
  ['lv-LV', 'Latviešu'],
  ['lt-LT', 'Lietuvių'],
  ['nl-NL', 'Nederlands (Nederland)'],
  ['nl-BE', 'Nederlands (België)'],
  ['nb-NO', 'Norsk (Bokmål)'],
  ['nn-NO', 'Norsk (Nynorsk)'],
  ['no-NO', 'Norsk'],
  ['pl-PL', 'Polski'],
  ['pt-PT', 'Português'],
  ['ru-RU', 'Русский'],
  ['sk-SK', 'Slovenčina'],
  ['sl-SI', 'Slovenščina'],
  ['fi-FI', 'Suomi'],
  ['sv-FI', 'Svenska (Finland)'],
  ['sv-SE', 'Svenska (Sverige)'],
]);

export const sortedLocalesList = Array.from(localeToLocalName.keys());

export type TranslationKey = keyof typeof enUSMessages;
