import { generateCodeChallenge, generateCodeVerifier } from '@ringcentral/easy-pkce';

import './easy-pkce.d';
import { CurityConfig } from './types';

export class OidcClient {
  codeVerifier;
  config;

  constructor(curityConfig: CurityConfig) {
    this.config = curityConfig;
    this.codeVerifier = generateCodeVerifier(64);
  }

  private getTokens = async (code: string, codeVerifier: string) =>
    await fetch(this.config.tokenEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: this.getBody(code, codeVerifier),
    });

  private getBody = (code: string, codeVerifier: string) =>
    new URLSearchParams({
      client_id: this.config.clientId,
      grant_type: 'authorization_code',
      code: code,
      code_verifier: codeVerifier,
      redirect_uri: this.config.redirectUri,
    });

  fetchTokens = async (code: string) => {
    const tokensResponse = await this.getTokens(code, this.codeVerifier);
    const tokens = await tokensResponse.json();
    return tokens;
  };

  getAuthorizationUrl = async () => {
    const codeChallenge = await generateCodeChallenge(this.codeVerifier);

    const url = new URL(this.config.authorizationEndpoint);
    const queryParams = url.searchParams;
    queryParams.append('client_id', this.config.clientId);
    queryParams.append('scope', this.config.scope);
    queryParams.append('response_type', 'code');
    queryParams.append('code_challenge_method', 'S256');
    queryParams.append('code_challenge', codeChallenge);
    queryParams.append('redirect_uri', this.config.redirectUri);
    return url;
  };
}
