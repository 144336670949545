import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { EstimatedTime, FrontendAlert } from '@tracking/data';
import { dateFnsLocales, useFormatMessage } from '@tracking/i18n';

import { getTimeFormat } from '../helpers/get-date-format';
import { Infobox } from './infobox';

type ProcessedAlert = { message: string; isoDate?: string };

type HeaderAlertsProps = {
  alerts: ProcessedAlert[];
  locale: string;
};

export const HeaderAlerts = ({ alerts, locale }: HeaderAlertsProps) => {
  return (
    <>
      {alerts.map((alert, index) => (
        <Infobox
          key={index}
          message={alert.message}
          label={
            alert.isoDate &&
            format(parseISO(alert.isoDate), `EEEE d MMMM ${getTimeFormat(locale)}`, {
              locale: dateFnsLocales.get(locale),
            })
          }
          data-branding="header-alert"
          data-test="header-alert"
        />
      ))}
    </>
  );
};

const formatAlertMessageDate = (date: string | undefined, locale: string) =>
  date
    ? format(parseISO(date), `EEEE d MMMM`, {
        locale: dateFnsLocales.get(locale),
      })
    : undefined;

const parseAlertMessageDates = (dates: EstimatedTime | undefined, locale: string) => {
  const earliest = formatAlertMessageDate(dates?.earliest, locale);
  const latest = formatAlertMessageDate(dates?.latest, locale);
  return { earliest, latest, exact: earliest === latest };
};

const getMessageText = (alert: FrontendAlert, locale: string, customTranslationKey?: string) => {
  const formatMessage = useFormatMessage();
  switch (alert.type) {
    case 'ALERT_DELIVERY_DELAYED':
      return formatMessage('HEADER.ALERT_DELIVERY_DELAYED');
    case 'ALERT_ESTIMATED_DELIVERY_TIME_UPDATED':
      return formatMessage('HEADER.ALERT_ESTIMATED_DELIVERY_TIME_UPDATED');
    case 'ALERT_OUT_FOR_DELIVERY':
      return formatMessage('HEADER.ALERT_OUT_FOR_DELIVERY');
    case 'ALERT_DELIVERY_ATTEMPT_FAILED':
      return formatMessage('HEADER.ALERT_DELIVERY_ATTEMPT_FAILED');
    case 'ALERT_AWAITING_SUPPLIER':
      const parsedAwaitingSupplierDates = parseAlertMessageDates(
        alert.awaiting_supplier?.estimated_time,
        locale
      );
      return parsedAwaitingSupplierDates.exact
        ? formatMessage('HEADER.ALERT_AWAITING_SUPPLIER.EXACT', {
            date: <strong>{parsedAwaitingSupplierDates.earliest}</strong>,
          })
        : formatMessage('HEADER.ALERT_AWAITING_SUPPLIER.RANGE', {
            earliest: <strong>{parsedAwaitingSupplierDates.earliest}</strong>,
            latest: <strong>{parsedAwaitingSupplierDates.latest}</strong>,
          });
    case 'ALERT_LINEHAUL_TO_CARRIER':
      const parsedLinehaulDates = parseAlertMessageDates(alert.linehaul?.estimated_time, locale);
      return parsedLinehaulDates.exact
        ? formatMessage('HEADER.ALERT_LINEHAUL_TO_CARRIER.EXACT', {
            date: <strong>{parsedLinehaulDates.earliest}</strong>,
          })
        : formatMessage('HEADER.ALERT_LINEHAUL_TO_CARRIER.RANGE', {
            earliest: <strong>{parsedLinehaulDates.earliest}</strong>,
            latest: <strong>{parsedLinehaulDates.latest}</strong>,
          });
    case 'ALERT_DELIVERY_READY_TO_PICK_UP':
      return formatMessage(
        'HEADER.ALERT_DELIVERY_READY_TO_PICK_UP',
        undefined,
        customTranslationKey
      );
    case 'ALERT_UNDEFINED':
    default:
      return '';
  }
};
export const getAlertMessagesByType = (
  alerts: FrontendAlert[],
  locale: string,
  customTranslationKey?: string
) =>
  alerts
    .map(alert => ({
      message: getMessageText(alert, locale, customTranslationKey),
      isoDate: alert.created_at,
    }))
    .filter(processedAlert => Boolean(processedAlert.message));
