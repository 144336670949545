import Cookie from 'js-cookie';
import { createContext, useContext } from 'react';

const defaultCookieImplementation = {
  set: Cookie.set,
  get: (name: string) => Promise.resolve(Cookie.get(name)),
};

export type CookieContext = typeof defaultCookieImplementation;

const cookieContext = createContext<CookieContext>(defaultCookieImplementation);
const { Provider: CookieContextProvider } = cookieContext;

const useCookie = () => useContext(cookieContext);

export { CookieContextProvider, useCookie };
