import { ShippingLogo } from '@ingrid/react-shipping-logo';
import { toDataURL } from 'qrcode';
import { useEffect, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

import { useFormatMessage } from '@tracking/i18n';

import { Button, Image, MotionStack, Stack, Text } from '../../atoms';
import { Modal } from '../../molecules';
// import { GoogleWalletButton } from './google-wallet-button/google-wallet-button';
import * as styles from './identify.css';
import { IdentifiedData } from './types';

const identifiedAnimations = {
  initialQrCodeSection: {
    y: '30%',
    opacity: 0,
  },
  animateQrCodeSection: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.5, bounce: 0 },
  },
  initialLables: {
    opacity: 0,
  },
  animateLables: {
    opacity: 1,
    transition: { delay: 0.8, bounce: 0 },
  },
};

type IdentifiedProps = {
  data: IdentifiedData;
  onGoogleWalletButtonClick: () => void;
  onClose: () => void;
};
export const Identified = ({ onClose, data, onGoogleWalletButtonClick }: IdentifiedProps) => {
  const formatMessage = useFormatMessage();
  const [qrCodeImage, setQrCodeImage] = useState<string>();

  const getQrCodeImage = async (qrCodeContent: string) => {
    try {
      const generatedQrCodeImage = await toDataURL(qrCodeContent);
      setQrCodeImage(generatedQrCodeImage);
    } catch (error) {
      onClose();
    }
  };

  useEffect(() => {
    getQrCodeImage(data.qrCodeContent);
  }, [data]);

  const isShareAvailable = Boolean(navigator.share);

  const shareQrCode = async (base64Image: string) => {
    if (!isShareAvailable) {
      throw new Error('Share API is not available on your device/browser.');
    }
    try {
      const blob = await (await fetch(base64Image)).blob();
      await navigator.share({
        text: `With this QR code you will be able to pickup delivery ${data.trackingNumber} coming from ICA`,
        files: [new File([blob], 'qr_code.png', { type: 'image/png' })],
      });
    } catch (error) {
      // no-op for abort error as it's normal operation
      if (error instanceof DOMException && error.name === 'AbortError') {
        return;
      }

      throw new Error('Error sharing image', { cause: error as Error });
    }
  };

  return (
    <Modal
      headerText={formatMessage('IDENTIFY.IDENTIFIED.MODAL_TITLE')}
      onClose={onClose}
      headerSpacing="dense"
      bodySpacing="none"
      fullscreen
      shrinkBody
      footerContent={
        <>
          <MotionStack
            initial="initialQrCodeSection"
            animate="animateQrCodeSection"
            variants={identifiedAnimations}
            space={12}
          >
            {isShareAvailable ? (
              <Button
                buttonType="secondary"
                border="solidBlack"
                onClick={qrCodeImage ? () => shareQrCode(qrCodeImage) : undefined}
                isLoading={!qrCodeImage}
              >
                <Text>{formatMessage('IDENTIFY.IDENTIFIED.SHARE_QR_CODE')}</Text>
              </Button>
            ) : undefined}
            {/* <GoogleWalletButton onClick={onGoogleWalletButtonClick} /> */}
          </MotionStack>
        </>
      }
    >
      <Stack data-branding="identified">
        <MotionStack
          data-branding="identified-qr-code-section"
          className={styles.qrCodeSection}
          initial="initialQrCodeSection"
          animate="animateQrCodeSection"
          variants={identifiedAnimations}
        >
          <Stack width={100} className={styles.qrCodeSectionHeader} space={4}>
            <ShippingLogo
              method={data.carrier || ''}
              variant="Round30"
              width={30}
              height={30}
              alt={formatMessage('COMMON.CARRIER_LOGO_ALT')}
              data-test="parcel-carrier-logo"
            />
            <Text textStyle="regular1420" data-branding="tracking-number">
              {data.trackingNumber}
            </Text>
          </Stack>
          <div className={styles.qrCodeImage}>
            {qrCodeImage ? (
              <Image
                width={160}
                height={160}
                src={qrCodeImage}
                alt={formatMessage('COMMON.QR_CODE_ALT')}
              />
            ) : (
              <PulseLoader color="black" size="6px" speedMultiplier={0.8} />
            )}
          </div>
          <Text data-branding="qr-code-description">
            {formatMessage('IDENTIFY.IDENTIFIED.QR_CODE.DESCRIPTION')}
          </Text>
        </MotionStack>
        <MotionStack
          space={2}
          padding={16}
          initial="initialLables"
          animate="animateLables"
          variants={identifiedAnimations}
          data-branding="instructions"
        >
          <Text textStyle="regular1420" textColor="darkGrey" data-branding="block-label">
            {formatMessage('IDENTIFY.IDENTIFIED.INSTRUCTIONS.HEADER')}
          </Text>
          <Text textStyle="regular1420" data-branding="block-text">
            {formatMessage('IDENTIFY.IDENTIFIED.INSTRUCTIONS.CONTENT')}
          </Text>
        </MotionStack>
      </Stack>
    </Modal>
  );
};
