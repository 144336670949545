import { FC } from 'react';

import { Branding, PrivateOrderCart, getSectionExpandProps, useAnalytics } from '@tracking/data';
import { CartItemsUI } from '@tracking/ui';

type CartItemsProps = {
  cart: PrivateOrderCart;
  branding: Branding;
};

export const CartItems: FC<CartItemsProps> = ({ cart, branding }) => {
  const { currency, items = [], prices } = cart;

  const analytics = useAnalytics();

  if (!cart.items) {
    return null;
  }

  return (
    <CartItemsUI
      dataTest="cart-items"
      // TODO: ensure the branding consists default values
      showImages={branding?.sections_config?.cart_items?.show_images ?? false}
      showPrices={branding?.sections_config?.cart_items?.show_prices ?? false}
      showSummary={branding?.sections_config?.cart_items?.show_summary ?? false}
      expandProps={{
        dataTest: 'cart-items-expand',
        ...getSectionExpandProps(branding, 'cart_items'),
      }}
      items={items}
      currency={currency}
      prices={prices}
      onExpandPosthogCapture={() => analytics.capture('expand_order_details')}
      onHidePosthogCapture={() => analytics.capture('hide_order_details')}
    />
  );
};
