import { FC } from 'react';
import { FormattedMessage as IntlFormatedMessage } from 'react-intl';

import type { TranslationKey } from './locale-to-messages';

interface Props extends React.ComponentProps<typeof IntlFormatedMessage> {
  id: TranslationKey;
}

export const FormattedMessage: FC<Props> = props => {
  return <IntlFormatedMessage {...props} />;
};
