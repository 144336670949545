import { z } from 'zod';

export const dateTimeRangeSchema = z
  .object({
    end: z
      .string()
      .describe(
        `End of the interval. Date is returned in RFC3339 format. Example '2018-09-08T22:47:31Z'.`
      )
      .optional(),
    start: z.string().optional(),
  })
  .describe(
    `Provides a date interval. Depending on a case, 'start' and 'end' parts are not guaranteed to be present.`
  );
