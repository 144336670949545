import type { FC } from 'react';

type Props = {
  className?: string;
  fill?: 'black' | 'white';
  width?: string;
  height?: string;
};

// IngridLogo uses inlined svg instead of CDN so that it can be modified by branding CSS file
export const IngridLogo: FC<Props> = ({
  fill = 'black',
  width = '65px',
  height = '24px',
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 38 14"
      className={className}
    >
      <path
        d="M28.9143 0.185935C28.6329 0.0124057 28.2995 -0.0422492 27.977 0.0329012L27.9674 0.0342676C27.3075 0.1955 26.8976 0.862289 27.0533 1.52635C27.1859 2.08929 27.6832 2.48144 28.2612 2.48144C28.3568 2.48144 28.4539 2.47051 28.5468 2.44865C28.8692 2.37213 29.1425 2.17537 29.3174 1.8939C29.4923 1.61243 29.5456 1.27903 29.4704 0.956569C29.3939 0.634105 29.1958 0.359464 28.9143 0.185935Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.1888 4.64693V1.02332H38.0006V10.8803H36.2639V9.90473L36.1314 10.04C35.5083 10.6781 34.6611 11.0293 33.7457 11.0293C31.7111 11.0293 30.1166 9.38961 30.1166 7.29633C30.1166 5.19485 31.7111 3.54837 33.7457 3.54837C34.6488 3.54837 35.47 3.8886 36.0562 4.5062L36.1888 4.64693ZM31.9407 7.2977C31.9407 8.54246 32.8835 9.51805 34.0873 9.51805C35.2992 9.51805 36.2489 8.54246 36.2489 7.2977C36.2489 6.04473 35.2992 5.06231 34.0873 5.06231C32.8835 5.06231 31.9407 6.04337 31.9407 7.2977Z"
        fill="black"
      />
      <path
        d="M7.54951 4.4354C8.06053 3.86563 8.8257 3.53906 9.64962 3.53906C11.2783 3.53906 12.4603 4.70185 12.4589 6.3046V10.871H10.6484V6.71998C10.6484 6.21989 10.4817 5.80041 10.1661 5.50664C9.89557 5.25523 9.52255 5.11039 9.1427 5.11039C8.2163 5.11039 7.51808 5.80314 7.51808 6.72134V10.871H5.70764V3.68663H7.41561V4.5857L7.54951 4.4354Z"
        fill="black"
      />
      <path
        d="M24.0266 5.07741L24.1728 4.79183C24.5362 4.07995 25.2522 3.68781 26.1868 3.68781H26.5257V5.28919H26.3508C24.8778 5.28919 24.1304 6.05163 24.1304 7.55464V10.8722H22.32V3.68781H24.0266V5.07741Z"
        fill="black"
      />
      <path d="M29.1918 3.68781H27.3813V10.8722H29.1918V3.68781Z" fill="black" />
      <path
        d="M3.24421 0.11104C3.33985 0.089178 3.4355 0.0782471 3.52978 0.0782471C4.09136 0.0782471 4.60101 0.46493 4.73628 1.03334C4.89342 1.69876 4.47941 2.36965 3.81398 2.52679C3.49425 2.60194 3.15539 2.54728 2.87665 2.37375C2.59518 2.20022 2.39842 1.92558 2.3219 1.60312C2.24539 1.28065 2.30004 0.94726 2.47357 0.665787C2.6471 0.384314 2.92174 0.187557 3.24421 0.11104Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3606 4.5037L19.2294 4.37253C18.6597 3.80139 17.8754 3.48712 17.0214 3.48712C17.0077 3.48849 17.0009 3.48849 16.9913 3.48712H16.99C16.4489 3.48712 15.9406 3.60326 15.476 3.83418C14.167 4.44222 13.3554 5.76213 13.3554 7.2788C13.3554 9.32017 14.8803 10.8601 16.9025 10.8601C17.9546 10.8601 18.6651 10.6346 19.1379 10.1496L19.2677 10.017L19.2732 10.3695C19.2732 11.6375 18.5066 12.456 17.3206 12.456C16.5814 12.456 15.9338 12.1294 15.5785 11.5801H13.6382C13.9375 12.7183 15.0524 14 17.2755 14C18.4615 14 19.4412 13.6379 20.1108 12.952C20.7379 12.3084 21.0699 11.4162 21.0699 10.3709V3.68798H19.3633V4.5037H19.3606ZM17.3111 9.42538C16.0923 9.42538 15.1727 8.49898 15.1727 7.27061C15.1727 6.02994 16.1127 5.05845 17.3111 5.05845C18.5189 5.05845 19.4645 6.02994 19.4645 7.27061C19.4658 8.50034 18.5394 9.42538 17.3111 9.42538Z"
        fill="black"
      />
      <path
        d="M2.73035 3.68622H4.31534L4.45198 3.68758V10.8706H2.73582V6.33015C2.33 6.52964 1.86407 6.64305 1.35715 6.64305H0.72998V5.05806H1.35851C2.11548 5.05806 2.73035 4.44319 2.73035 3.68622Z"
        fill="black"
      />
    </svg>
  );
};
