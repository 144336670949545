import { Parcel, PickupLocationType, ProgressItem, isHomeDelivery } from '@tracking/data';
import { FormatMessageFn, createCompoundTranslationSuffix } from '@tracking/i18n';

export const getProgressText = (
  formatMessage: FormatMessageFn,
  status: ProgressItem | undefined,
  deliveryType: Parcel['delivery_type'],
  locationType: PickupLocationType | undefined,
  carrier: Parcel['carrier'],
  merchant: string | undefined,
  translationKeySuffix?: string
) => {
  switch (status?.step) {
    case 'STEP_CONFIRMED_BY_MERCHANT':
      return formatMessage('PROGRESS_DETAILS.PROGRESS_STATUS.CONFIRMED');

    case 'STEP_AWAITING_SUPPLIER':
      return formatMessage('PROGRESS_DETAILS.PROGRESS_STATUS.AWAITING_SUPPLIER');

    case 'STEP_PREPARED_BY_MERCHANT':
      return formatMessage('PROGRESS_DETAILS.PROGRESS_STATUS.PREPARED');

    case 'STEP_LINEHAUL_TO_CARRIER':
      return formatMessage('PROGRESS_DETAILS.PROGRESS_STATUS.LINEHAUL_TO_CARRIER');

    case 'STEP_SUBMITTED_TO_CARRIER':
      return formatMessage(
        'PROGRESS_DETAILS.PROGRESS_STATUS.SUBMITTED',
        { merchant },
        translationKeySuffix
      );

    case 'STEP_ON_ROUTE':
      return formatMessage(
        isHomeDelivery(deliveryType)
          ? 'PROGRESS_DETAILS.PROGRESS_STATUS.ON_ROUTE.TYPE_DELIVERY'
          : 'PROGRESS_DETAILS.PROGRESS_STATUS.ON_ROUTE.TYPE_PICKUP'
      );

    case 'STEP_DELIVERED_TO_PICKUP_POINT':
      return formatMessage(
        'PROGRESS_DETAILS.PROGRESS_STATUS.DELIVERED.TYPE_PICKUP',
        undefined,
        createCompoundTranslationSuffix([locationType, translationKeySuffix])
      );

    case 'STEP_DELIVERED_TO_CONSUMER':
    case 'STEP_DELIVERED_TO_MERCHANT':
      return formatMessage(
        isHomeDelivery(deliveryType)
          ? 'PROGRESS_DETAILS.PROGRESS_STATUS.DELIVERED.TYPE_DELIVERY'
          : 'PROGRESS_DETAILS.PROGRESS_STATUS.PICKED_UP'
      );

    case 'STEP_TERMINATED':
      return formatMessage('PROGRESS_DETAILS.PROGRESS_STATUS.TERMINATED', { carrier });

    case 'STEP_SENT_BACK':
      return formatMessage(
        'PROGRESS_DETAILS.PROGRESS_STATUS.SENT_BACK',
        { carrier },
        translationKeySuffix
      );

    default:
      return '';
  }
};
