import clsx from 'clsx';
import { ReactNode } from 'react';

import { IngridLogo } from '../atoms/ingrid-logo';
import { PrivacyPolicyLink } from '../atoms/privacy-policy-link';
import * as styles from './footer.css';

type FooterProps = {
  children?: ReactNode;
  privacyPolicyOnClick?: React.MouseEventHandler<HTMLAnchorElement>;
  className?: string;
};

export const Footer = ({ children, privacyPolicyOnClick, className }: FooterProps) => {
  return (
    <footer data-branding="widget-footer" className={clsx(styles.footer, className)}>
      <div className={styles.wrapper}>
        <PrivacyPolicyLink onClick={privacyPolicyOnClick} />

        {children}
      </div>
      <div className={styles.ingridLogoContainer}>
        <IngridLogo width="38px" height="14px" />
      </div>
    </footer>
  );
};
