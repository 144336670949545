import type { PrivateOrderCart } from '../../../generated/types';
import { BrandingData, getBrandingSenderName } from '../branding';
import { GeneralGetTrackingDataResponse } from './types';

export const getOrder = (data: GeneralGetTrackingDataResponse) => data.order;

export const getShowDetails = (data: GeneralGetTrackingDataResponse) =>
  'has_private_view' in data ? data.has_private_view : false;

/**
 * This selector basically renames `site` to `merchantBrand` because from
 * tracking page point of view this is more natural naming/concept than `site`,
 * e.g. when user sees order status "Prepared by Kokets", they understand
 * Kokets (subbrand for Adlibris) is a shop/merchant/brand, not a site.
 */
export const getSenderName = (
  data: GeneralGetTrackingDataResponse,
  branding: BrandingData
): string => {
  const order = getOrder(data);
  return getBrandingSenderName(branding) || (order?.sender_name ?? '');
};

// TODO: remove the condition once both GetTrackingDataResponse and GetPrivateTrackingDataResponse have been unified
// TRACK-1168 https://ingrid-ab.atlassian.net/browse/TRACK-1168
export const getCart = (
  data: GeneralGetTrackingDataResponse
): PrivateOrderCart & Required<Pick<PrivateOrderCart, 'items'>> => {
  const order = getOrder(data);
  if ('cart' in order) {
    return {
      ...order.cart,
      items: order.cart?.items ?? [],
    };
  }

  return {
    items: order.items ?? [],
  };
};

export const getExternalId = (data: GeneralGetTrackingDataResponse) => {
  const order = getOrder(data);
  return order?.external_id;
};

export const getParcels = (data: GeneralGetTrackingDataResponse) => {
  const order = getOrder(data);
  return order?.parcels || [];
};

export const getParcelsOutbound = (data: GeneralGetTrackingDataResponse) => {
  const order = getOrder(data);

  const indexedParcels = order?.parcels?.map((parcel, idx) => ({
    ...parcel,
    index: idx,
  }));
  return indexedParcels?.filter(parcel => parcel.direction_type !== 'RETURN') || [];
};

export const getParcelsReturn = (data: GeneralGetTrackingDataResponse) => {
  const order = getOrder(data);
  const indexedParcels = order?.parcels?.map((parcel, idx) => ({
    ...parcel,
    index: idx,
  }));
  return indexedParcels?.filter(parcel => parcel.direction_type === 'RETURN') || [];
};

export const getPreselectedParcelIndex = (data: GeneralGetTrackingDataResponse) => {
  const order = getOrder(data);
  if (order?.preselected_parcel_index) {
    return parseInt(order?.preselected_parcel_index, 10);
  }
  return undefined;
};

export const getUserIdentifyData = (data: GeneralGetTrackingDataResponse) => ({
  analytics_id: data.order.analytics_id,
  tos_id: data.tos_id,
});

export const getSiteIdentifyData = (data: GeneralGetTrackingDataResponse) => ({
  site_id: data.site_id,
  site_name: data.order.site_name,
});
