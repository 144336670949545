import { z } from 'zod';

import { privateOrderCartPricesShippingComponentSchema } from './privateOrderCartPricesShippingComponentSchema';
import { privateOrderCartPricesTotalsSchema } from './privateOrderCartPricesTotalsSchema';

export const privateOrderCartPricesSchema = z.object({
  shipping_components: z
    .array(z.lazy(() => privateOrderCartPricesShippingComponentSchema))
    .describe(`ShippingComponents represents the shipping components of the cart.`)
    .optional(),
  total: z.lazy(() => privateOrderCartPricesTotalsSchema).optional(),
});
