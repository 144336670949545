import { FC, Fragment } from 'react';

import { getShowDetails, useAnalytics, useAuthState, useTrackingData } from '@tracking/data';
import { useFormatMessage } from '@tracking/i18n';
import { Button, Text } from '@tracking/ui';

import { useAuthModal } from '../auth-modal/auth-modal-context';
import * as styles from './authentication.css';

export const Authentication: FC = () => {
  const authContext = useAuthState();
  const analytics = useAnalytics();
  const formatMessage = useFormatMessage();
  const { showDetailsButton } = useTrackingData(data => ({
    showDetailsButton: getShowDetails(data),
  }));
  const modal = useAuthModal();

  const onModalOpen = () => {
    analytics.capture('open_verification_modal_via_show_details_button');
    modal.open();
  };

  if (authContext?.isAuthenticated || !showDetailsButton) {
    return null;
  }

  return (
    <Fragment>
      <div className={styles.rootElement}>
        <Button
          onClick={onModalOpen}
          className={styles.showDetails}
          dataTest="auth-show-details-button"
        >
          <Text textColor="white">{formatMessage('AUTH.SHOW_DETAILS')}</Text>
        </Button>
        <Text textColor="darkGrey" textStyle="light1216">
          {formatMessage('AUTH.DESCRIPTION')}
        </Text>
      </div>
    </Fragment>
  );
};
