import type { FC, ReactNode } from 'react';
import { IntlProvider } from 'react-intl';

export const DEFAULT_LOCALE = 'en-US';

export const I18nProvider: FC<{
  locale: string;
  localeMessages: Record<string, string>;
  children: ReactNode;
}> = ({ children, locale = DEFAULT_LOCALE, localeMessages }) => {
  return (
    <IntlProvider locale={locale} messages={localeMessages} defaultLocale={DEFAULT_LOCALE}>
      {children}
    </IntlProvider>
  );
};
