import { FC, ReactNode, createContext, useContext, useState } from 'react';

type Props = {
  isAuthenticated: boolean;
  children: ReactNode;
};

type AuthContext = {
  isAuthenticated: boolean;
  setAuthenticationValue: (authValue: boolean) => void;
};

export const authContext = createContext<AuthContext | null>(null);

export const AuthProvider: FC<Props> = ({ children, isAuthenticated }) => {
  const [isAuthenticatedValue, setAuthenticationValue] = useState(isAuthenticated);
  const { Provider } = authContext;

  const value: AuthContext = {
    isAuthenticated: isAuthenticatedValue,
    setAuthenticationValue,
  };

  return <Provider value={value}>{children}</Provider>;
};

export const useAuthState = () => useContext(authContext);
