import { PageWidgetCustomEvents, PrivateParcel, PublicParcel } from '@tracking/data';

import { OneOffSurvey } from './one-off-survey';

type PartialParcel = Pick<PublicParcel | PrivateParcel, 'alerts' | 'progress'>;
type ConsiderChangeAddresSurveyProps = {
  posthogCapture: (event: PageWidgetCustomEvents, props: Record<string, unknown>) => void;
  parcel: PartialParcel | undefined;
  locale: string;
  siteId: string | undefined;
  // added support for undefined to not fight with TS types for the POC purposes only
  env: string | undefined;
};

// An example survey for sake of the POC
// Ultimately this should be a generic solution
const SURVEY_ID = 'consider_change_address';

// blacklist idealofsweden because of issues with the broken custom css (fallback font)
// this is for POC purposes only
const SITE_ID_BLACKLIST = {
  production: ['6094e1dea72849a2bf439b60c2c5f7f4'],
  stage: ['6094e1dea72849a2bf439b60c2c5f7f4'],
  development: ['625d5df5-ff6d-4ca4-82d3-0158c6afbe6a'],
  local: ['625d5df5-ff6d-4ca4-82d3-0158c6afbe6a'],
};

const ConsiderChangeAddressSurvey = ({
  parcel,
  siteId,
  locale,
  posthogCapture,
  env,
}: ConsiderChangeAddresSurveyProps) => {
  const surveyEnabled =
    // For the initial POC we agreed to use hardcoded translations only for swedish.
    (locale === 'sv-SE' || locale === 'sv-FI') &&
    // restrict survey for some sites
    !(siteId && env && SITE_ID_BLACKLIST[env as keyof typeof SITE_ID_BLACKLIST]?.includes(siteId));

  if (!surveyEnabled || !parcel) {
    return null;
  }

  return (
    <OneOffSurvey
      surveyId={SURVEY_ID}
      title="Vi vill höra din åsikt!"
      question="Har du någonsin funderat på att ändra paketets leveransadress?"
      onDisplay={() => {
        posthogCapture('survey_show', getSurveyEventProps(SURVEY_ID, parcel));
      }}
      onAnswer={answer => {
        posthogCapture('survey_answer', {
          ...getSurveyEventProps(SURVEY_ID, parcel),
          survey_answer: answer,
        });
      }}
    />
  );
};

const getSurveyEventProps = (surveyId: string, { progress, alerts }: PartialParcel) => {
  const currentStep = progress?.find(e => !e.time)?.step;
  const alertList = alerts?.map(alert => alert.type).sort();
  return {
    survey_id: surveyId,
    parcel_step: currentStep,
    parcel_alerts: alertList,
  };
};
