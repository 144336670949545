import { z } from 'zod';

import { anySchema } from './anySchema';

export const errorSchema = z.object({
  code: z.number().optional(),
  details: z.array(z.lazy(() => anySchema)).optional(),
  error: z.string().optional(),
  message: z.string().optional(),
});
