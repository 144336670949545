import { ShippingLogo } from '@ingrid/react-shipping-logo';

import {
  Address,
  AnalyticsEventHandler,
  DeliveryType,
  Location,
  formatAddressLines,
} from '@tracking/data';
import { createCompoundTranslationSuffix, useFormatMessage } from '@tracking/i18n';

import { ContentBlock, Stack, Text } from '../../../atoms';
import { formatLocationType, formatOperationalHours } from '../../../helpers';
import { CopyButton, Expand, ExpandProps } from '../../../molecules';
import { OpeningHours } from '../../../molecules/opening-hours';
import * as styles from '../delivery-details.css';

export const formatAddress = ({ address_lines, postal_code, city }: Address) =>
  formatAddressLines([...(address_lines ?? []), postal_code ?? '', city ?? '']);

type PickupPointProps = {
  address: Address | undefined;
  analyticsEventHandler: AnalyticsEventHandler;
  carrier: string | undefined;
  carrierName: string | undefined;
  locale: string;
  location: Location | undefined;
  trackingNumber: string | undefined;
  deliveryType?: DeliveryType;
  pickupInstructions?: string;
  translationKey?: string;
  expandProps: Pick<ExpandProps, 'defaultExpanded' | 'expandToggleDisabled'>;
};
export const PickupPoint = ({
  address,
  analyticsEventHandler,
  carrier,
  carrierName = '',
  deliveryType,
  locale,
  location,
  pickupInstructions,
  trackingNumber,
  translationKey,
  expandProps,
}: PickupPointProps) => {
  const formatMessage = useFormatMessage();

  if (!address) {
    return null;
  }

  const displayPickupInstructions =
    (deliveryType === 'INSTORE' || deliveryType === 'PICKUP') && Boolean(pickupInstructions);

  const isInstore = deliveryType === 'INSTORE';

  const header = (
    <Stack paddingRight={24}>
      <Text textStyle="light1420" textColor="darkGrey" data-branding="block-label">
        {formatMessage('DELIVERY_DETAILS.PICK_UP')}
      </Text>
      <Text
        dataTest="pickup-location-address"
        textStyle="light1420"
        textColor="black"
        data-branding="pickup-location-address"
      >
        {address.name}
      </Text>
    </Stack>
  );

  const instructions = displayPickupInstructions && (
    <Text
      dataTest="pickup-instructions"
      textColor="darkGrey"
      textStyle="light1216"
      data-branding="block-secondary-text"
    >
      {pickupInstructions}
    </Text>
  );

  return (
    <div
      className={styles.rootElement}
      data-test="delivery-details"
      data-branding="section-delivery-details-pickup-point"
    >
      <Expand
        {...expandProps}
        dataTest="delivery-details-expand"
        unexpandedChildren={header}
        expandedChildren={
          <>
            {header}
            <Stack>
              <Text textColor="darkGrey" data-branding="block-text">
                {formatAddress(address)}
              </Text>
              {
                // for non-instore parcels, the instructions are part of the "delivery to" section
              }
              {isInstore && instructions}
            </Stack>
            {location?.operational_hours && (
              <OpeningHours
                dataTest="opening-hours"
                operationalHours={formatOperationalHours(
                  location.operational_hours,
                  formatMessage,
                  locale
                )}
                headerText={formatMessage('DELIVERY_DETAILS.OPENING_HOURS')}
              />
            )}
            {!isInstore && (
              <ContentBlock marginTop={16} data-branding="block-delivery-to">
                <Stack>
                  <Text data-branding="block-label">
                    {formatMessage(
                      'DELIVERY_DETAILS.DELIVERY_TO',
                      undefined,
                      createCompoundTranslationSuffix([location?.location_type, translationKey])
                    )}
                  </Text>
                  <Text dataTest="delivery-to" textColor="darkGrey" data-branding="block-text">
                    {formatLocationType(
                      location?.location_type,
                      carrierName,
                      formatMessage,
                      translationKey
                    )}
                  </Text>
                  {instructions}
                </Stack>
                <ShippingLogo
                  data-branding="shipping-logo-pickup-point"
                  method={carrier || ''}
                  variant="Round30"
                  width={32}
                  height={32}
                  alt={formatMessage('COMMON.CARRIER_LOGO_ALT')}
                  data-test={`parcel-carrier-logo-${carrierName}`}
                />
              </ContentBlock>
            )}
            {trackingNumber && (
              <ContentBlock marginTop={16} data-branding="block-tracking-number">
                <Stack>
                  <Text data-branding="block-label">
                    {formatMessage('DELIVERY_DETAILS.TRACKING_NUMBER')}
                  </Text>
                  <Text dataTest="tracking-number" textColor="darkGrey" data-branding="block-text">
                    {trackingNumber}
                  </Text>
                </Stack>
                <CopyButton
                  textToCopy={trackingNumber}
                  onCopy={() => analyticsEventHandler('copy_tracking_number')}
                />
              </ContentBlock>
            )}
          </>
        }
        onExpandPosthogCapture={() => analyticsEventHandler('expand_delivery_details')}
        onHidePosthogCapture={() => analyticsEventHandler('hide_delivery_details')}
      />
    </div>
  );
};
