import { Parcel, ProgressItem, isHomeDelivery } from '@tracking/data';
import type { FormatMessageFn } from '@tracking/i18n';

export const getTimelineItemText = (
  formatMessage: FormatMessageFn,
  deliveryType: Parcel['delivery_type'],
  status: ProgressItem | undefined,
  merchant: string,
  carrier: Parcel['carrier'],
  destination: string,
  translationKeySuffix: string | undefined
) => {
  if (!status) {
    return '';
  }

  switch (status.step) {
    case 'STEP_RETURN_REQUESTED':
      return formatMessage('PROGRESS_DETAILS.PARCEL_STATUS.RETURN_REQUESTED');
    case 'STEP_CONFIRMED_BY_MERCHANT':
      return formatMessage('PROGRESS_DETAILS.PARCEL_STATUS.CONFIRMED', { merchant });
    case 'STEP_AWAITING_SUPPLIER':
      return formatMessage('PROGRESS_DETAILS.PARCEL_STATUS.AWAITING_SUPPLIER');
    case 'STEP_PREPARED_BY_MERCHANT':
      return formatMessage('PROGRESS_DETAILS.PARCEL_STATUS.PREPARED', { merchant });
    case 'STEP_LINEHAUL_TO_CARRIER':
      return formatMessage('PROGRESS_DETAILS.PARCEL_STATUS.LINEHAUL_TO_CARRIER');
    case 'STEP_SUBMITTED_TO_CARRIER':
      return formatMessage(
        'PROGRESS_DETAILS.PARCEL_STATUS.SUBMITTED',
        { carrier },
        translationKeySuffix
      );
    case 'STEP_ON_ROUTE':
      return formatMessage('PROGRESS_DETAILS.PARCEL_STATUS.ON_ROUTE', {
        destination: destination || undefined,
      });
    case 'STEP_DELIVERED_TO_PICKUP_POINT':
      return formatMessage('PROGRESS_DETAILS.PARCEL_STATUS.DELIVERED', {
        destination: destination || undefined,
      });
    case 'STEP_DELIVERED_TO_CONSUMER':
      return isHomeDelivery(deliveryType)
        ? formatMessage('PROGRESS_DETAILS.PARCEL_STATUS.DELIVERED', {
            destination: destination || undefined,
          })
        : formatMessage('PROGRESS_DETAILS.PARCEL_STATUS.PICKED_UP', {
            destination: destination || undefined,
          });

    case 'STEP_DELIVERED_TO_MERCHANT':
      return formatMessage('PROGRESS_DETAILS.PARCEL_STATUS_DELIVERED_TO_MERCHANT', {
        destination: destination || undefined,
      });
    case 'STEP_TERMINATED':
      return formatMessage('PROGRESS_DETAILS.PARCEL_STATUS.TERMINATED', { carrier });
    case 'STEP_SENT_BACK':
      return formatMessage('PROGRESS_DETAILS.PARCEL_STATUS.SENT_BACK', { carrier });

    default:
      return '';
  }
};
