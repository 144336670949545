import { z } from 'zod';

import { publicOrderSchema } from './publicOrderSchema';

export const getPublicTrackingDataResponseSchema = z.object({
  has_private_view: z.boolean(),
  order: z.lazy(() => publicOrderSchema),
  site_id: z.string(),
  tos_id: z.string(),
});
