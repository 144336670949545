import clsx from 'clsx';
import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';

import * as styles from './slide-in.css';
import { useInView } from './use-in-view';

type SlideInProps = {
  children: ReactNode;
  show: boolean;
  className?: string;
};

export const SlideIn = ({ children, show, className }: SlideInProps) => {
  const [contentHeight, setContentHeight] = useState<number | null>(null);

  const [onceInView, setOnceInView] = useState(false);

  const [inViewRef, inView] = useInView({
    enabled: contentHeight !== null && show,
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      setOnceInView(true);
    }
  }, [inView]);

  const contentRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!contentRef.current) {
      return;
    }
    inViewRef(contentRef.current);
    setContentHeight(contentRef.current.offsetHeight);
  }, []);

  const slideInShow = show && onceInView;

  return (
    <div
      style={{ height: slideInShow ? (contentHeight ?? 0) : 0 }}
      className={clsx(
        styles.container,
        {
          [styles.slideInShow]: slideInShow,
          [styles.slideInHide]: !show,
        },
        className
      )}
    >
      <div ref={contentRef}>{children}</div>
    </div>
  );
};
