import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useRef, useState } from 'react';
import { MapRef, default as MapboxMap, Marker } from 'react-map-gl';

import { AnalyticsEventHandler, Coordinates } from '@tracking/data';

import { Pin } from '../../../../atoms';
import { getMapPadding } from '../../../../helpers';
import { useMapResizeUpdate } from '../../hooks';
import { FallbackMapError } from '../../types';
import { MapLocationPointer } from '../map-location-pointer';
import { LogoMapEntry } from '../private-map';
import * as styles from '../private-map.css';
import { MapControl } from './map-control';

type PrivateMapProps = {
  analyticsEventHandler: AnalyticsEventHandler;
  coordinates: Coordinates;
  mapboxApiKey?: string;
  logoMapEntry: LogoMapEntry;
  onError: (e: FallbackMapError) => void;
};

export const MapboxPrivateMap = ({
  analyticsEventHandler,
  coordinates,
  mapboxApiKey,
  logoMapEntry,
  onError,
}: PrivateMapProps) => {
  const map = useRef<MapRef>(null);
  const setupResizeUpdate = useMapResizeUpdate();

  const [viewState, setViewState] = useState({
    longitude: coordinates?.lng,
    latitude: coordinates?.lat,
    zoom: 15,
  });

  useEffect(() => {
    setViewState(prevState => ({
      ...prevState,
      longitude: coordinates?.lng,
      latitude: coordinates?.lat,
    }));
  }, [coordinates]);

  const handleOnMapCentre = () => {
    if (coordinates?.lat && coordinates.lng) {
      map?.current?.flyTo({ center: [coordinates.lng, coordinates.lat], zoom: 15 });
      analyticsEventHandler('map_centering');
    }
    return null;
  };

  return (
    <MapboxMap
      ref={map}
      {...viewState}
      initialViewState={{
        longitude: coordinates?.lng,
        latitude: coordinates?.lat,
        zoom: 15,
      }}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      mapboxAccessToken={mapboxApiKey}
      onMove={evt => setViewState(evt.viewState)}
      onLoad={setupResizeUpdate}
      onError={e => onError(e.error)}
    >
      {logoMapEntry && (
        <Marker longitude={coordinates?.lng} latitude={coordinates?.lat} anchor="bottom">
          <Pin
            src={logoMapEntry.pathPin}
            alt={logoMapEntry.name || 'Delivery location'}
            dataTest={`pin-${
              logoMapEntry.patterns.find(pattern => pattern.startsWith('carrier-')) ||
              logoMapEntry.patterns[0] ||
              'generic'
            }`}
            onLoad={event => {
              if (!map.current) {
                return;
              }

              map.current.setPadding({
                ...map.current.getPadding(),
                top: getMapPadding(event.currentTarget.height, map.current.getCanvas().height),
              });
            }}
          />
        </Marker>
      )}
      <MapControl position="bottom-right">
        <button className={styles.locationPointer} onClick={handleOnMapCentre}>
          <MapLocationPointer data-branding="map-location-pointer" />
        </button>
      </MapControl>
    </MapboxMap>
  );
};
