import { FC, ReactNode, createContext, useEffect, useState } from 'react';

type ContextValues = {
  isLangSelectorVisible: boolean;
  isLangSelectionDismissed: boolean;
  setLangSelectorVisibility: (value: boolean) => void;
  dismissLangSelection: () => void;
} | null;

// Every time you change smth. (f. ex. structure of a value) you should update VERSION variable.
const VERSION = 1;
const DISSMISS_LANG_KEY = `dismiss-lang-${VERSION}`;

export const langStoreContext = createContext<ContextValues>(null);

const { Provider } = langStoreContext;

export const LangStore: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [isLangSelectorVisible, setLangSelectorVisibility] = useState(false);
  const [isLangSelectionDismissed, setLangSelectionDissmissed] = useState(false);

  useEffect(() => {
    const value = !!localStorage.getItem(DISSMISS_LANG_KEY);
    setLangSelectionDissmissed(value);
  }, []);

  const dismissLangSelection = () => {
    localStorage.setItem(DISSMISS_LANG_KEY, 'true');
    setLangSelectionDissmissed(true);
  };

  const value: ContextValues = {
    isLangSelectorVisible,
    setLangSelectorVisibility,
    isLangSelectionDismissed,
    dismissLangSelection,
  };

  return <Provider value={value}>{children}</Provider>;
};
