import posthog from 'posthog-js';
import { _info } from 'posthog-js/lib/src/utils/event-utils';

import type { AnalyticsEventHandler } from '@tracking/data';

export const ANONYMIZED_OVERRIDE_VALUE = 'anonymous';
export const overridenProperties = ['$ip', '$device_id', 'distinct_id'].reduce(
  (propsMap, propName) => ({ ...propsMap, [propName]: ANONYMIZED_OVERRIDE_VALUE }),
  {}
);
const propertiesWhitelist = ['$current_url'];

const propertiesBlacklist = [
  ...Object.keys(_info.properties()),
  '$active_feature_flags',
  '$autocapture_disabled_server_side',
  '$device_id',
  '$feature_flag_payloads',
  '$initial_os_version',
  '$sent_at',
  '$session_id',
  '$session_recording_canvas_recording',
  '$session_recording_network_payload_capture',
  '$window_id',
];

export const blacklistedProperties = propertiesBlacklist.filter(
  blackistedProperty => !propertiesWhitelist.includes(blackistedProperty)
);

export const isIdentified = () => posthog.get_property('identified');

export const posthogCapture: AnalyticsEventHandler = (portalEventName, properties) =>
  posthog.capture(portalEventName, {
    ...properties,
    ...(isIdentified() ? {} : overridenProperties),
  });
