import '@curity/identityserver-haapi-web-driver';
import { useRef, useState } from 'react';
import { UAParser } from 'ua-parser-js';

import { UserFacingError } from '../../errors';
import { useAbortController } from './abort-controller';
import { makeAbortable, rejectOnSignalAbort } from './abortable';
import {
  HaapiCaller,
  findActionKind,
  getHaapiCallParams,
  haapiFetchInstance,
  useHaapiCaller,
} from './haapi-flow';
import { pollRequest } from './helpers';
import { OidcClient } from './oidc-client';
import { Action, CurityConfig } from './types';

type PollingResponse = {
  code: string;
  actions: Action[];
  messages?: { text: string }[];
  properties?: {
    status: 'pending' | 'done' | 'failed';
  };
  links?: {
    href: string;
    rel: string;
    title: string;
    type: string;
  }[];
};

type ExternalDeviceAuth = (
  setQrCode: React.Dispatch<React.SetStateAction<string | undefined>>,
  action?: Action
) => Promise<string>;

const POLLING_OPTIONS = {
  // Curity allows polling for 30 seconds, after which it rejects the authentication request,
  // requiring the initiation of a new authentication process.
  // Therefore, 35 seconds seems like a reasonable value.
  retryCount: 35000,
  intervalValue: 2000,
};

const getPollingQrCode = (pollingResponse: PollingResponse) => pollingResponse.links?.[0]?.href;
export const prepareAppLink = (appLink: string, redirectUrl: string) => {
  const parser = new UAParser(window.navigator.userAgent);

  // On Android, the device closes BankID automatically after successful authentication and returns to the browser.
  // On iOS, the BankID app asks you to return to the browser manually.
  // Therefore, we provide a redirect URL explicitly, however only for iOS as Android will open another tab.
  if (parser.getOS().name === 'iOS') {
    return appLink.replace('redirect=null', `redirect=${redirectUrl}`);
  }

  return appLink;
};

type UseBankIdAuthOptions = {
  enabled: boolean;
  curityConfig: CurityConfig;
  openLink: (href: string, target: string) => void;
  redirectUrl: string;
};
export function useBankIdAuth({
  enabled,
  curityConfig,
  openLink,
  redirectUrl,
}: UseBankIdAuthOptions) {
  const cancelAuthProcessActionRef = useRef<Action>();

  const callHaapi = useHaapiCaller(curityConfig);

  const abortController = useAbortController();
  const [oidcClient] = useState(() => (enabled ? new OidcClient(curityConfig) : undefined));

  if (!enabled || !oidcClient) {
    return null;
  }

  const cancelAuthProcess = async () => {
    abortController.abort();
    if (cancelAuthProcessActionRef.current) {
      await callHaapi(...getHaapiCallParams(cancelAuthProcessActionRef.current));
      cancelAuthProcessActionRef.current = undefined;
    }
  };

  const initializeAuth = async (callHaapi: HaapiCaller, kind: 'poll' | 'login') => {
    const url = await oidcClient.getAuthorizationUrl();

    const authInitialization = await callHaapi<{ actions: Action[]; code: string }>(url);

    if (authInitialization?.code === 'generic_error') {
      // Instruct to recreate haapiFetch on next try. It internally establishes a "new session", the way it may recover from errors like "too many attemmpts".
      // Too many attempts error on prod may last for at least 2+ minutes so may be treated as non-recoverable here.
      haapiFetchInstance.reset();
      throw new Error('Failed to initialize authentication process', {
        cause: authInitialization,
      });
    }

    const methodAction = findActionKind(authInitialization.actions, kind);
    return methodAction;
  };

  const updateCancelActionOnPendigAuth = (pollingResponse: PollingResponse) => {
    const cancelAction = findActionKind(pollingResponse.actions, 'cancel');
    cancelAuthProcessActionRef.current = cancelAction;
  };

  const externalDeviceAuth: ExternalDeviceAuth = async setQrCode => {
    abortController.reset();

    const signal = abortController.getSignal();
    const abortableCall = makeAbortable(signal, callHaapi);

    const pollAction: Action | undefined = await initializeAuth(abortableCall, 'poll');

    return rejectOnSignalAbort(
      signal,
      externalDeviceAuthAction(abortableCall, pollAction, setQrCode)
    );
  };

  // todo: make the both auth flows more DRY
  const externalDeviceAuthAction = async (
    haapiCall: HaapiCaller,
    startAction: Action | undefined,
    setQrCode: (qrCode: string) => void
  ) => {
    let pollAction: Action | undefined = startAction;

    while (true) {
      if (!pollAction) {
        throw new Error('No action provided');
      }
      const pollingResponse = await pollRequest(
        () => haapiCall<PollingResponse>(...getHaapiCallParams(pollAction), false),
        response => response.properties?.status !== 'pending',
        response => {
          if (response.properties?.status === 'pending') {
            updateCancelActionOnPendigAuth(response);
            const pollingQrCode = getPollingQrCode(response);
            if (pollingQrCode) {
              setQrCode(pollingQrCode);
            }
          }
        },
        POLLING_OPTIONS
      );

      if (pollingResponse.properties?.status === 'done') {
        const pollingDoneResponse = await haapiCall(
          ...getHaapiCallParams(pollingResponse.actions[0])
        );
        const tokens = await oidcClient.fetchTokens(pollingDoneResponse.properties.code);
        cancelAuthProcessActionRef.current = undefined;
        return tokens.id_token;
      }

      if (pollingResponse.properties?.status === 'failed') {
        handleFailedAction(pollingResponse);
      }

      const retryCall = await haapiCall(...getHaapiCallParams(pollingResponse.actions[0]));
      pollAction = findActionKind(retryCall.actions, 'poll');
    }
  };

  const currentDeviceAuth = async (): Promise<string> => {
    abortController.reset();
    const abortableCall = makeAbortable(abortController.getSignal(), callHaapi);
    const signal = abortController.getSignal();

    const loginAction: Action | undefined = await initializeAuth(abortableCall, 'login');

    return rejectOnSignalAbort(signal, currentDeviceAuthAction(abortableCall, loginAction));
  };

  const currentDeviceAuthAction = async (
    haapiCall: HaapiCaller,
    startAction: Action | undefined
  ) => {
    let loginAction: Action | undefined = startAction;
    while (true) {
      if (!loginAction) {
        throw new Error('No action provided');
      }

      const appLink = loginAction.model?.arguments?.href;
      if (!appLink) {
        throw new Error('No application link provided.');
      }

      openLink(prepareAppLink(appLink, redirectUrl), '_self');
      const pollingResponse = await pollRequest(
        () =>
          haapiCall<PollingResponse>(
            ...getHaapiCallParams(loginAction?.model.continueActions[0]),
            false
          ),
        response => response.properties?.status !== 'pending',
        response => {
          if (response.properties?.status === 'pending') {
            updateCancelActionOnPendigAuth(response);
          }
        },
        POLLING_OPTIONS
      );

      if (pollingResponse.properties?.status === 'done') {
        const pollingDoneResponse = await haapiCall(
          ...getHaapiCallParams(pollingResponse.actions[0])
        );
        const tokens = await oidcClient.fetchTokens(pollingDoneResponse.properties.code);
        cancelAuthProcessActionRef.current = undefined;
        return tokens.id_token;
      }

      if (pollingResponse.properties?.status === 'failed') {
        handleFailedAction(pollingResponse);
      }

      const retryCall = await haapiCall(...getHaapiCallParams(pollingResponse.actions[0]));
      loginAction = findActionKind(retryCall.actions, 'login');
    }
  };
  return { cancelAuthProcess, externalDeviceAuth, currentDeviceAuth };
}

const handleFailedAction = (action: PollingResponse) => {
  const message = String(action.messages?.[0]?.text ?? '');

  // curity may return a error code like error.too-many-attempts or localized (swedish) human readable message
  // this is a heuristic to determine if the message is a human readable
  const maybeHumanReadable =
    message &&
    message.length > 5 &&
    !/^[\w.-]+$/.test(message) &&
    message[0]!.toUpperCase() === message[0] &&
    message.at(-1) === '.';

  if (maybeHumanReadable) {
    throw new UserFacingError(message, { cause: action });
  }

  throw new Error('Authentication failed', { cause: action });
};
