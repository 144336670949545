import { useState } from 'react';

import { FallbackMapError, MapErrorHandler, PrimaryMapError } from './types';

export const useMapFallback = (onError: MapErrorHandler) => {
  const [primaryMapError, setPrimaryMapError] = useState<PrimaryMapError>();
  const [fallbackMapError, setFallbackMapError] = useState<FallbackMapError>();

  const onPrimaryMapError = (e: PrimaryMapError) => {
    setPrimaryMapError(e);
    onError({ primaryMapError: e });
  };

  const onFallbackMapError = (e: FallbackMapError) => {
    setFallbackMapError(e);
    onError({ primaryMapError, fallbackMapError: e });
  };

  return { primaryMapError, fallbackMapError, onPrimaryMapError, onFallbackMapError };
};
