import { useIntl } from 'react-intl';

import type { TranslationKey } from './locale-to-messages';
import { fallbackLocaleMessages } from './locale-to-messages';
import { DEFAULT_LOCALE } from './provider';

export const useFormatMessage = () => {
  const { formatMessage } = useIntl();

  // simplify formatMessage API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const simpleFormatMessage = (id: TranslationKey, values?: Record<string, any>) =>
    formatMessage({ id, defaultMessage: fallbackLocaleMessages.get(DEFAULT_LOCALE)?.[id] }, values);

  return (id: TranslationKey, values?: Record<string, any>, keySuffix?: string): string => {
    if (!keySuffix) {
      return simpleFormatMessage(id, values);
    }
    // It tries to find a translation for a key with the provided suffix.
    // If it can't be found, the translation for the key without the suffix is returned.
    const alteredTranslationKey = `${id}.${keySuffix}` as Parameters<FormatMessageFn>[0];
    const formattedAdditionalKeyMessage = simpleFormatMessage(alteredTranslationKey, values);
    if (formattedAdditionalKeyMessage !== alteredTranslationKey) {
      return formattedAdditionalKeyMessage;
    }
    return simpleFormatMessage(id, values);
  };
};

export type FormatMessageFn = ReturnType<typeof useFormatMessage>;

// TODO: reuse hook for page (replace with useRouter) & widget (replace with useWidgetConfig).
// The locale is passed to the intl provider at the root level, so it should be reused from there anyway.
export const useLocale = () => useIntl().locale;
