import { FC, PropsWithChildren, useState } from 'react';
import { createPortal } from 'react-dom';
import { ControlPosition, useControl } from 'react-map-gl';

type MapControlProps = PropsWithChildren<{ position: ControlPosition }>;
export const MapControl: FC<MapControlProps> = ({ children, position }) => {
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useControl(
    () => ({
      onAdd: () => {
        const node = document.createElement('div');
        setContainer(node);
        return node;
      },
      onRemove: () => {
        setContainer(null);
        container?.parentNode?.removeChild(container);
      },
    }),
    {
      position,
    }
  );

  if (!container) {
    return null;
  }

  return createPortal(children, container);
};
