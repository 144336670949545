import { RecipeVariants } from '@vanilla-extract/recipes';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';

import { textRecipe } from './text.css';

type TextVariants = RecipeVariants<typeof textRecipe>;

type Props = TextVariants & {
  className?: string;
  dataTest?: string;
  children?: ReactNode;
};

export const TextHeader: FC<Props> = ({
  children,
  textStyle,
  className,
  textColor,
  dataTest,
  ...h1Props
}) => (
  <h1
    className={clsx([textRecipe({ textStyle, textColor }), className])}
    data-test={dataTest}
    {...h1Props}
  >
    {children}
  </h1>
);
