import { z } from 'zod';

import { addressSchema } from './addressSchema';
import { operationalHoursSchema } from './operationalHoursSchema';
import { pickupLocationTypeSchema } from './pickupLocationTypeSchema';

export const locationSchema = z.object({
  address: z.lazy(() => addressSchema),
  location_external_id: z
    .string()
    .describe(`location_external_id - external identifier for the location.`)
    .optional(),
  location_type: z.lazy(() => pickupLocationTypeSchema).optional(),
  operational_hours: z.lazy(() => operationalHoursSchema).optional(),
  pickup_instructions: z
    .string()
    .describe(`pickup_instructions - instructions for the customer to pick up the parcel.`)
    .optional(),
});
