import { FC, PropsWithChildren, createContext, useContext, useState } from 'react';

import { useAnalytics, useAuthState } from '@tracking/data';
import { useFormatMessage } from '@tracking/i18n';
import { useNotification } from '@tracking/ui';

import { AuthFormValues, AuthModal } from './auth-modal';

type AuthModalContextValue = {
  open: () => void;
  close: () => void;
};

const AuthModalContext = createContext<AuthModalContextValue | null>(null);
type AuthModalProviderProps = {
  formSubmitHandler: (data: AuthFormValues) => Promise<void>;
};
export const AuthModalProvider: FC<PropsWithChildren<AuthModalProviderProps>> = ({
  children,
  formSubmitHandler,
}) => {
  const formatMessage = useFormatMessage();
  const analytics = useAnalytics();

  const auth = useAuthState();
  const notifications = useNotification();

  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  return (
    <AuthModalContext.Provider value={{ close, open }}>
      {children}
      {isOpen ? (
        <AuthModal
          onSubmit={(data: AuthFormValues) => {
            analytics.capture('verification_confirmation');
            return formSubmitHandler(data);
          }}
          onClose={() => {
            analytics.capture('close_verification_modal');
            close();
          }}
          onCancel={() => {
            analytics.capture('cancel_verification_modal');
            close();
          }}
          onSuccess={() => {
            notifications.showNotification(formatMessage('NOTIFICATION.EMAIL_VERIFIED'));
            auth?.setAuthenticationValue(true);
            close();
          }}
        />
      ) : null}
    </AuthModalContext.Provider>
  );
};

export const useAuthModal = (): AuthModalContextValue => {
  const value = useContext(AuthModalContext);
  if (!value) {
    throw new Error('useAuthModal must be used within a AuthModalProvider');
  }
  return value;
};
