import { FC } from 'react';

import { getPriceFormatter, useFormatMessage, useLocale } from '@tracking/i18n';

import { Text } from '../../atoms';
import * as styles from './styles.css';

type CartSummaryProps = {
  //TODO: add support for delivery costs, discounts, once it's available from the BE side.
  total: number;
  currency: string | undefined;
};

export const CartSummary: FC<CartSummaryProps> = ({ total, currency }) => {
  const formatMessage = useFormatMessage();
  const locale = useLocale();
  const formatPrice = getPriceFormatter(locale, currency);
  return (
    <div className={styles.item}>
      <Text textStyle="regular1620">{formatMessage('CART_SUMMARY.ITEMS_TOTAL')}</Text>
      <Text textStyle="regular1620">{formatPrice(total)}</Text>
    </div>
  );
};
