import { RecipeVariants } from '@vanilla-extract/recipes';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';

import { defaultVariants, textRecipe } from './text.css';

type TextVariants = RecipeVariants<typeof textRecipe>;

type Props = TextVariants & {
  className?: string;
  dataTest?: string;
  children: ReactNode;
} & JSX.IntrinsicElements['span'];

export const Text: FC<Props> = ({
  children,
  textStyle,
  className,
  textColor,
  textAlign,
  dataTest,
  ...spanProps
}) => (
  <span
    data-branding={`text-${textStyle || defaultVariants.textStyle}-${
      textColor || defaultVariants.textColor
    }`}
    className={clsx([textRecipe({ textStyle, textColor, textAlign }), className])}
    data-test={dataTest}
    {...spanProps}
  >
    {children}
  </span>
);
