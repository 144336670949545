import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { Notification } from '../molecules';

type NotificationContextType = {
  showNotification: (text: string) => void;
};

const notificationContext = createContext<NotificationContextType | null>(null);

export const NotificationProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [notificationText, setNotificationText] = useState('');
  const [isNotificationVisible, setNotificationVisibility] = useState(false);

  const { Provider } = notificationContext;

  useEffect(() => {
    if (!isNotificationVisible) {
      return;
    }
    const timer = setTimeout(() => setNotificationVisibility(false), 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [isNotificationVisible]);

  const showNotification = (text: string) => {
    setNotificationText(text);
    setNotificationVisibility(true);
  };

  return (
    <Provider value={{ showNotification }}>
      {isNotificationVisible && <Notification text={notificationText} />}
      {children}
    </Provider>
  );
};

export const useNotification = () => {
  const value = useContext(notificationContext);

  if (!value) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return value;
};
