export class AbortError extends Error {
  constructor() {
    super('Aborted');
    this.name = 'AbortError';

    // TODO:
    // this may be neccesary for older browsers or low ES target
    // https://github.com/microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    //
    // Investigate if we can drop this line, as babel-plugin-transform-builtin-extend may do the job:
    // https://github.com/loganfsmyth/babel-plugin-transform-builtin-extend
    Object.setPrototypeOf(this, AbortError.prototype);
  }
}

export class UserFacingError extends Error {
  constructor(errorMessage: string, options?: ErrorOptions) {
    super(errorMessage, options);
    this.name = 'UserFacingError';
    Object.setPrototypeOf(this, UserFacingError.prototype);
  }
}
