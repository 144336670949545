import { z } from 'zod';

import { alertSchema } from './alertSchema';
import { deliveryTypeSchema } from './deliveryTypeSchema';
import { directionTypeSchema } from './directionTypeSchema';
import { locationSchema } from './locationSchema';
import { progressItemSchema } from './progressItemSchema';

export const publicParcelSchema = z.object({
  alerts: z.array(z.lazy(() => alertSchema)).optional(),
  carrier: z.string().optional(),
  carrier_product_id: z.string().optional(),
  carrier_product_ref: z.string().optional(),
  delivery_type: z.lazy(() => deliveryTypeSchema).optional(),
  direction_type: z.lazy(() => directionTypeSchema).optional(),
  display_carrier_name: z.string().optional(),
  index: z.number().optional(),
  location: z.lazy(() => locationSchema).optional(),
  progress: z.array(z.lazy(() => progressItemSchema)).optional(),
  tracking_link: z.string().optional(),
  tracking_number: z.string(),
});
