import { DeliveryType, ProgressItem, Step } from '@tracking/data';
import { FormatMessageFn } from '@tracking/i18n';

import { getTimestampDate } from './get-timestamp-date';
import { getTimestampMessage } from './get-timestamp-message';

const stepToTimestampType: Partial<Record<Step, keyof TimestampsType>> = {
  STEP_CONFIRMED_BY_MERCHANT: 'ordered',
  STEP_SUBMITTED_TO_CARRIER: 'shipped',
  STEP_DELIVERED_TO_PICKUP_POINT: 'deliveredToPickupPoint',
  STEP_DELIVERED_TO_CONSUMER: 'delivered',
  STEP_DELIVERED_TO_MERCHANT: 'delivered',
  STEP_TERMINATED: 'terminated',
  STEP_SENT_BACK: 'sentBack',
  STEP_RETURN_REQUESTED: 'ordered',
};

export type TimestampsType = {
  ordered: TimestampData;
  shipped: TimestampData;
  deliveredToPickupPoint: TimestampData;
  delivered: TimestampData;
  terminated: TimestampData;
  sentBack: TimestampData;
};

export type TimestampData = {
  formattedTime: string;
  message: string;
  isEstimatedTime: boolean | null;
};

export const getTimestamps = (
  statuses: ProgressItem[],
  deliveryType: DeliveryType | undefined,
  locale = 'en-US',
  formatMessage: FormatMessageFn
) => {
  const timestamp: TimestampsType = {
    ordered: {
      formattedTime: '',
      message: '',
      isEstimatedTime: null,
    },
    shipped: {
      formattedTime: '',
      message: '',
      isEstimatedTime: null,
    },
    deliveredToPickupPoint: {
      formattedTime: '',
      message: '',
      isEstimatedTime: null,
    },
    delivered: {
      formattedTime: '',
      message: '',
      isEstimatedTime: null,
    },
    terminated: {
      formattedTime: '',
      message: '',
      isEstimatedTime: null,
    },
    sentBack: {
      formattedTime: '',
      message: '',
      isEstimatedTime: null,
    },
  };

  const isReturnProcess = statuses.some(status => status.step === 'STEP_RETURN_REQUESTED');

  statuses.forEach(status => {
    const type = stepToTimestampType[status.step];
    if (!type) {
      return;
    }
    timestamp[type].formattedTime = getTimestampDate(locale, status, formatMessage);
    timestamp[type].isEstimatedTime = Boolean(
      !status.time && (status.estimated_time?.end || status.estimated_time?.start)
    );
    timestamp[type].message = getTimestampMessage(status, deliveryType, isReturnProcess);
  });

  return timestamp;
};
