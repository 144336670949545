import React, { FC, ReactNode } from 'react';

import { useFormatMessage } from '@tracking/i18n';

import { Image, Link, Text } from '../../atoms';
import arrowheadLeftwards from './arrowhead-leftwards-icon.svg';
import * as styles from './go-back-button.css';

type Props = {
  href?: string;
  dataTest?: string;
  onClick?: () => void;
  children: ReactNode;
};

export const GoBackButton: FC<Props> = ({ dataTest, href, onClick, children }) => {
  const formatMessage = useFormatMessage();
  const onKeyDownEventHandler = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter') {
      onClick?.();
    }
  };

  return (
    <Link
      onClick={onClick}
      href={href}
      className={styles.link}
      tabIndex={0}
      onKeyDown={onKeyDownEventHandler}
    >
      <Image
        src={arrowheadLeftwards}
        alt={formatMessage('SPECIAL.IMG_ALT.GO_BACK_ICON')}
        className={styles.icon}
      />
      <div className={styles.linkTextWrapper} data-test={dataTest}>
        <Text textColor="black" textStyle="regular1620" className={styles.linkText}>
          {children}
        </Text>
      </div>
    </Link>
  );
};
