import { captureException, withScope } from '@sentry/nextjs';

type ErrorData = {
  data: {
    error: string;
    trace_id: string;
  };
};

export class PageTrackingError extends Error {
  constructor(
    message?: string,
    public response?: ErrorData
  ) {
    super(message);
    Object.setPrototypeOf(this, PageTrackingError.prototype);
  }
}

const DEPLOY_TARGET = process.env.NEXT_PUBLIC_ENV;

export const logError = (error: PageTrackingError) => {
  switch (DEPLOY_TARGET) {
    case 'production':
      withScope(scope => {
        scope.setExtra('traceId', error?.response?.data?.trace_id ?? '');
        captureException(error);
      });
      break;

    case 'stage':
    case 'development':
    case 'local':
      console.error(error);
      break;

    default:
      throw new Error(`Unreachable case: ${DEPLOY_TARGET}`);
  }
};
