import clsx from 'clsx';

import { styles } from './pin.css';

type PinProps = {
  offsetVertically?: boolean;
  src: string;
  alt?: string;
  dataTest?: string;
  onLoad?: React.ReactEventHandler<HTMLImageElement>;
};

const SVG_TYPE = 'image/svg+xml';
export const Pin = ({ offsetVertically, src, alt, dataTest, onLoad }: PinProps): JSX.Element => {
  // HACK:
  // SVG filters on Safari are rasterized in low resolution when rendering with `img` tag, or background-image.
  // When embedding via an object tag it looks properly like it's rendered inline.
  // TODO: get rid of this and fix the SVG files instead (see: TRACK-675)
  if (src.endsWith('.svg') || src.startsWith(`data:${SVG_TYPE}`)) {
    return (
      <object
        data={src}
        type={SVG_TYPE}
        data-test={dataTest}
        className={clsx(styles.pin, offsetVertically && styles.offsetPin)}
      >
        {
          // Image is a fallback, in case the object failed, but it's loaded in both cases
          // so it's "safe" to use onLoad to get the image info.
          // Dirty workaround, will be resolved in TRACK-675
        }
        <img src={src} alt={alt} onLoad={onLoad} />
      </object>
    );
  }

  return <img src={src} alt={alt} data-test={dataTest} className={styles.pin} onLoad={onLoad} />;
};
