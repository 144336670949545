import { useFormatMessage } from '@tracking/i18n';

import * as styles from './privacy-policy-link.css';
import { Text } from './text';

type PrivacyPolicyLinkProps = JSX.IntrinsicElements['a'];

export const PrivacyPolicyLink = ({ onClick }: PrivacyPolicyLinkProps) => {
  const formatMessage = useFormatMessage();

  return (
    <a
      onClick={onClick}
      className={styles.policy}
      rel="noreferrer"
      href="https://www.ingrid.com/privacy-policy"
      target="_blank"
    >
      <Text textColor="darkGrey" textStyle="light1216">
        {formatMessage('FOOTER.PRIVACY_POLICY')}
      </Text>
    </a>
  );
};
