import * as maptilersdk from '@maptiler/sdk';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { AnalyticsEventHandler, Coordinates } from '@tracking/data';

import { initializeMap } from '../../initialize-map';
import * as mapStyles from '../../map.css';
import { PrimaryMapError } from '../../types';
import { drawMapCircle } from '../draw-map-circle';
import { MapPopup } from '../map-popup';

const POPUP_MARGIN = 8;

type MaptilerPublicMapProps = {
  analyticsEventHandler: AnalyticsEventHandler;
  coordinates: Coordinates | undefined;
  hasAuthenticationEmail: boolean;
  setAuthModalVisibility: Dispatch<SetStateAction<boolean>>;
  maptilerApiKey: string;
  onError: (e: PrimaryMapError) => void;
};
export const MaptilerPublicMap = ({
  analyticsEventHandler,
  coordinates,
  hasAuthenticationEmail,
  setAuthModalVisibility,
  maptilerApiKey,
  onError,
}: MaptilerPublicMapProps) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<maptilersdk.Map | null>(null);
  maptilersdk.config.apiKey = maptilerApiKey;
  maptilersdk.config.session = false;

  const [popupCoordinates, setPopupCoordinates] = useState<{ x: number; y: number } | null>(null);

  const handleOnHover = (
    event: maptilersdk.MapMouseEvent & { features?: maptilersdk.MapGeoJSONFeature[] | undefined }
  ) => {
    if (!hasAuthenticationEmail) {
      return null;
    }
    const {
      point: { y, x },
    } = event;
    setPopupCoordinates({ x: x + POPUP_MARGIN, y: y + POPUP_MARGIN });
  };

  const handleOnClick = () => {
    if (!hasAuthenticationEmail) {
      return null;
    }
    analyticsEventHandler('open_verification_modal_via_map_click');
    setAuthModalVisibility(true);
  };

  useEffect(() => {
    if (map.current || !coordinates?.lat || !coordinates.lng) {
      return;
    }

    map.current = initializeMap({
      container: mapContainer.current as HTMLElement,
      center: [coordinates?.lng, coordinates?.lat],
      zoom: 11,
    });

    map.current.on('error', e => onError(e.error));

    map.current.on('load', () => {
      map.current?.addSource('radius-circle', {
        type: 'geojson',
        data: drawMapCircle({ ...coordinates }),
      });

      map.current?.addLayer({
        id: 'radius-circle',
        type: 'fill',
        source: 'radius-circle',
        layout: {},
        paint: {
          'fill-color': '#767676',
          'fill-opacity': 0.5,
        },
      });

      map.current?.on('mousemove', 'radius-circle', event => {
        handleOnHover(event);
      });

      map.current?.on('mouseleave', 'radius-circle', () => {
        setPopupCoordinates(null);
      });
      map.current?.on('click', handleOnClick);
    });
  }, [coordinates]);

  return (
    <div ref={mapContainer} className={mapStyles.mapContainer}>
      {popupCoordinates && <MapPopup coordinates={popupCoordinates} />}
    </div>
  );
};
