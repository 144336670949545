import { Text } from '../atoms';
import { sprinkles } from '../sprinkles.css';
import { operationalHour } from './opening-hours.css';

type Props = {
  operationalHours: string[][];
  headerText: string;
  dataTest: string;
};

export const OpeningHours = ({ operationalHours, headerText, dataTest }: Props) => {
  if (!operationalHours.length) {
    return null;
  }
  return (
    <div data-test={dataTest} className={sprinkles({ marginTop: 16 })}>
      <Text data-branding="block-label">{headerText}</Text>
      {operationalHours.map(([day, time]) => (
        <div className={operationalHour} key={day}>
          <Text data-branding="block-text" textColor="darkGrey">
            {day}
          </Text>
          <Text data-branding="block-text" textColor="darkGrey">
            {time}
          </Text>
        </div>
      ))}
    </div>
  );
};
