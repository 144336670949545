import clsx from 'clsx';
import type { AnchorHTMLAttributes, DetailedHTMLProps, FC } from 'react';

import * as styles from './link.css';

type Props = DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

export const Link: FC<Props> = ({ children, className, ...rest }) => (
  <a className={clsx([styles.link, className])} {...rest}>
    {children}
  </a>
);
