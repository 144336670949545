export const getProgressBarValue = (currentStatus?: string) => {
  switch (currentStatus) {
    case 'STEP_UNKNOWN':
    case 'STEP_CONFIRMED_BY_MERCHANT':
      return 10;
    case 'STEP_AWAITING_SUPPLIER':
      return 15;
    case 'STEP_PREPARED_BY_MERCHANT':
      return 20;
    case 'STEP_LINEHAUL_TO_CARRIER':
      return 25;
    case 'STEP_SUBMITTED_TO_CARRIER':
      return 30;
    case 'STEP_ON_ROUTE':
      return 50;
    case 'STEP_DELIVERED_TO_PICKUP_POINT':
      return 80;
    case 'STEP_DELIVERED_TO_CONSUMER':
    case 'STEP_DELIVERED_TO_MERCHANT':
    case 'STEP_TERMINATED':
    case 'STEP_SENT_BACK':
      return 100;
    default:
      return 0;
  }
};
