import type { FC } from 'react';

import { TextHeader } from '../atoms/text-header';

type Props = { className?: string; text: string; dataTest?: string };

export const Header: FC<Props> = ({ className, text, dataTest }) => {
  return (
    <header className={className} data-test={dataTest}>
      <TextHeader
        textStyle="light2028"
        textColor="black"
        data-branding="eta-header-text"
        dataTest="eta-text"
      >
        {text}
      </TextHeader>
    </header>
  );
};
