import { ProgressItem } from '@tracking/data';
import { TranslationKey, useFormatMessage } from '@tracking/i18n';

export const getTimestampMessage = (
  status: ProgressItem,
  deliveryType?: string,
  isReturn?: boolean
) => {
  const formatMessage = useFormatMessage();

  if (status.time) {
    switch (status.step) {
      case 'STEP_CONFIRMED_BY_MERCHANT':
        return formatMessage('HEADER.TIMESTAMP.ORDERED');
      case 'STEP_SUBMITTED_TO_CARRIER':
        if (isReturn) {
          return formatMessage('HEADER.TIMESTAMP.STEP_SUBMITTED_TO_MERCHANT_RETURN');
        }
        return formatMessage('HEADER.TIMESTAMP.SHIPPED');
      case 'STEP_DELIVERED_TO_CONSUMER':
        if (deliveryType === 'PICKUP' || deliveryType === 'INSTORE') {
          return formatMessage('HEADER.TIMESTAMP.PICKED_UP_BY_CUSTOMER');
        }
        return formatMessage('HEADER.TIMESTAMP.DELIVERED');
      case 'STEP_DELIVERED_TO_PICKUP_POINT':
        return formatMessage('HEADER.TIMESTAMP.DELIVERED_TO_PICKUP_POINT');
      case 'STEP_TERMINATED':
        return formatMessage('HEADER.TIMESTAMP.STEP_TERMINATED');
      case 'STEP_SENT_BACK':
        return formatMessage('HEADER.TIMESTAMP.STEP_SENT_BACK');
      case 'STEP_RETURN_REQUESTED':
        return formatMessage('HEADER.TIMESTAMP.STEP_RETURN_REQUESTED');
      case 'STEP_DELIVERED_TO_MERCHANT':
        return formatMessage('HEADER.TIMESTAMP.RETURNED');
      default:
        return '';
    }
  }
  if (status.estimated_time?.start || status.estimated_time?.end) {
    if (status.step === 'STEP_SUBMITTED_TO_CARRIER' && isReturn) {
      return formatMessage(
        `HEADER.TIMESTAMP_ESTIMATED.STEP_SUBMITTED_TO_CARRIER_RETURN` as TranslationKey
      );
    }
    return formatMessage(`HEADER.TIMESTAMP_ESTIMATED.${status.step}` as TranslationKey);
  }
  return '';
};
