import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';
import { MapProps } from 'react-map-gl';

type UseMapResizeUpdateOptions = { debounceValue?: number };
export const useMapResizeUpdate = (
  { debounceValue }: UseMapResizeUpdateOptions = { debounceValue: 500 }
) => {
  const mapResizeObserver = useRef<ResizeObserver>();

  const setupResizeObserver: MapProps['onLoad'] = e => {
    const debouncedMapResize = debounce(() => {
      e.target.resize();
    }, debounceValue);

    const mapContainer = e.target.getContainer();

    mapResizeObserver.current = new ResizeObserver(() => {
      debouncedMapResize();
    });

    mapResizeObserver.current?.observe(mapContainer);
  };

  useEffect(() => {
    return () => {
      mapResizeObserver.current?.disconnect();
    };
  }, []);

  return setupResizeObserver;
};
