import type { Locale } from 'date-fns';
import csCZ from 'date-fns/locale/cs';
import daDK from 'date-fns/locale/da';
import deDE from 'date-fns/locale/de';
import deAT from 'date-fns/locale/de-AT';
import elGR from 'date-fns/locale/el';
import enAU from 'date-fns/locale/en-AU';
import enCA from 'date-fns/locale/en-CA';
import enGB from 'date-fns/locale/en-GB';
import enIE from 'date-fns/locale/en-IE';
import enUS from 'date-fns/locale/en-US';
import esES from 'date-fns/locale/es';
import etEE from 'date-fns/locale/et';
import fiFI from 'date-fns/locale/fi';
import frFR from 'date-fns/locale/fr';
import hrHR from 'date-fns/locale/hr';
import isIS from 'date-fns/locale/is';
import itIT from 'date-fns/locale/it';
import jaJP from 'date-fns/locale/ja';
import koKR from 'date-fns/locale/ko';
import ltLT from 'date-fns/locale/lt';
import lvLV from 'date-fns/locale/lv';
import nbNO from 'date-fns/locale/nb';
import nlNL from 'date-fns/locale/nl';
import nlBE from 'date-fns/locale/nl-BE';
import nnNO from 'date-fns/locale/nn';
import plPL from 'date-fns/locale/pl';
import ptPT from 'date-fns/locale/pt';
import ruRU from 'date-fns/locale/ru';
import skSK from 'date-fns/locale/sk';
import slSL from 'date-fns/locale/sl';
import svSV from 'date-fns/locale/sv';
import zhCN from 'date-fns/locale/zh-CN';

export const dateFnsLocales = new Map<string, Locale>([
  ['cs-CZ', csCZ],
  ['da-DK', daDK],
  ['de-DE', deDE],
  ['de-AT', deAT],
  ['de-CH', deDE], //TODO: check if support for the `deCH` has been added - https://github.com/date-fns/date-fns/issues/2803
  ['et-EE', etEE],
  ['el-GR', elGR],
  ['es-ES', esES],
  ['en-AU', enAU],
  ['en-CA', enCA],
  ['en-GB', enGB],
  ['en-IE', enIE],
  ['en-US', enUS],
  ['fr-FR', frFR],
  ['fr-BE', frFR],
  ['hr-HR', hrHR],
  ['is-IS', isIS],
  ['it-IT', itIT],
  ['ja-JP', jaJP],
  ['ko-KR', koKR],
  ['lt-LT', ltLT],
  ['lv-LV', lvLV],
  ['nl-NL', nlNL],
  ['nb-NO', nbNO],
  ['no-NO', nbNO],
  ['nn-NO', nnNO],
  ['pl-PL', plPL],
  ['pt-PT', ptPT],
  ['ru-RU', ruRU],
  ['sk-SK', skSK],
  ['sl-SI', slSL],
  ['sv-SE', svSV],
  ['sv-FI', svSV],
  ['fi-FI', fiFI],
  ['fi-SE', fiFI],
  ['nl-BE', nlBE],
  ['zh-CN', zhCN],
]);
