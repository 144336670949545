import { PropsWithChildren, createContext, useContext } from 'react';

const EnvConfigContext = createContext<WidgetEnv | null>(null);
type WidgetEnv = {
  MAPTILER_API_KEY: string;
  MAPBOX_API_KEY: string;
  CDN_URL: string;
};
export const EnvConfigProvider = ({
  config,
  children,
}: PropsWithChildren<{ config: WidgetEnv }>) => (
  <EnvConfigContext.Provider value={config}>{children}</EnvConfigContext.Provider>
);

export const useEnv = (): WidgetEnv => {
  const value = useContext(EnvConfigContext);
  if (!value) {
    throw new Error('EnvConfigProvider value is not set');
  }
  return value;
};
