import { debounce } from 'lodash';
import { FC, useMemo, useState } from 'react';

import {
  getCoordinates,
  getDeliveryType,
  getIsHomeDelivery,
  getLogoName,
  getParcelCurrentStatus,
  getParcelDirectionType,
  getShowDetails,
  getTrackingLink,
  useAnalytics,
  useAuthState,
  useLogger,
  useTrackingData,
} from '@tracking/data';
import { type MapErrorHandler, PrivateMap, PublicMapUI, hasCorrectCoordinates } from '@tracking/ui';

import { useEnv } from '../../env-config';
import { useAuthModal } from '../auth';
import * as styles from './map.css';

export const Map: FC<{ parcelIndex: number }> = ({ parcelIndex }) => {
  const data = useTrackingData(data => ({
    trackingLink: getTrackingLink(data, parcelIndex),
    isHomeDelivery: getIsHomeDelivery(data, parcelIndex),
    currentStatus: getParcelCurrentStatus(data, parcelIndex),
    coordinates: getCoordinates(data, parcelIndex),
    hasPrivateView: getShowDetails(data),
    shippingMethod: getLogoName(data, parcelIndex),
    deliveryType: getDeliveryType(data, parcelIndex),
    directionType: getParcelDirectionType(data, parcelIndex),
  }));

  const modal = useAuthModal();
  const logger = useLogger();

  // Prevent sending two entries when there is an error in both primary and fallback maps
  const debouncedLogError = useMemo(() => debounce(logger.error, 5000), [logger.error]);

  const authContext = useAuthState();
  const analytics = useAnalytics();
  const [mapUnavailable, setMapUnavailable] = useState(false);

  const showPublicMap = !authContext?.isAuthenticated && data.isHomeDelivery;

  const handleMapError: MapErrorHandler = errors => {
    if (errors.primaryMapError && errors.fallbackMapError) {
      setMapUnavailable(true);
    }
    debouncedLogError(errors);
  };

  if (!hasCorrectCoordinates(data.coordinates) || mapUnavailable) {
    return <div className={styles.noMap} />;
  }

  const env = useEnv();
  return (
    <div className={styles.container} data-test="map">
      <div className={styles.map} data-test={showPublicMap ? 'public-map' : undefined}>
        {showPublicMap ? (
          <PublicMapUI
            setAuthModalVisibility={modal.open}
            onError={handleMapError}
            hasAuthenticationEmail={data.hasPrivateView}
            analyticsEventHandler={analytics.capture}
            coordinates={data.coordinates}
            mapboxApiKey={env.MAPBOX_API_KEY}
            maptilerApiKey={env.MAPTILER_API_KEY}
          />
        ) : (
          <PrivateMap
            analyticsEventHandler={analytics.capture}
            coordinates={data.coordinates}
            mapboxApiKey={env.MAPBOX_API_KEY}
            maptilerApiKey={env.MAPTILER_API_KEY}
            deliveryType={data.deliveryType}
            directionType={data.directionType}
            shippingMethod={data.shippingMethod}
            onError={handleMapError}
          />
        )}
      </div>
    </div>
  );
};
