import { RecipeVariants } from '@vanilla-extract/recipes';
import clsx from 'clsx';
import type { ComponentPropsWithoutRef, FC } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

import {
  buttonRecipe,
  buttonStyle,
  childrenContainer,
  defaultVariants,
  loaderContainer,
} from './button.css';

type ButtonVariants = RecipeVariants<typeof buttonRecipe>;

type Props = ComponentPropsWithoutRef<'button'> &
  ButtonVariants & {
    isLoading?: boolean;
    dataTest?: string;
  };

export const Button: FC<Props> = ({
  children,
  dataTest,
  className,
  buttonType,
  border,
  isLoading,
  disabled,
  ...rest
}) => (
  <button
    disabled={disabled || isLoading}
    data-test={dataTest}
    data-branding={`button-${buttonType || defaultVariants.buttonType}-${
      border || defaultVariants.border
    }`}
    className={clsx([buttonStyle, className, buttonRecipe({ buttonType, border })])}
    {...rest}
  >
    <div className={childrenContainer({ hidden: isLoading })}>{children}</div>
    {isLoading && (
      <div className={loaderContainer}>
        <PulseLoader color="white" size="6px" speedMultiplier={0.8} />
      </div>
    )}
  </button>
);
