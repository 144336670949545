import { useFormatMessage } from '@tracking/i18n';

import { Button, Image, Stack, Text } from '../../atoms';
import bankIdLogo from './bank-id-logo.svg';

type IdentifyButtonProps = {
  loading: boolean;
  onClick: () => void;
  alreadyAuthenticated: boolean;
};
export const IdentifyButton = ({ loading, onClick, alreadyAuthenticated }: IdentifyButtonProps) => {
  const formatMessage = useFormatMessage();
  return (
    <Stack space={12} paddingTop={16} data-branding="identify-button">
      <Button buttonType="primary" border="solidBlack" onClick={onClick} isLoading={loading}>
        {!alreadyAuthenticated && (
          <Image
            width={20}
            height={20}
            src={bankIdLogo}
            alt={formatMessage('IDENTIFY.BANK_ID_LOGO')}
            data-branding="bank-id-logo"
          />
        )}
        <Text textColor="white" textStyle="regular1420">
          {formatMessage(
            alreadyAuthenticated
              ? 'IDENTIFY.INITIALIZE.ALREADY_AUTHENTICATED'
              : 'IDENTIFY.INITIALIZE.START_BUTTON'
          )}
        </Text>
      </Button>
      <Text textStyle="light1420" textAlign="center" data-branding="text-secondary">
        {formatMessage('IDENTIFY.INITIALIZE.SECONDARY_TEXT')}
      </Text>
    </Stack>
  );
};
