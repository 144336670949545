import { z } from 'zod';

export const privateOrderCartPricesShippingComponentSchema = z.object({
  discount: z
    .number()
    .describe(`Discount represents the discount of the shipping component in cents.`)
    .optional(),
  name: z
    .string()
    .describe(`Name represents the name of the shipping component. Translated to the given locale.`)
    .optional(),
  price: z
    .number()
    .describe(`Price represents the price of the shipping component in cents.`)
    .optional(),
});
