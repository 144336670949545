import { z } from 'zod';

import { boolOptionSchema } from './boolOptionSchema';
import { int32OptionSchema } from './int32OptionSchema';

export const sectionsConfigCartItemsSchema = z.object({
  default_expanded: z.lazy(() => boolOptionSchema).optional(),
  disabled: z.lazy(() => boolOptionSchema).optional(),
  expand_toggle_disabled: z.lazy(() => boolOptionSchema).optional(),
  order: z.lazy(() => int32OptionSchema).optional(),
  show_images: z.lazy(() => boolOptionSchema).optional(),
  show_prices: z.lazy(() => boolOptionSchema).optional(),
  show_summary: z.lazy(() => boolOptionSchema).optional(),
});
