import clsx from 'clsx';
import { FC, ReactNode } from 'react';

import { Sprinkles, sprinkles } from '../sprinkles.css';
import { contentBlock } from './content-block.css';

type Props = {
  marginTop?: Sprinkles['marginTop'];
  paddingRight?: Sprinkles['paddingRight'];
  className?: string;
  children: ReactNode;
  dataTest?: string;
};

export const ContentBlock: FC<Props> = ({
  children,
  className,
  dataTest,
  paddingRight,
  marginTop,
  ...restProps
}) => {
  return (
    <div
      className={clsx([contentBlock, sprinkles({ paddingRight, marginTop }), className])}
      data-test={dataTest}
      {...restProps}
    >
      {children}
    </div>
  );
};
