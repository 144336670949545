export const getTimeFormat = (locale: string) => {
  switch (locale) {
    case 'en-US':
    case 'en-GB':
    case 'en-CA':
    case 'jaJP':
      return 'h:mm a';
    default:
      return 'HH:mm';
  }
};
