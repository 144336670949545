import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import type { CookieAttributes } from 'js-cookie';
import { useEffect, useState } from 'react';

import { CookieContext, useCookie } from '../contexts';
import { getBrowserFingerprint } from './get-browser-fingerprint';

const getEncryptedCookie = async <T>(
  getFn: CookieContext['get'],
  key: string,
  encryptionKey: string
): Promise<T | undefined> => {
  try {
    const cookieValue = await getFn(key);
    if (!cookieValue) {
      return;
    }
    const storedEncryptedBytes = CryptoAES.decrypt(cookieValue, encryptionKey);
    const decryptedValue = storedEncryptedBytes.toString(CryptoENC);
    const value = JSON.parse(decryptedValue) as T;
    return value;
  } catch (error) {
    return;
  }
};

const setEncryptedCookie = <T>(
  setFn: CookieContext['set'],
  name: string,
  value: T,
  encryptionKey: string,
  cookieAttributes?: CookieAttributes
) => {
  const stringifiedValue = JSON.stringify(value);
  const encryptedValue = CryptoAES.encrypt(stringifiedValue, encryptionKey).toString();
  setFn(name, encryptedValue, cookieAttributes);
};

export const useEncryptedCookieState = <T>(
  cookieName: string,
  options: {
    secretKey?: string;
    cookieAttributes?: CookieAttributes;
    defaultValue?: T;
  } = {}
) => {
  const cookies = useCookie();

  const secretKey = options?.secretKey || getBrowserFingerprint();
  const cookieAttributes = options?.cookieAttributes;

  const [value, setValue] = useState(options.defaultValue);

  const getSavedValue = async () => {
    const savedValue = await getEncryptedCookie<T>(cookies.get, cookieName, secretKey);
    setValue(savedValue);
  };

  useEffect(() => {
    getSavedValue();
  }, [cookieName, cookies.get, secretKey]);

  type SetterFn = (prevValue: T | undefined) => T | undefined;
  const setter = (newValue: T | SetterFn) => {
    setValue(currentValue => {
      const updatedValue =
        typeof newValue === 'function' ? (newValue as SetterFn)(currentValue) : newValue;
      setEncryptedCookie(cookies.set, cookieName, updatedValue, secretKey, cookieAttributes);
      return updatedValue;
    });
  };

  return [value, setter] as const;
};
