import { useLogoMapContext, useLogoMapEntry } from '@ingrid/react-shipping-logo';

import { DeliveryType, DirectionType } from '@tracking/data';

export const useMapPinLogo = (
  shippingMethod?: string,
  deliveryType?: DeliveryType,
  directionType?: DirectionType
) => {
  if (shippingMethod === undefined) {
    return;
  }
  const getDerrivedShippingMethod = (
    shippingMethod: string,
    deliveryType?: DeliveryType,
    directionType?: DirectionType
  ) => {
    if (directionType === 'RETURN') {
      return shippingMethod;
    }
    switch (deliveryType) {
      case 'DELIVERY':
      case 'MAILBOX':
        return 'home-delivery';
      case 'PICKUP':
      case 'INSTORE':
      case 'INWAREHOUSE':
        return shippingMethod;
      case 'UNKNOWN':
      default:
        return '';
    }
  };
  const logoMapContext = useLogoMapContext();

  const logoMapEntry = useLogoMapEntry({
    method: getDerrivedShippingMethod(shippingMethod, deliveryType, directionType),
  });

  if (!logoMapEntry) {
    return;
  }

  return {
    ...logoMapEntry,
    pathPin: `${logoMapContext.host}${logoMapEntry.pathPin}`,
  };
};
