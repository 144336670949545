export type ConsentType =
  | 'cookie_cat_functional'
  | 'cookie_cat_marketing'
  | 'cookie_cat_necessary'
  | 'cookie_cat_statistic'
  | 'cookie_cat_unclassified';

export type CookieConsentGivenEvent = CustomEvent<{
  consents: Record<ConsentType, boolean>;
}>;

export const getConsentGiven = (consentType: ConsentType) =>
  window?.CookieInformation?.getConsentGivenFor(consentType);
