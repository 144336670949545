type ThumbsDownIconProps = { className?: string };

export const ThumbsDownIcon = ({ className }: ThumbsDownIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_7437_27088)">
      <path
        d="M20.5 14L17.5 14L14.2276 19.6099C13.1989 21.3733 10.5 20.6436 10.5 18.6022L10.5 14L5.68806 14C4.34834 14 3.38744 12.7085 3.77241 11.4253L5.57241 5.42531C5.8262 4.57934 6.60484 4 7.48806 4L20.5 4"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7437_27088">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
