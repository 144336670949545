import { z } from 'zod';

import { sectionsConfigSchema } from './sectionsConfigSchema';

export const widgetBrandingSchema = z.object({
  css_inline: z.string(),
  sections_config: z.lazy(() => sectionsConfigSchema).optional(),
  sender_name: z
    .string()
    .describe(`Used to overwrite the default value which is name of the site.`)
    .optional(),
  translation_key: z
    .string()
    .describe(
      `TranslationKey is a custom translation key. If not defined - default translation key will be used.`
    )
    .optional(),
});
