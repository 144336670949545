import { sanitizeUrl } from '@braintree/sanitize-url';

import type {
  DeliveryType,
  PageBranding,
  Parcel,
  ProgressItem,
  WidgetBranding,
} from '../../../generated/types';
import { BrandingData } from '../branding';
import { getSenderName } from './order-selectors';
import { FrontendAlert, GeneralGetTrackingDataResponse } from './types';

// TODO: find a better place for this
export const formatAddressLines = (adddressLines: string[] | undefined) => {
  if (!adddressLines || adddressLines.length === 0) {
    return '';
  }

  return adddressLines
    .flatMap(line => {
      const trimmed = line.trim();
      return trimmed.length && trimmed != '-' ? [trimmed] : [];
    })
    .join(', ');
};

export const getParcel = (data: GeneralGetTrackingDataResponse, parcelIndex: number) => {
  const parcels = data.order?.parcels || [];
  return parcels[parcelIndex];
};

export const getParcelTrackingNumber = (
  data: GeneralGetTrackingDataResponse,
  parcelIndex: number
) => {
  const parcel = getParcel(data, parcelIndex);
  return parcel?.tracking_number;
};

export const getParcelStatuses = (
  data: GeneralGetTrackingDataResponse,
  parcelIndex: number
): ProgressItem[] => {
  const parcel = getParcel(data, parcelIndex);
  return parcel?.progress ?? [];
};

// this selector relies on BE providing statuses sorted chronologically and descending, i.e. picked up / delivered status being first element of the list
export const getParcelCurrentStatus = (
  data: GeneralGetTrackingDataResponse,
  parcelIndex: number
): ProgressItem | undefined => {
  const progress = getParcelStatuses(data, parcelIndex);
  return getCurrentStatus(progress);
};

export const getCarrier = (data: GeneralGetTrackingDataResponse, parcelIndex: number) => {
  const parcel = getParcel(data, parcelIndex);
  return parcel?.carrier;
};

export const getCarrierName = (data: GeneralGetTrackingDataResponse, parcelIndex: number) => {
  const parcel = getParcel(data, parcelIndex);
  return parcel?.display_carrier_name;
};

export const getDeliveryType = (data: GeneralGetTrackingDataResponse, parcelIndex: number) => {
  const parcel = getParcel(data, parcelIndex);
  return parcel?.delivery_type;
};

export const getLocationType = (data: GeneralGetTrackingDataResponse, parcelIndex: number) => {
  const parcel = getParcel(data, parcelIndex);
  return parcel?.location?.location_type;
};

export const getLocation = (data: GeneralGetTrackingDataResponse, parcelIndex: number) => {
  const parcel = getParcel(data, parcelIndex);
  return parcel?.location;
};

export const getLocationAddress = (data: GeneralGetTrackingDataResponse, parcelIndex: number) => {
  const parcel = getParcel(data, parcelIndex);
  return parcel?.location?.address;
};

export const getCoordinates = (data: GeneralGetTrackingDataResponse, parcelIndex: number) =>
  getLocationAddress(data, parcelIndex)?.coordinates;

export const isHomeDelivery = (deliveryType?: DeliveryType) =>
  deliveryType === 'DELIVERY' ||
  deliveryType === 'MAILBOX' ||
  // The agreement is to we fallback the UNKNOWN as DELIVERY, so it won't be treated as PICKUP
  // Backend will ensure that the value won't come from the user input. It becames an edge case, extremely unlikely to happen.
  // As the UNKNOWN is still a valid option from the moirai perspective, leaving the logic to handle that.
  deliveryType == 'UNKNOWN';

export const getDestinationName = (
  data: GeneralGetTrackingDataResponse,
  parcelIndex: number,
  branding: BrandingData,
  maskHomeAddress = false
) => {
  const deliveryType = getDeliveryType(data, parcelIndex) ?? 'UNKNOWN';
  const parcel = getParcel(data, parcelIndex);

  const isReturnParcel = parcel?.direction_type === 'RETURN';

  if (isReturnParcel) {
    return getSenderName(data, branding);
  }

  const address = getLocationAddress(data, parcelIndex);

  if (!isHomeDelivery(deliveryType)) {
    return address?.name ?? '';
  }

  if (maskHomeAddress) {
    return getPublicAddress(data, parcelIndex);
  }

  return formatAddressLines(address?.address_lines);
};

export const getTrackingLink = (data: GeneralGetTrackingDataResponse, parcelIndex: number) => {
  const { tracking_link } = getParcel(data, parcelIndex) || {};
  if (!tracking_link) {
    return;
  }
  const sanitizedTrackingLink = sanitizeUrl(tracking_link);
  // When a meaningful URL can't be returned after sanitization, 'about:blank' is returned.
  // It should be treated as if there is no tracking link at all.
  return sanitizedTrackingLink === 'about:blank' ? undefined : sanitizedTrackingLink;
};

export const getTrackingNumber = (data: GeneralGetTrackingDataResponse, parcelIndex: number) => {
  const parcel = getParcel(data, parcelIndex);
  return parcel?.tracking_number;
};

export const getIsHomeDelivery = (data: GeneralGetTrackingDataResponse, parcelIndex: number) => {
  const deliveryType = getDeliveryType(data, parcelIndex);
  return isHomeDelivery(deliveryType);
};

export const getPublicAddress = (data: GeneralGetTrackingDataResponse, parcelIndex: number) => {
  const address = getLocationAddress(data, parcelIndex);

  return formatAddressLines([address?.postal_code ?? '', address?.city ?? '']);
};

export const getDetailedAddress = (data: GeneralGetTrackingDataResponse, parcelIndex: number) => {
  const address = getLocationAddress(data, parcelIndex);
  if (!address) {
    return undefined;
  }
  return formatAddressLines([
    ...(address?.address_lines ?? []),
    address?.postal_code ?? '',
    address?.city ?? '',
  ]);
};

export const getAlerts = (
  data: GeneralGetTrackingDataResponse,
  parcelIndex: number
): FrontendAlert[] => {
  const parcel = getParcel(data, parcelIndex);

  return getParcelAlerts(parcel);
};

export const getParcelAlerts = (parcel?: Parcel) => {
  const status = getCurrentStatus(parcel?.progress ?? []);

  const alerts: FrontendAlert[] = parcel?.alerts ?? [];

  if (status?.step === 'STEP_DELIVERED_TO_PICKUP_POINT') {
    return alerts.concat({
      type: 'ALERT_DELIVERY_READY_TO_PICK_UP',
      created_at: status.time,
    });
  }

  return alerts;
};

export const getShowParcelAlerts = (
  parcel: Parcel | undefined,
  branding: PageBranding | WidgetBranding
) => !parcel?.tracking_link && !branding.sections_config?.eta_header?.disable_alerts;

export const getIsReturnParcel = (data: GeneralGetTrackingDataResponse, parcelIndex: number) => {
  return getParcelDirectionType(data, parcelIndex) === 'RETURN';
};

export const getParcelDirectionType = (
  data: GeneralGetTrackingDataResponse,
  parcelIndex: number
) => {
  const parcel = getParcel(data, parcelIndex);
  return parcel?.direction_type;
};

export const getLogoName = (data: GeneralGetTrackingDataResponse, parcelIndex: number) => {
  const parcel = getParcel(data, parcelIndex);
  return parcel?.direction_type === 'RETURN' ? data.order.site_name : parcel?.carrier;
};

export const getCurrentStatus = (statuses: ProgressItem[]) =>
  statuses.find(status => !!status.time);

export const getPickupInstructions = (
  data: GeneralGetTrackingDataResponse,
  parcelIndex: number
) => {
  const parcel = getParcel(data, parcelIndex);
  return parcel?.location?.pickup_instructions;
};

export const getBankIdFlowAvailability = (
  data: GeneralGetTrackingDataResponse,
  parcelIndex: number
) => {
  const trackingNumber = getTrackingNumber(data, parcelIndex);
  if (!trackingNumber) {
    return false;
  }
  const parcelOptions = data.parcel_options?.[trackingNumber];
  return parcelOptions?.ica_bankid_flow || false;
};
