import type { FC } from 'react';
import React from 'react';

import {
  GeneralGetTrackingDataResponse,
  PageBranding,
  WidgetBranding,
  getAlerts,
  getCarrierName,
  getDeliveryType,
  getGlobalTranslationKey,
  getIsReturnParcel,
  getLocationType,
  getParcel,
  getParcelCurrentStatus,
  getParcelStatuses,
  getSenderName,
  getShowParcelAlerts,
  getTrackingLink,
} from '@tracking/data';
import { useFormatMessage } from '@tracking/i18n';

import { Image, Text } from '../../atoms';
import { ProgressText } from '../../molecules';
import { CartItemsOverview } from '../cart-items';
import { EtaHeader } from '../eta-header';
import arrowheadRightwardsIcon from './arrowhead-rightwards-icon.svg';
import * as styles from './parcel-overview.css';

type ParcelOverviewProps = {
  locale: string;
  branding: PageBranding | WidgetBranding;
  dataTest: string;
  total: number;
  selectParcel: () => void;
  trackingData: GeneralGetTrackingDataResponse;
  parcelIndex: number;
  idx: number;
  destination: string;
  translationKey: string | undefined;
};

export const ParcelOverview: FC<ParcelOverviewProps> = ({
  dataTest,
  total,
  selectParcel,
  locale,
  branding,
  trackingData,
  parcelIndex,
  idx,
  destination,
  translationKey,
}) => {
  const data = {
    carrierName: getCarrierName(trackingData, parcelIndex),
    currentStatus: getParcelCurrentStatus(trackingData, parcelIndex),
    deliveryType: getDeliveryType(trackingData, parcelIndex),
    locationType: getLocationType(trackingData, parcelIndex),
    statuses: getParcelStatuses(trackingData, parcelIndex),
    senderName: getSenderName(trackingData, branding),
    isReturnParcel: getIsReturnParcel(trackingData, parcelIndex),
    alerts: getAlerts(trackingData, parcelIndex),
    isTrackingLink: Boolean(getTrackingLink(trackingData, parcelIndex)),
    parcel: getParcel(trackingData, parcelIndex),
  };

  const { carrierName, currentStatus, deliveryType, senderName } = data;

  const progressStatusData = {
    currentStatus,
    deliveryType,
    carrierName,
    senderName,
    destination,
  };

  const parcelItems = data.parcel?.line_items ?? [];
  const formatMessage = useFormatMessage();

  const position = idx + 1;

  const onKeyDownEventHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      selectParcel();
    }
  };

  // TODO: TRACK-1322
  // We're in the testing phase of the new text headers, that are same as the time line text.
  // This makes the progress text redundant, so we're hiding it.
  //
  // But not for ICA.
  //
  // ICA layout must not be changed, so we're keeping the old text.
  // This is a temporary solution, we have to find out the best approach.
  // The flag in the may be the case, however it does not cover the root issue, which is the static layout.
  // The ultimate solution is to make the layout-based templates, the way we can "drag & drop" the components.
  const showProgressTextComponent = !data.isTrackingLink && translationKey === 'ICA';

  const textHeader = data.isReturnParcel
    ? formatMessage('RETURN_POSITION', { position, total })
    : formatMessage('DELIVERY_POSITION', { position, total });

  return (
    <div
      className={styles.parcelOverview}
      onClick={e => {
        // The event may come from the button (like item gallery) that has already own click handler
        if (e.target instanceof HTMLButtonElement) {
          return;
        }

        selectParcel();
      }}
      data-test={dataTest}
      role="button"
      tabIndex={0}
      onKeyDown={onKeyDownEventHandler}
    >
      <div className={styles.parcelOverviewContent}>
        <Text
          textColor="darkGrey"
          textStyle="light1420"
          className={styles.parcelOverviewContentHeader}
        >
          {textHeader}
        </Text>
        <EtaHeader
          deliveryType={data.deliveryType}
          locationType={data.locationType}
          statuses={data.statuses}
          alerts={data.alerts}
          carrierName={data.carrierName}
          isTrackingLink={data.isTrackingLink}
          locale={locale}
          senderName={data.senderName}
          destination={destination}
          translationKey={getGlobalTranslationKey(branding)}
          showAlerts={getShowParcelAlerts(data.parcel, branding)}
          showDates={!branding.sections_config?.eta_header?.disable_dates}
        />

        <CartItemsOverview
          items={parcelItems}
          showImages={!!branding.sections_config?.cart_items?.show_images}
          imagesOnTop
        />

        {showProgressTextComponent && (
          <ProgressText
            className={styles.parcelProgressText}
            progressStatusData={progressStatusData}
            dataTest={'progress'}
            translationKey={translationKey}
          />
        )}
      </div>

      <Image
        className={styles.parcelOverviewArrowTrigger}
        width={24}
        height={24}
        src={arrowheadRightwardsIcon}
        alt={formatMessage('GO_TO_PARCEL_DETAILS_ALT')}
      />
    </div>
  );
};
