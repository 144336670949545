import { useEffect, useState } from 'react';

const canScroll = (el: HTMLElement) => el.scrollWidth > el.clientWidth;

export const useCanScroll = (ref: React.RefObject<HTMLElement>) => {
  const [value, setValue] = useState(() => {
    return ref.current ? canScroll(ref.current) : false;
  });

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      if (!ref.current) {
        return;
      }
      setValue(canScroll(ref.current));
    });

    resizeObserver.observe(ref.current);

    setValue(canScroll(ref.current));
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return value;
};
