/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';

import { useFormatMessage } from '@tracking/i18n';

import { Image } from '../../atoms';
import arrowheadUpwardsIcon from './arrowhead-upwards-icon.svg';
import * as styles from './expand.css';

const CLICKABLE_HEIGHT = 60; // pixels

export type ExpandProps = {
  expandedButtonOffset?: number;
  expandedChildren: ReactNode;
  unexpandedButtonOffset?: number;
  unexpandedChildren: ReactNode;
  dataTest?: string;
  onExpandPosthogCapture: () => void;
  onHidePosthogCapture: () => void;
  defaultExpanded?: boolean;
  expandToggleDisabled?: boolean;
  onContentClick?: (e: MouseEvent, toggle: () => void) => void;
};

export const Expand: FC<ExpandProps> = ({
  expandedButtonOffset = 8,
  expandedChildren,
  unexpandedButtonOffset = 8,
  unexpandedChildren,
  dataTest,
  onExpandPosthogCapture,
  onHidePosthogCapture,
  defaultExpanded = false,
  expandToggleDisabled = false,
  onContentClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  useEffect(() => {
    setIsExpanded(defaultExpanded);
  }, [defaultExpanded]);

  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const formatMessage = useFormatMessage();

  const content = isExpanded ? expandedChildren : unexpandedChildren;

  if (expandToggleDisabled) {
    return <div className={styles.expandRootElement}>{content}</div>;
  }

  const toggle = () => {
    isExpanded ? onHidePosthogCapture?.() : onExpandPosthogCapture?.();
    setIsExpanded(state => !state);
  };

  const handleClick = onContentClick
    ? onContentClick
    : (e: MouseEvent, t: () => void) => {
        const rootElementRect = ref.current.getBoundingClientRect();
        if (e.clientY - rootElementRect.y <= CLICKABLE_HEIGHT) {
          t();
        }
      };

  return (
    <div className={styles.expandRootElement} ref={ref}>
      <button
        onClick={toggle}
        data-branding="expand-button"
        className={`${styles.arrowButton} ${styles.arrowButtonState[isExpanded ? 'up' : 'down']}`}
        style={{ top: isExpanded ? expandedButtonOffset : unexpandedButtonOffset }}
        data-test={dataTest}
      >
        <Image
          src={arrowheadUpwardsIcon}
          height={24}
          width={24}
          alt={
            isExpanded
              ? formatMessage('EXPAND_ARROW_EXPANDED_ALT')
              : formatMessage('EXPAND_ARROW_SHRINKED_ALT')
          }
        />
      </button>
      <div
        onClick={e => {
          handleClick(e, toggle);
        }}
      >
        {content}
      </div>
    </div>
  );
};
