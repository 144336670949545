import {
  AnimatePresence,
  motion,
  useMotionValueEvent,
  useScroll,
  useTransform,
} from 'framer-motion';
import { FC, useEffect, useRef, useState } from 'react';

import { Item } from '@tracking/data';

import { CartItemImage } from '../../molecules';
import { ArrowButton } from './arrow-button';
import { useCanScroll } from './can-scroll';
import * as styles from './item-gallery.css';

type ItemGalleryProps = {
  items: Item[];
};

const getScrollControls = (x: number, scrollEnabled: boolean): Controls => ({
  left: scrollEnabled && x > 0,
  right: scrollEnabled && x < 1,
});

type Controls = {
  left: boolean;
  right: boolean;
};

// The value of scrollLeft is a float and it can be a fraction of a pixel for DPR > 1.
// The unexpected behavior is that the scrollLeft may not reach the scrollWidth at all.
// Calling scrollTo(x, 0) where x is integer scrollWidth result in some fraction like 270.99999999999994 intead of 271 - that was my case for DPR=2.75
// As framer depend on the scroll values, the scrollXProgress may never reach 1.
// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollLeft

// The function returns the progress value 0-1 with a pixel (integer) precision.
const getActualProgress = (progress: number, value: number) => {
  if (progress === 0 || progress === 1) {
    return progress;
  }

  // the scrollPosition is the value from 0 to scrollWidth - clientWidth
  // this logic is rounding the scrollPosition to the nearest integer first.
  const scrollPosition = Math.round(value / progress);
  return Math.round(scrollPosition * progress) / scrollPosition;
};

export const ItemGallery: FC<ItemGalleryProps> = ({ items }) => {
  const ref = useRef<HTMLDivElement>(null);

  const scroll = useScroll({ container: ref });

  const scrollEnabled = useCanScroll(ref);

  const progress = useTransform(scroll.scrollXProgress, value =>
    getActualProgress(value, scroll.scrollX.get())
  );

  const [controls, setControls] = useState<Controls>(() =>
    getScrollControls(progress.get(), scrollEnabled)
  );

  useMotionValueEvent(progress, 'change', newValue => {
    const previousValue = getActualProgress(
      scroll.scrollXProgress.getPrevious(),
      scroll.scrollX.getPrevious()
    );

    // update controls only when we reach/leave the edges, to avoid unnecessary re-renders
    if (newValue > 0 && newValue < 1 && previousValue > 0 && previousValue < 1) {
      return;
    }

    setControls(getScrollControls(newValue, scrollEnabled));
  });

  useEffect(() => {
    setControls(getScrollControls(progress.get(), scrollEnabled));
  }, [scrollEnabled]);

  const scrollBy = (value: number) => {
    ref.current?.scrollBy({ left: value * ref.current.clientWidth, behavior: 'smooth' });
  };

  return (
    <div className={styles.gallery}>
      <div>
        <div className={styles.galleryContent}>
          <Overlay {...controls} size="40px" />
          <div className={styles.buttonsWrapper}>
            <Buttons {...controls} scrollBy={scrollBy} />
          </div>
          <div className={styles.images} ref={ref}>
            {items.map(item => (
              <motion.div
                key={item.sku}
                transition={{ duration: 0.1 }}
                initial={scrollEnabled ? { opacity: 0, scale: 0.6 } : undefined}
                whileInView={{ scale: 1, opacity: 1 }}
                viewport={{
                  root: ref,
                }}
              >
                <CartItemImage
                  image={{
                    alt: `Image of ${item.name || item.sku}`,
                    fit: 'contain',
                    src: item.image,
                  }}
                />
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

type OverlayProps = Controls & {
  size: string;
};
const Overlay: FC<OverlayProps> = ({ left, right, size }) => {
  const leftColor = left ? 'white' : 'transparent';
  const rightColor = right ? 'white' : 'transparent';

  const gradient = `${leftColor}, transparent ${size}, transparent calc(100% - ${size}), ${rightColor}`;

  return (
    <motion.div
      className={styles.overlay}
      animate={{
        backgroundImage: `linear-gradient(90deg, ${gradient})`,
      }}
    />
  );
};

type ButtonsProps = Controls & {
  scrollBy: (value: number) => void;
};

const Buttons: FC<ButtonsProps> = ({ left, right, scrollBy }) => {
  return (
    <AnimatePresence>
      {left && (
        <ArrowButton
          key="left"
          direction="left"
          onClick={() => {
            scrollBy(-0.5);
          }}
        />
      )}

      {right && (
        <ArrowButton
          key="right"
          direction="right"
          onClick={() => {
            scrollBy(0.5);
          }}
        />
      )}
    </AnimatePresence>
  );
};
