import { z } from 'zod';

import { displayItemSchema } from './displayItemSchema';
import { publicParcelSchema } from './publicParcelSchema';

export const publicOrderSchema = z.object({
  analytics_id: z.string(),
  external_id: z.string().optional(),
  items: z.array(z.lazy(() => displayItemSchema)).optional(),
  parcels: z.array(z.lazy(() => publicParcelSchema)).optional(),
  preselected_parcel_index: z.string().optional(),
  sender_name: z
    .string()
    .describe(
      `sender_name is the name of the sender of the order. This maybe a site name or a merchant name.`
    )
    .optional(),
  site_name: z.string().optional(),
});
