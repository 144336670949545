import { useMutation } from '@tanstack/react-query';
import type { FC } from 'react';

import { useAnalytics } from '@tracking/data';
import { useFormatMessage } from '@tracking/i18n';
import { AuthForm, Modal } from '@tracking/ui';

export type AuthFormValues = {
  email: string;
};

type Props = {
  onModalClose?: () => void;
  onSubmit: (data: AuthFormValues) => Promise<void>;
  onClose: () => void;
  onCancel: () => void;
  onSuccess: () => void;
};

export const AuthModal: FC<Props> = ({ onCancel, onClose, onSubmit, onSuccess }) => {
  const formatMessage = useFormatMessage();

  const analytics = useAnalytics();

  const { mutate, isLoading, error } = useMutation((data: AuthFormValues) => onSubmit(data), {
    onSuccess,
  });

  const errorText = error instanceof Error ? error.message : undefined;

  return (
    <Modal onClose={onClose} headerText={formatMessage('AUTH.VERIFICATION')}>
      <AuthForm
        emailInputDataTest="auth-email-input"
        authVerifyButtonDataTest="auth-verify-button"
        authCancelButtonDataTest="auth-cancel-button"
        onPosthogCapture={() => analytics.capture('input_email_address')}
        isLoading={isLoading}
        onSubmit={mutate}
        onCancel={onCancel}
        errorText={errorText}
      />
    </Modal>
  );
};
