import React, { FC, ReactNode, createContext, useContext } from 'react';

type DataStoreContext<T> = [
  FC<{ value: T; children: ReactNode }>,
  <R>(select: (data: T) => R) => R,
];

export const createDataStoreContext = <T,>(name: string): DataStoreContext<T> => {
  const context = createContext<T | null>(null);

  return [
    ({ children, value }: { children: ReactNode; value: T }): JSX.Element => (
      <context.Provider value={value}>{children}</context.Provider>
    ),
    select => {
      const value = useContext(context);
      if (!value) {
        throw new Error(`Missing value for the ${name} context.`);
      }
      return select(value);
    },
  ];
};
