import { useEffect, useState } from 'react';

export const useInView = ({
  enabled = true,
  threshold,
}: {
  threshold: number;
  enabled?: boolean;
}) => {
  const [inView, setInView] = useState<boolean | null>(null);

  const [ref, setRef] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!ref || !enabled) {
      return;
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry?.isIntersecting || null);
      },
      { threshold }
    );
    observer.observe(ref);
    return () => {
      observer.disconnect();
    };
  }, [ref, threshold, enabled]);

  return [setRef, inView] as const;
};
