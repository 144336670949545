import { z } from 'zod';

export const alertTypeSchema = z
  .enum([
    `ALERT_UNDEFINED`,
    `ALERT_DELIVERY_DELAYED`,
    `ALERT_ESTIMATED_DELIVERY_TIME_UPDATED`,
    `ALERT_DELIVERY_ATTEMPT_FAILED`,
    `ALERT_OUT_FOR_DELIVERY`,
    `ALERT_SUBMISSION_DELAYED`,
    `ALERT_DELIVERY_DELAYED_FROM_CARRIER`,
    `ALERT_AWAITING_SUPPLIER`,
    `ALERT_LINEHAUL_TO_CARRIER`,
  ])
  .default('ALERT_UNDEFINED');
