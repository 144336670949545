type AccessibleInlineSvg = React.SVGProps<SVGSVGElement> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLTitleElement>, HTMLTitleElement>;

export const MapLocationPointer = ({
  title = 'Location pointer',
  ...props
}: AccessibleInlineSvg) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <title>{title}</title>
    <g clipPath="url(#clip0_1042_1680)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16 8A8 8 0 110 8 8 8 0 0116 8zm-8 5s4-2.5 4-5.5a4 4 0 00-8 0C4 11 8 13 8 13zm-.038-5.517a.049.049 0 00-.004.017.049.049 0 00.012.03.049.049 0 00.03.012.049.049 0 00.03-.012.049.049 0 00.012-.03.049.049 0 00-.012-.03.049.049 0 00-.03-.012.049.049 0 00-.03.012.04.04 0 00-.008.013zM9.5 7.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
        clipRule="evenodd"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_1042_1680">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
