import { useEffect, useState } from 'react';

import { SlideIn } from './slide-in';
import { Survey, SurveyProps } from './survey';

const LOCAL_STORAGE_ITEM = 'surveys_answered';

const getAnswered = () => {
  const value = globalThis.localStorage.getItem(LOCAL_STORAGE_ITEM) || '{}';
  try {
    return JSON.parse(value);
  } catch {
    return {};
  }
};

const setAnswered = (value: Record<string, number>) => {
  globalThis.localStorage.setItem(LOCAL_STORAGE_ITEM, JSON.stringify(value));
};

const markAsAnswered = (questionId: string) => {
  const value = getAnswered();
  setAnswered({ ...value, [questionId]: 1 });
};

const isAlreadyAnswered = (questionId: string) => !!getAnswered()[questionId];

type OneOffSurveyProps = SurveyProps & {
  surveyId: string;
  onDisplay: () => void;
};

export const OneOffSurvey = ({
  question,
  surveyId,
  title,
  onDisplay,
  onAnswer,
}: OneOffSurveyProps) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isAlreadyAnswered(surveyId)) {
      return;
    }

    setIsVisible(true);
    onDisplay();
  }, []);

  return (
    <SlideIn show={isVisible}>
      <Survey
        title={title}
        question={question}
        onAnswer={answer => {
          markAsAnswered(surveyId);
          setIsVisible(false);
          onAnswer(answer);
        }}
      />
    </SlideIn>
  );
};
