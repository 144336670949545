import { ImgHTMLAttributes, useContext } from 'react';
import { ComponentType, createContext } from 'react';

export type ImageProps = ImgHTMLAttributes<HTMLImageElement>;
type ImageComponent = ComponentType<ImageProps>;

const imageComponentContext = createContext<ImageComponent | null>(null);
const { Provider: ImageComponentProvider } = imageComponentContext;

const useImageComponent = () => useContext(imageComponentContext);

export { ImageComponentProvider, useImageComponent };
