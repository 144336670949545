import { z } from 'zod';

import { getTrackingDataResponseOptionsSchema } from './getTrackingDataResponseOptionsSchema';
import { orderSchema } from './orderSchema';

export const getTrackingDataResponseSchema = z.object({
  has_private_view: z.boolean(),
  order: z.lazy(() => orderSchema),
  parcel_options: z
    .object({})
    .catchall(z.lazy(() => getTrackingDataResponseOptionsSchema))
    .describe(`parcel_options is a map of parcel tracking numbers to options.`)
    .optional(),
  site_id: z.string(),
  tos_id: z.string(),
});
