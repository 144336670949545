import { Text } from '../atoms';
import { sprinkles } from '../sprinkles.css';
import * as styles from './infobox.css';

type InfoboxProps = {
  message: string;
  label?: string;
  'data-branding'?: string;
  'data-test'?: string;
};

export const Infobox = ({
  message,
  label,
  'data-branding': dataBranding = 'infobox',
  'data-test': dataTest = 'infobox',
}: InfoboxProps) => (
  <div className={styles.notification} data-test={dataTest} data-branding={dataBranding}>
    {Boolean(label) && (
      <Text
        data-branding="block-label"
        textStyle="light1216"
        textColor="darkGrey"
        className={sprinkles({ marginBottom: 2 })}
      >
        {label}
      </Text>
    )}
    <Text data-branding="block-text" textStyle="light1420">
      {message}
    </Text>
  </div>
);
