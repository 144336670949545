import { AbortError } from '../../errors';

export const makeAbortable =
  <T, A extends unknown[]>(signal: AbortSignal, fn: (...args: A) => Promise<T>) =>
  async (...args: A) => {
    if (signal.aborted) {
      throw new AbortError();
    }
    const result = await fn(...args);

    if (signal.aborted) {
      throw new AbortError();
    }

    return result;
  };

// this makes the promise reject early, once the signal is aborted,
// may reject before the AbortError is thrown in the abortable promise, as it may be in the middle of async operation like timeout, fetch etc.
export const rejectOnSignalAbort = <T>(
  signal: AbortSignal,
  promise: Promise<T>
): Promise<T | undefined> =>
  Promise.race([
    promise,
    new Promise<undefined>((_, reject) =>
      signal.addEventListener('abort', () => {
        reject(new AbortError());
      })
    ),
  ]);
