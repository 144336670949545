import { DEFAULT_LOCALE, fallbackLocaleMessages } from '@tracking/i18n';

import { PageTrackingError, logError } from '$src/commons/error-handling';
import { client } from '$src/modules/data/api/client';

export const getLocaleMessages = async (locale = DEFAULT_LOCALE) => {
  if (locale === 'en-US') {
    return fallbackLocaleMessages.get('en-US');
  }
  try {
    if (process.env.NODE_ENV === 'development' && process.env.PROXY_LOCALES === 'true') {
      // next fails when trying to serialize module, thus extracting the "default"
      const { default: messages } = await import(`@tracking/i18n/messages/${locale}.json`);
      return messages;
    }
    const { data } = await client.get(
      `${process.env.NEXT_PUBLIC_CDN_URL}/tracking-locales/${locale}.json`
    );
    return data;
  } catch (error) {
    logError(error as PageTrackingError);
    return fallbackLocaleMessages.get('en-US');
  }
};
