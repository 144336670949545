type ThumbUpIconProps = { className?: string };

export const ThumbUpIcon = ({ className }: ThumbUpIconProps) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0 8H3L6.27244 2.3901C7.30108 0.626717 10 1.35637 10 3.39784V8H14.8119C16.1517 8 17.1126 9.29147 16.7276 10.5747L14.9276 16.5747C14.6738 17.4207 13.8952 18 13.0119 18H0"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);
