import type { FC, ReactNode } from 'react';

import * as styles from './section.css';

type Props = {
  dataTest?: string;
  children: ReactNode;
};

export const Section: FC<Props> = ({ children, dataTest }) => (
  <div className={styles.section} data-test={dataTest}>
    {children}
  </div>
);
