import { getByTag } from 'locale-codes';

const isLocaleCode = (code: string) => Boolean(getByTag(code));

export const getUnsupportedLangPathSegment = (path: string) => {
  const [firstPathSegment] = path.replace(/^\//, '').split('/');
  if (firstPathSegment && isLocaleCode(firstPathSegment)) {
    return firstPathSegment;
  }
};
