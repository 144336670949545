import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

import { langStoreContext } from './lang-store';
import { useNavigatorLanguage } from './use-navigator-language';

export const useShowBanner = () => {
  const { locale, locales } = useRouter();
  const context = useContext(langStoreContext);
  const [isBannerVisible, setBannerVisibility] = useState(false);
  const language = useNavigatorLanguage();

  useEffect(() => {
    const isLangSelectionDismissed = !!context?.isLangSelectionDismissed;
    const isLocalesDifferent = language !== locale;
    const isSupportedLocale = !!locales?.some(supportedLocales => supportedLocales === language);

    const isBannerVisibleCondition =
      !isLangSelectionDismissed && isSupportedLocale && isLocalesDifferent;

    setBannerVisibility(isBannerVisibleCondition);
  }, [locale, locales, context, language]);

  const closeBanner = () => {
    context?.dismissLangSelection();
    setBannerVisibility(false);
  };

  return { isBannerVisible, closeBanner };
};
