import type { FC } from 'react';

import { Parcel, ProgressItem } from '@tracking/data';
import { useFormatMessage } from '@tracking/i18n';

import { Text } from '../atoms';
import { getTimelineItemText } from '../helpers';

type Props = {
  dataTest: string;
  className?: string;
  translationKey: string | undefined;
  progressStatusData: {
    currentStatus: ProgressItem | undefined;
    deliveryType: Parcel['delivery_type'];
    carrierName: Parcel['carrier'];
    senderName: string;
    destination: string;
  } & JSX.IntrinsicElements['span'];
};

export const ProgressText: FC<Props> = ({
  className,
  dataTest,
  progressStatusData,
  translationKey,
  ...textProps
}) => {
  const formatMessage = useFormatMessage();

  const { currentStatus, deliveryType, carrierName, senderName, destination } = progressStatusData;

  return (
    <Text
      textStyle="light1420"
      textColor="black"
      className={className}
      dataTest={dataTest}
      {...textProps}
    >
      {currentStatus &&
        getTimelineItemText(
          formatMessage,
          deliveryType,
          currentStatus,
          senderName,
          carrierName,
          destination,
          translationKey
        )}
    </Text>
  );
};
