import { format, isSameDay, isSameMinute, isSameMonth, parseISO } from 'date-fns';

import { ProgressItem } from '@tracking/data';
import { FormatMessageFn, dateFnsLocales } from '@tracking/i18n';

import { getTimeFormat } from './get-date-format';
import { isWholeDay } from './is-whole-day';

export const getTimestampDate = (
  locale: string,
  status: ProgressItem,
  formatMessage: FormatMessageFn
) => {
  const dateSeparator = formatMessage('HEADER.TIMESTAMP.DATE_SEPARATOR');
  const fnsDateLocale = dateFnsLocales.get(locale);

  const disableTime = status.step === 'STEP_SUBMITTED_TO_CARRIER';

  const formatDate = (time: Date, dateFormat = 'MMM d') =>
    format(time, dateFormat, {
      locale: fnsDateLocale,
    });

  const formatTime = (time: Date) => formatDate(time, getTimeFormat(locale));

  if (status.time) {
    return formatDate(parseISO(status.time));
  }

  const startDate = status.estimated_time?.start ? parseISO(status.estimated_time.start) : null;
  const endDate = status.estimated_time?.end ? parseISO(status.estimated_time.end) : null;

  if (!startDate && !endDate) {
    return '';
  }

  // single day estimate
  if (
    !startDate ||
    !endDate ||
    // date-fns docs: The given dates are the same to the nearest minute
    isSameMinute(startDate, endDate)
  ) {
    const singleDay = startDate || endDate!;

    if (disableTime || isWholeDay(singleDay)) {
      return formatDate(singleDay);
    }

    return `${formatDate(singleDay)} ${dateSeparator.toLowerCase()} ${formatTime(singleDay)}`;
  }

  // month range estimate
  if (!isSameMonth(startDate, endDate)) {
    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
  }

  // day range estimate within same month
  if (!isSameDay(startDate, endDate)) {
    return `${formatDate(startDate)} - ${formatDate(endDate, 'd')}`;
  }

  // same day estimate
  if (disableTime) {
    return formatDate(startDate);
  }

  return `${formatDate(startDate)} ${formatTime(startDate)} - ${formatTime(endDate)}`;
};
