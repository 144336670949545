import { z } from 'zod';

export const privateOrderCartPricesTotalsSchema = z.object({
  discount: z
    .number()
    .describe(
      `Discount represents the total discount of the cart with a shipping discount included.`
    )
    .optional(),
  items_discount: z
    .number()
    .describe(`ItemsDiscount represents the total discount of the cart items in cents.`)
    .optional(),
  items_price: z
    .number()
    .describe(
      `ItemsPrice represents the total price of the cart items in cents with items discount applied.`
    )
    .optional(),
  price: z
    .number()
    .describe(
      `Price represents the total price of the cart in cents with discount and shipping price applied.`
    )
    .optional(),
  shipping_discount: z
    .number()
    .describe(`ShippingDiscount represents the total shipping discount of the cart in cents.`)
    .optional(),
  shipping_price: z
    .number()
    .describe(
      `ShippingPrice represents the total shipping price of the cart in cents with shipping discount applied.`
    )
    .optional(),
});
