import { motion } from 'framer-motion';
import { FC } from 'react';

import * as styles from './arrow-button.css';
import { ChevronIcon } from './chevron-icon';

type ArrowButtonProps = {
  direction: 'left' | 'right';
  onClick: () => void;
};

export const ArrowButton: FC<ArrowButtonProps> = ({ direction, onClick }) => (
  <motion.button
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className={styles.arrowButton({ direction })}
    onClick={onClick}
  >
    <ChevronIcon direction={direction} />
  </motion.button>
);
