import { ReactNode, createContext, useContext } from 'react';

import { AnalyticsEventHandler } from '../../posthog';

const context = createContext<AnalyticsMethods | null>(null);

type AnalyticsIdentity = {
  analyticsId: string;
  tosId: string;
};

export type AnalyticsMethods = {
  identify(identity: AnalyticsIdentity): void;
  capture: AnalyticsEventHandler;
  registerProperties: (object: Record<string, unknown>) => void;
  unregisterProperties: (...keys: string[]) => void;
};

type AnalyticsProviderProps = {
  children: ReactNode;
  methods: AnalyticsMethods;
};

export const AnalyticsProvider = ({ children, methods }: AnalyticsProviderProps) => (
  <context.Provider value={methods}>{children}</context.Provider>
);

export const useAnalytics = () => {
  const config = useContext(context);

  if (!config) {
    throw new Error('Missing value for the AnalyticsProvider');
  }

  return config;
};
