import { useFormatMessage } from '@tracking/i18n';

import { Text } from '../../../atoms/text';
import * as styles from './map-popup.css';

type PopupProps = {
  coordinates: { x: number; y: number };
};

export const MapPopup = ({ coordinates }: PopupProps) => {
  const formatMessage = useFormatMessage();

  return (
    <div style={{ left: coordinates.x, top: coordinates.y }} className={styles.popup}>
      <Text textStyle="light1216" textColor="white">
        {formatMessage('PARCEL_DETAILS.MAP_VERIFICATION_POPUP')}
      </Text>
    </div>
  );
};
