import { FC, ReactNode } from 'react';

import { TranslationKey, useFormatMessage } from '@tracking/i18n';

import { Section, Text } from '../atoms';
import * as styles from './parcel-selector.css';

type Props = {
  dataTest: string;
  children: ReactNode;
  header: TranslationKey;
};

export const ParcelSelectorUI: FC<Props> = ({ dataTest, header, children }) => {
  const formatMessage = useFormatMessage();

  return (
    <article>
      <header>
        <Text className={styles.parcelSelectorHeader} textStyle="light2028" dataTest={dataTest}>
          {formatMessage(header)}
        </Text>
      </header>

      <Section>
        <div className={styles.parcelSelector}>{children}</div>
      </Section>
    </article>
  );
};
