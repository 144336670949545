import { AnalyticsEventHandler, Coordinates, DeliveryType, DirectionType } from '@tracking/data';

import { useMapPinLogo } from '../hooks';
import { MapErrorHandler } from '../types';
import { useMapFallback } from '../use-map-fallback';
import { hasCorrectCoordinates } from '../utils';
import { MapboxPrivateMap } from './mapbox-private-map';
import { MaptilerPrivateMap } from './maptiler-private-map';

export type LogoMapEntry = ReturnType<typeof useMapPinLogo>;

type PrivateMapProps = {
  analyticsEventHandler: AnalyticsEventHandler;
  coordinates: Coordinates | undefined;
  deliveryType?: DeliveryType;
  directionType?: DirectionType;
  maptilerApiKey: string;
  mapboxApiKey: string;
  shippingMethod?: string;
  onError: MapErrorHandler;
};
export const PrivateMap = ({
  analyticsEventHandler,
  coordinates,
  deliveryType,
  directionType,
  maptilerApiKey,
  mapboxApiKey,
  shippingMethod,
  onError,
}: PrivateMapProps) => {
  const { primaryMapError, fallbackMapError, onPrimaryMapError, onFallbackMapError } =
    useMapFallback(onError);

  const logoMapEntry = useMapPinLogo(shippingMethod, deliveryType, directionType);

  if (!hasCorrectCoordinates(coordinates)) {
    return null;
  }

  if (!primaryMapError) {
    return (
      <MaptilerPrivateMap
        analyticsEventHandler={analyticsEventHandler}
        coordinates={coordinates}
        maptilerApiKey={maptilerApiKey}
        logoMapEntry={logoMapEntry}
        onError={onPrimaryMapError}
      />
    );
  }

  if (!fallbackMapError) {
    return (
      <MapboxPrivateMap
        analyticsEventHandler={analyticsEventHandler}
        coordinates={coordinates}
        mapboxApiKey={mapboxApiKey}
        logoMapEntry={logoMapEntry}
        onError={onFallbackMapError}
      />
    );
  }

  return null;
};
