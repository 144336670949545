import addHours from 'date-fns/addHours';
import addMinutes from 'date-fns/addMinutes';
import format from 'date-fns/format';
import getMinutes from 'date-fns/getMinutes';
import startOfDay from 'date-fns/startOfDay';
import { isEmpty, mapValues, omit, omitBy, pick, uniq } from 'lodash';

import type { OperationalHours } from '@tracking/data';
import { FormatMessageFn, TranslationKey, dateFnsLocales } from '@tracking/i18n';

const getTimeFormat = (date: Date, locale: string) => {
  const minutes = getMinutes(date);
  if (locale === 'en-US') {
    return minutes === 0 ? 'ha' : 'h:mma';
  }
  return minutes === 0 ? 'H' : 'H:mm';
};

const formatTime = (date: Date, locale: string) =>
  format(date, getTimeFormat(date, locale), { locale: dateFnsLocales.get(locale) });

type StringPair = [string, string];
const createTimeObject = ([hours, minutes = '']: StringPair) =>
  addMinutes(addHours(startOfDay(new Date()), +hours), +minutes);

export const formatTimeRange = (time: string, locale: string) => {
  const timeRanges = time.split(/,|\|/).map(timeRange => {
    const [startTime, endTime] = timeRange.trim().split('-') as StringPair;
    return {
      from: createTimeObject(startTime.split(':') as StringPair),
      to: createTimeObject(endTime.split(':') as StringPair),
    };
  });

  return timeRanges
    .map(({ from, to }) => `${formatTime(from, locale)}–${formatTime(to, locale)}`)
    .join(', ');
};

type OperationalDays = Omit<OperationalHours, 'free_text'>;
type Day = keyof OperationalDays;

const daysToMessages = new Map<Day, TranslationKey>([
  ['sun', 'COMMON.SUNDAY'],
  ['mon', 'COMMON.MONDAY'],
  ['tue', 'COMMON.TUESDAY'],
  ['wed', 'COMMON.WEDNESDAY'],
  ['thu', 'COMMON.THURSDAY'],
  ['fri', 'COMMON.FRIDAY'],
  ['sat', 'COMMON.SATURDAY'],
]);

const formatDay = (time: string, text: string, locale: string) => [
  text,
  formatTimeRange(time, locale),
];

const formatDays = (days: OperationalDays, formatMessage: FormatMessageFn, locale: string) =>
  Object.entries(days).map(([day, hours]) =>
    formatDay(hours, formatMessage(daysToMessages.get(day as Day)!), locale)
  );

const isSameHoursRange = <T extends Partial<OperationalDays>>(days: T): days is Required<T> => {
  const values = Object.values(days);
  return values.filter(Boolean).length === values.length && uniq(values).length === 1;
};

export const formatOperationalHours = (
  operationalHours: OperationalHours,
  formatMessage: FormatMessageFn,
  locale: string
): string[][] => {
  const values: OperationalDays = mapValues(
    // TODO: omiting the free_text, as it's not supported and still in question TRACK-1145
    omit(operationalHours, 'free_text'),
    value => value?.trim()?.replace(/^-$/, '') ?? ''
  );

  const isWholeWeekRange = isSameHoursRange(values);
  const wholeWeekRangeFormat = formatMessage('COMMON.ALL_DAYS');
  if (isWholeWeekRange) {
    return [formatDay(values['mon'], wholeWeekRangeFormat, locale)];
  }

  const sections: Array<[Day, ...Day[]]> = [
    ['mon', 'tue', 'wed', 'thu', 'fri'],
    ['sat', 'sun'],
  ];

  return sections.flatMap(section => {
    const dayValues = pick(values, section);

    if (!isSameHoursRange(dayValues)) {
      return formatDays(omitBy(dayValues, isEmpty), formatMessage, locale);
    }

    const [first, last] = [section[0], section[section.length - 1]];

    return [
      formatDay(
        dayValues[first],
        `${formatMessage(daysToMessages.get(first)!)} – ${formatMessage(
          daysToMessages.get(last as Day)!
        )}`,
        locale
      ),
    ];
  });
};
