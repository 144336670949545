import { FC, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useFormatMessage } from '@tracking/i18n';

import { Text } from '../../atoms';
import * as styles from './copy-button.css';
import { CopyIcon } from './copy-icon';

type Props = {
  textToCopy: string | undefined;
  onCopy?: () => void;
  alt?: string;
};

export const CopyButton: FC<Props> = ({
  textToCopy,
  alt = 'Copy tracking number to clipboard',
  onCopy: onCopyProp,
}) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const formatMessage = useFormatMessage();

  useEffect(() => {
    const timer = setTimeout(() => setPopupVisibility(false), 1500);

    return () => {
      clearTimeout(timer);
    };
  }, [isPopupVisible]);

  return (
    <div className={styles.copyContainer}>
      {isPopupVisible && (
        <div className={styles.popup}>
          <Text textStyle="light1216" textColor="white">
            {formatMessage('COMMON.COPIED')}
          </Text>
        </div>
      )}
      <CopyToClipboard
        text={textToCopy ?? ''}
        onCopy={() => {
          onCopyProp?.();
          setPopupVisibility(true);
        }}
      >
        <button
          data-branding="copy-button"
          className={styles.copyButton}
          title={alt}
          data-test="copy-button"
        >
          <CopyIcon />
        </button>
      </CopyToClipboard>
    </div>
  );
};
