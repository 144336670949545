import clsx from 'clsx';
import { FC, ReactNode, useState } from 'react';

import { Text } from '../../atoms';
import * as styles from './survey.css';
import { ThumbsDownIcon } from './thumbs-down-icon';
import { ThumbUpIcon } from './thumbs-up-icon';

export type SurveyProps = {
  title: string;
  question: string;
  onAnswer(answer: boolean): void;
};

export const Survey = ({ title, question, onAnswer }: SurveyProps) => (
  <div className={styles.surveyBox}>
    <Text textStyle="regular1420" textColor="darkGrey">
      {title}
    </Text>
    <div>
      <Text textColor="black" textStyle="regular1620">
        {question}
      </Text>
    </div>
    <div className={styles.buttons}>
      <Button onClick={() => onAnswer(false)}>
        <ThumbsDownIcon className={styles.thumbDownIcon} />
      </Button>
      <Button onClick={() => onAnswer(true)}>
        <ThumbUpIcon className={styles.thumbUpIcon} />
      </Button>
    </div>
  </div>
);

type ButtonProps = { onClick: () => void; children: ReactNode };

const Button: FC<ButtonProps> = ({ onClick, children }) => {
  const [clicked, setClicked] = useState(false);
  return (
    <button
      className={clsx(styles.button, { [styles.buttonClicked]: clicked })}
      onClick={() => {
        setClicked(true);
        onClick();
      }}
    >
      {children}
    </button>
  );
};
