import jsonLogic from 'json-logic-js';
import { isPlainObject } from 'lodash';
import cloneDeepWith from 'lodash/cloneDeepWith';

import { Expression, ExpressionType } from '../../../generated/types';
import { Branding, ExpressionContextData } from './configuration';

type ExpressionEvaluator<T> = {
  validate: (expression: unknown) => expression is T;
  execute: (expression: T, context: unknown) => unknown;
};

const jsonLogicEvaluator: ExpressionEvaluator<jsonLogic.RulesLogic> = {
  validate: jsonLogic.is_logic,
  execute: jsonLogic.apply,
};

const evaluators = new Map<ExpressionType, ExpressionEvaluator<any>>([
  ['JSON_LOGIC', jsonLogicEvaluator],
]);

const isObject = (value: unknown): value is Record<string, unknown> => isPlainObject(value);

type PrimitiveValue = boolean | string | number;

export type WithExpression<T> = T extends PrimitiveValue
  ? T | Expression
  : { [name in keyof T]: WithExpression<T[name]> };

const isExpression = (value: unknown): value is Required<Expression> =>
  isObject(value) && '__type' in value && 'value' in value;

export const evaluateObjectDeep = <T,>(
  object: WithExpression<T>,
  context: ExpressionContextData
): T =>
  cloneDeepWith(object, value => {
    if (!isExpression(value)) {
      return;
    }

    const { value: expressionStringValue, __type: evaluatorName } = value;

    const expressionValue = parseValue(expressionStringValue);

    if (expressionValue === null) {
      console.error('Cannot parse the rule %s', expressionStringValue);
      return;
    }

    const evaluator = evaluators.get(evaluatorName);
    if (!evaluator) {
      console.error('Unknown evaluator %s', evaluatorName);
      return;
    }

    if (!evaluator.validate(expressionValue)) {
      console.error('Invalid rule %o for evaluator %s', expressionValue, evaluatorName);
      return;
    }

    try {
      const result = evaluator.execute(expressionValue, context);
      // convert undefined to null
      // using undefined implies default deepClone behavior for the 'value' argument
      return result ?? null;
    } catch {
      // in case of invalid rule, like using unsupported operators
      console.error('Could not evaluate rule %o for evaluator %s', expressionValue, evaluatorName);
      return value;
    }
  });

const parseValue = (value: string): unknown => {
  try {
    return JSON.parse(value);
  } catch {
    return null;
  }
};
