import { z } from 'zod';

import { alertAwaitingSupplierPayloadSchema } from './alertAwaitingSupplierPayloadSchema';
import { alertDeliveryAttemptFailedPayloadSchema } from './alertDeliveryAttemptFailedPayloadSchema';
import { alertDeliveryDelayedFromCarrierPayloadSchema } from './alertDeliveryDelayedFromCarrierPayloadSchema';
import { alertDeliveryDelayedPayloadSchema } from './alertDeliveryDelayedPayloadSchema';
import { alertEstimatedDeliveryTimeUpdatedPayloadSchema } from './alertEstimatedDeliveryTimeUpdatedPayloadSchema';
import { alertLinehaulPayloadSchema } from './alertLinehaulPayloadSchema';
import { alertOutForDeliveryPayloadSchema } from './alertOutForDeliveryPayloadSchema';
import { alertSubmissionDelayedPayloadSchema } from './alertSubmissionDelayedPayloadSchema';
import { alertTypeSchema } from './alertTypeSchema';

export const alertSchema = z.object({
  awaiting_supplier: z.lazy(() => alertAwaitingSupplierPayloadSchema).optional(),
  created_at: z.string().optional(),
  delivery_attempt_failed: z.lazy(() => alertDeliveryAttemptFailedPayloadSchema).optional(),
  delivery_delayed: z.lazy(() => alertDeliveryDelayedPayloadSchema).optional(),
  delivery_delayed_from_carrier: z
    .lazy(() => alertDeliveryDelayedFromCarrierPayloadSchema)
    .optional(),
  estimated_delivery_time_updated: z
    .lazy(() => alertEstimatedDeliveryTimeUpdatedPayloadSchema)
    .optional(),
  linehaul: z.lazy(() => alertLinehaulPayloadSchema).optional(),
  out_for_delivery: z.lazy(() => alertOutForDeliveryPayloadSchema).optional(),
  submission_delayed: z.lazy(() => alertSubmissionDelayedPayloadSchema).optional(),
  type: z.lazy(() => alertTypeSchema).optional(),
});
