import { FC } from 'react';

import { getPriceFormatter, useLocale } from '@tracking/i18n';

import { Text } from '../../atoms';
import * as styles from './price.css';

type PriceProps = {
  price: number;
  discount?: number;
  currency: string | undefined;
};

export const Price: FC<PriceProps> = ({ price, discount, currency }: PriceProps) => {
  const locale = useLocale();
  const formatPrice = getPriceFormatter(locale, currency);

  if (!discount) {
    return (
      <Text textStyle="light1420" textColor="black">
        {formatPrice(price)}
      </Text>
    );
  }

  return (
    <>
      <Text textStyle="light1420" textColor="darkGrey" className={styles.oldPrice}>
        {
          // price is an unit price with all applied discounts:
          // new price = price
          // old price = price + discount
        }
        {formatPrice(price + discount)}
      </Text>
      <Text textStyle="light1420" textColor="black">
        {formatPrice(price)}
      </Text>
    </>
  );
};
