import { z } from 'zod';

export const lineItemSchema = z.object({
  currency: z
    .string()
    .describe(
      `Currency represented as a symbol (ISO-4217 - 3 letters string). Examples: USD, PLN, SEK.`
    ),
  discount: z
    .number()
    .describe(
      `Discount is the discount for a given item in cents. Example: '20000' represents '200.00'.`
    )
    .optional(),
  image: z.string().describe(`Image is the URL of the image of the given cart item.`).optional(),
  name: z.string().describe(`Name is the product name or title.`).optional(),
  price: z
    .number()
    .describe(
      `Price is the price of a single item with applied discounts in cents. Example: '20000' represents '200.00'.`
    ),
  properties: z
    .object({})
    .catchall(z.string())
    .describe(`Properties that define item variant.`)
    .optional(),
  quantity: z
    .number()
    .describe(`Quantity is the total number of a given product item in the cart.`),
  sku: z.string().describe(`SKU is a unique product identifier.`),
  total_price: z.number().optional(),
});
