import { FC } from 'react';

import { Text } from '../../atoms';
import * as styles from './notification.css';

export const Notification: FC<{
  text: string;
}> = ({ text }) => (
  <div className={styles.notification}>
    <Text data-test="notification" textStyle="light1420" textColor="white">
      {text}
    </Text>
  </div>
);
