import type { InputHTMLAttributes } from 'react';

import * as styles from './page-text-input.css';

export const getString = (value: InputHTMLAttributes<HTMLInputElement>['value']) =>
  (value ?? '').toString();

export const getBorderColor = (focused?: boolean, error?: boolean) => {
  if (error) {
    return styles.borderStyleError;
  } else if (focused) {
    return styles.borderStyleFocused;
  } else {
    return styles.borderStyle;
  }
};
