import { z } from 'zod';

export const operationalHoursSchema = z.object({
  free_text: z.array(z.string()).optional(),
  fri: z.string().optional(),
  mon: z.string().optional(),
  sat: z.string().optional(),
  sun: z.string().optional(),
  thu: z.string().optional(),
  tue: z.string().optional(),
  wed: z.string().optional(),
});
