import clsx from 'clsx';
import type { FC } from 'react';

import * as styles from './dot-line.css';

type Props = {
  completed?: boolean;
  current?: boolean;
  drawLine?: boolean;
};

export const DotLine: FC<Props> = ({ completed = false, current = false, drawLine = true }) => (
  <div className={styles.rootElement}>
    <div
      className={clsx([
        styles.dotBase,
        completed && styles.dotCompleted,
        current && styles.dotCurrent,
      ])}
      data-branding={`timeline-item-dot${completed ? '-completed' : ''}`}
    />
    {drawLine && (
      <div
        data-branding={`timeline-item-line${completed ? '-completed' : ''}`}
        className={clsx([styles.lineBase, completed && styles.lineCompleted])}
      />
    )}
  </div>
);
