import { DehydratedState, Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { AppContext, AppProps } from 'next/app';
import DefaultApp from 'next/app';
import Head from 'next/head';
import Image, { ImageProps } from 'next/image';
import { useRouter } from 'next/router';
import Script from 'next/script';
import posthog from 'posthog-js';
import { Fragment, useEffect, useState } from 'react';

import { AnalyticsProvider, LoggerProvider } from '@tracking/data';
import { I18nProvider } from '@tracking/i18n';
import { ImageComponentProvider } from '@tracking/ui';
import '@tracking/ui/styles/normalize.css';
import { EnvConfigProvider } from '@tracking/widget-ui';

import { CookieConsentGivenEvent, getConsentGiven } from '$src/commons/cookie-information';
import { logError } from '$src/commons/error-handling';
import { blacklistedProperties, isIdentified, posthogCapture } from '$src/commons/posthog-capture';
import { getLocaleMessages } from '$src/modules/data/api/locale-messages';
import { LangStore } from '$src/modules/i18n';
import '$src/styles';

const App = ({
  Component,
  pageProps,
  messages,
}: AppProps & {
  messages: Record<string, string>;
  dehydratedState: DehydratedState;
}) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: { refetchOnWindowFocus: false, refetchOnReconnect: false },
        },
      })
  );
  const { pathname, locale = 'en-US' } = useRouter();

  useEffect(() => {
    window.addEventListener('CookieInformationConsentsReady', () => {
      const consentGiven = getConsentGiven('cookie_cat_statistic');
      posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_API_HOST,
        autocapture: false,
        capture_pageview: false,
        persistence: consentGiven ? 'cookie' : 'memory', // avoid data storage due to GDPR regulations
        property_blacklist: consentGiven ? [] : blacklistedProperties,
        loaded: posthog => {
          posthog.register({ identified: consentGiven });
          window?.addEventListener('CookieInformationConsentGiven', event => {
            const { consents } = (event as CookieConsentGivenEvent).detail;
            if (consents.cookie_cat_statistic && !isIdentified()) {
              posthog.set_config({ persistence: 'cookie', property_blacklist: [] });
              posthog.identify(posthog.get_distinct_id());
              posthog.register({ identified: true });
              posthogCapture('statistic_consent_given', { source: 'portal' });
            } else if (!consents.cookie_cat_statistic) {
              posthog.set_config({
                persistence: 'memory',
                property_blacklist: blacklistedProperties,
              });
              posthog.reset();
              posthog.register({ identified: false });
              posthogCapture('statistic_consent_withdrawn', { source: 'portal' });
            }
          });
          if (process.env.NEXT_PUBLIC_ENV !== 'production') {
            posthog.opt_out_capturing();
          }
          if (pathname === '/') {
            posthogCapture('portal_view', { source: 'portal' });
          }
        },
      });
    });
  }, []);

  return (
    <Fragment>
      <Head>
        <title>Track your delivery | Ingrid Tracking Portal</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
        <link
          rel="preload"
          href="https://cdn.ingrid.com/static/assets/UniversalSans.630.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="https://cdn.ingrid.com/static/assets/UniversalSans.450.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
      </Head>
      <Script
        data-culture="EN"
        src="https://policy.app.cookieinformation.com/uc.js"
        onLoad={() => {
          window.dispatchEvent(new CustomEvent('CookieInformationConsentsReady'));
        }}
      ></Script>
      <EnvConfigProvider
        config={{
          MAPBOX_API_KEY: process.env.NEXT_PUBLIC_MAPBOX_KEY || '',
          MAPTILER_API_KEY: process.env.NEXT_PUBLIC_MAPTILER_KEY || '',
          CDN_URL: process.env.NEXT_PUBLIC_CDN_URL || '',
        }}
      >
        <LoggerProvider
          value={{
            error: (err: unknown) => logError(err as Error),
          }}
        >
          <AnalyticsProvider
            methods={{
              capture: (event, properties) => {
                posthogCapture(event, {
                  ...properties,
                  source: pathname === '/' ? 'portal' : 'page',
                });
              },
              identify: _identity => {
                // Identify the user based on the PostHog distinct ID during initialization
                //TODO: Discuss whether we want to use a different identifier
                // posthog.identify(identity.analyticsId, {
                //   tos_id: identity.tosId,
                //   source: pathname === '/' ? 'portal' : 'page',
                // });
              },
              registerProperties: properties => {
                posthog.register(properties);
              },
              unregisterProperties: (...keys: string[]) => {
                keys.forEach(key => posthog.unregister(key));
              },
            }}
          >
            <I18nProvider locale={locale} localeMessages={messages}>
              <ImageComponentProvider
                value={({ src = '', alt = '', width, height, placeholder: _, ...props }) => (
                  <Image
                    src={src}
                    alt={alt}
                    width={width as ImageProps['width']}
                    height={height as ImageProps['height']}
                    {...props}
                  />
                )}
              >
                <LangStore>
                  <QueryClientProvider client={queryClient}>
                    <Hydrate state={pageProps.dehydratedState}>
                      <Component {...pageProps} />
                    </Hydrate>
                    <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                  </QueryClientProvider>
                </LangStore>
              </ImageComponentProvider>
            </I18nProvider>
          </AnalyticsProvider>
        </LoggerProvider>
      </EnvConfigProvider>
    </Fragment>
  );
};

App.getInitialProps = async (props: AppContext) => {
  const initialProps = await DefaultApp.getInitialProps(props);
  const messages = await getLocaleMessages(props.ctx.locale);

  return { ...initialProps, messages: messages };
};

export default App;
