import type { FC, ReactNode } from 'react';

import { useFormatMessage } from '@tracking/i18n';

import { Stack, Text } from '../../atoms';

type Props = {
  progressTextComponent: ReactNode;
};

export const TrackingProgressStack: FC<Props> = ({ progressTextComponent }) => {
  const formatMessage = useFormatMessage();

  return (
    <Stack paddingRight={24}>
      <Text textStyle="light1420" textColor="darkGrey" data-branding="block-label">
        {formatMessage('PROGRESS_DETAILS.PROGRESS')}
      </Text>
      {progressTextComponent}
    </Stack>
  );
};
