import { Dispatch, SetStateAction } from 'react';

import { AnalyticsEventHandler, Coordinates } from '@tracking/data';

import { MapErrorHandler } from '../types';
import { useMapFallback } from '../use-map-fallback';
import { MapboxPublicMap } from './mapbox-public-map';
import { MaptilerPublicMap } from './maptiler-public-map';

type PublicMapUIProps = {
  analyticsEventHandler: AnalyticsEventHandler;
  coordinates: Coordinates | undefined;
  hasAuthenticationEmail: boolean;
  setAuthModalVisibility: Dispatch<SetStateAction<boolean>>;
  mapboxApiKey: string;
  maptilerApiKey: string;
  onError: MapErrorHandler;
};
export const PublicMapUI = ({
  analyticsEventHandler,
  maptilerApiKey,
  mapboxApiKey,
  onError,
  ...publicMapProps
}: PublicMapUIProps) => {
  const { primaryMapError, fallbackMapError, onPrimaryMapError, onFallbackMapError } =
    useMapFallback(onError);

  if (!primaryMapError) {
    return (
      <MaptilerPublicMap
        analyticsEventHandler={analyticsEventHandler}
        maptilerApiKey={maptilerApiKey}
        onError={onPrimaryMapError}
        {...publicMapProps}
      />
    );
  }

  if (!fallbackMapError) {
    return (
      <MapboxPublicMap
        analyticsEventHandler={analyticsEventHandler}
        mapboxApiKey={mapboxApiKey}
        onError={onFallbackMapError}
        {...publicMapProps}
      />
    );
  }

  return null;
};
