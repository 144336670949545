import type { PageBranding, SectionsConfig, WidgetBranding } from '../../../generated/types';

export type BrandingData = PageBranding | WidgetBranding | null | undefined;

export const getGlobalTranslationKey = (branding: BrandingData) => branding?.translation_key;

//sections are visible by default
export const getSingleSectionVisibility = (
  branding: BrandingData,
  sectionName: keyof SectionsConfig
) => !branding?.sections_config?.[sectionName]?.disabled;

export const getSectionsVisibility = (brandingData: BrandingData) => ({
  etaHeader: getSingleSectionVisibility(brandingData, 'eta_header'),
  mapSection: getSingleSectionVisibility(brandingData, 'map'),
  deliveryDetails: getSingleSectionVisibility(brandingData, 'delivery_details'),
  cartItems: getSingleSectionVisibility(brandingData, 'cart_items'),
  trackingProgress: getSingleSectionVisibility(brandingData, 'tracking_progress'),
  infoBox: getSingleSectionVisibility(brandingData, 'info_box'),
});

type MatchingKeys<T> = {
  [K in keyof SectionsConfig]-?: T extends SectionsConfig[K] ? K : never;
}[keyof SectionsConfig];

export const getSectionExpandProps = (
  branding: BrandingData,
  sectionName: MatchingKeys<{
    default_expanded: boolean;
    expand_toggle_disabled: boolean;
  }>
) => {
  const section = branding?.sections_config?.[sectionName];
  return {
    defaultExpanded: section?.default_expanded || false,
    expandToggleDisabled: section?.expand_toggle_disabled || false,
  };
};

export const getBrandingSenderName = (branding: BrandingData) => branding?.sender_name || '';
