import React, { FC } from 'react';

type ChevronIconProps = {
  direction: 'left' | 'right';
};
export const ChevronIcon: FC<ChevronIconProps> = ({ direction }) => {
  const angle = direction === 'left' ? 180 : 0;
  return (
    <svg
      style={{ pointerEvents: 'none' }}
      transform={`rotate(${angle} 0 0)`}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="20"
        height="20"
        fill="white"
        fillOpacity={0.01}
        style={{ mixBlendMode: 'multiply' }}
      />
      <path d="M8 5L13 10L8 15" stroke="#444444" strokeWidth="1.5" />
    </svg>
  );
};
