import { RecipeVariants } from '@vanilla-extract/recipes';
import { FC, HTMLProps, ReactNode, RefObject, useEffect, useRef } from 'react';

import { ModalHeader } from './modal-header/modal-header';
import * as headerStyles from './modal-header/modal-header.css';
import * as styles from './modal.css';

type ModalProps = {
  headerText: string;
  onClose: () => void;
  footerContent?: ReactNode;
  children: ReactNode;
  headerDivider?: boolean;
  footerDivider?: boolean;
  headerSpacing?: NonNullable<RecipeVariants<typeof headerStyles.modalHeader>>['spacing'];
  bodySpacing?: NonNullable<RecipeVariants<typeof styles.modalBody>>['spacing'];
  fullscreen?: boolean;
  shrinkBody?: boolean;
};
export const Modal: FC<ModalProps> = ({
  headerText,
  onClose,
  footerContent,
  children,
  headerDivider,
  footerDivider,
  headerSpacing,
  bodySpacing,
  fullscreen,
  shrinkBody,
}) => {
  return (
    <div className={styles.backdrop({ fullscreen })}>
      <ModalContainer className={styles.modal}>
        <ModalHeader
          text={headerText}
          onClose={onClose}
          divider={headerDivider}
          spacing={headerSpacing}
        />
        <div className={styles.modalBody({ spacing: bodySpacing, shrink: shrinkBody })}>
          {children}
        </div>
        {footerContent && (
          <div className={styles.modalFooter({ divider: footerDivider })}>{footerContent}</div>
        )}
      </ModalContainer>
    </div>
  );
};

const ModalContainer = (props: HTMLProps<HTMLDivElement>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useLockBodyScroll(containerRef);
  return <div ref={containerRef} {...props} />;
};

const useLockBodyScroll = (elementRef: RefObject<Element>) => {
  useEffect(() => {
    if (!elementRef.current) {
      return;
    }

    const body = elementRef.current.ownerDocument.body;
    body.classList.add(styles.bodyScrollLock);
    return () => {
      body.classList.remove(styles.bodyScrollLock);
    };
  }, []);
};
