import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'zod';

import { useFormatMessage } from '@tracking/i18n';

import { Button, Text } from '../atoms';
import { TextInputWithMask } from '../molecules';
import * as styles from './auth-form.css';

const EMAIL_MAX_LENGTH = 150;

type FormValues = {
  email: string;
};

type Props = {
  isLoading: boolean;
  errorText?: string;
  onPosthogCapture: () => void;
  onCancel: () => void;
  onSubmit: (values: FormValues) => void;
  emailInputDataTest?: string;
  authVerifyButtonDataTest?: string;
  authCancelButtonDataTest?: string;
};

export const AuthForm: FC<Props> = ({
  isLoading,
  errorText,
  onCancel,
  onPosthogCapture,
  onSubmit,
  emailInputDataTest,
  authVerifyButtonDataTest,
  authCancelButtonDataTest,
}) => {
  const formatMessage = useFormatMessage();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: { email: '' },
    resolver: zodResolver(
      object({
        email: string()
          .min(1, formatMessage('AUTH.EMAIL_REQUIRED'))
          .max(EMAIL_MAX_LENGTH, formatMessage('AUTH.TOO_LONG_EMAIL'))
          .email(formatMessage('AUTH.VALID_EMAIL_ADDRESS')),
      })
    ),
  });

  useEffect(() => {
    if (errorText) {
      setError('email', { type: 'custom', message: errorText });
    }
  }, [errorText, setError]);

  const { onBlur, ...emailRegisterProps } = register('email');

  return (
    <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
      <Text>{formatMessage('AUTH.FORM_TEXT')}</Text>
      <TextInputWithMask
        {...emailRegisterProps}
        className={styles.textInput}
        onBlur={e => {
          if (getValues().email) {
            onPosthogCapture();
          }
          onBlur(e);
        }}
        id="email"
        name="email"
        maxLength={EMAIL_MAX_LENGTH}
        label="E-mail"
        error={errors.email}
        dataTest={emailInputDataTest}
        value={watch('email')}
      />
      <Button
        isLoading={isLoading}
        className={styles.verify}
        dataTest={authVerifyButtonDataTest}
        type="submit"
      >
        <Text textColor="white">{formatMessage('AUTH.VERIFY')}</Text>
      </Button>
      <Button
        onClick={onCancel}
        className={styles.cancel}
        dataTest={authCancelButtonDataTest}
        buttonType="secondary"
      >
        <Text>{formatMessage('AUTH.CANCEL')}</Text>
      </Button>
    </form>
  );
};
