import { z } from 'zod';

import { displayItemSchema } from './displayItemSchema';
import { orderCartSchema } from './orderCartSchema';
import { parcelSchema } from './parcelSchema';

export const orderSchema = z.object({
  access_token: z.string().optional(),
  analytics_id: z.string(),
  cart: z.lazy(() => orderCartSchema).optional(),
  external_id: z.string().optional(),
  items: z.array(z.lazy(() => displayItemSchema)).optional(),
  parcels: z.array(z.lazy(() => parcelSchema)).optional(),
  preselected_parcel_index: z.string().optional(),
  preselected_parcel_tracking_number: z.string().optional(),
  sender_name: z
    .string()
    .describe(
      `sender_name is the name of the sender of the order. This maybe a site name or a merchant name.`
    )
    .optional(),
  site_name: z.string().optional(),
});
