import { z } from 'zod';

export const stepSchema = z
  .enum([
    `STEP_UNKNOWN`,
    `STEP_CONFIRMED_BY_MERCHANT`,
    `STEP_PREPARED_BY_MERCHANT`,
    `STEP_SUBMITTED_TO_CARRIER`,
    `STEP_ON_ROUTE`,
    `STEP_DELIVERED_TO_PICKUP_POINT`,
    `STEP_DELIVERED_TO_CONSUMER`,
    `STEP_TERMINATED`,
    `STEP_SENT_BACK`,
    `STEP_RETURN_REQUESTED`,
    `STEP_DELIVERED_TO_MERCHANT`,
    `STEP_AWAITING_SUPPLIER`,
    `STEP_LINEHAUL_TO_CARRIER`,
  ])
  .default('STEP_UNKNOWN');
